import React, { useState, useEffect } from 'react';
import styled, {
  css,
  DefaultTheme,
  StyledComponent,
  keyframes,
} from 'styled-components';

import { Button } from '../../Atoms/Button';
import { IconSMArrowDown } from '../../Atoms/Icon';
import DefaultNavLink from '../../Atoms/NavLink';

const visibleAni = ($height: number) => keyframes`
    0%{
        height: 0px;
    }
    100%{
        height: ${$height}px;
    }
`;

const closeAni = ($height: number) => keyframes`
    0%{
        height: ${$height}px;
    }
    100%{
        height: 0px;
    }
`;

const Component = styled.div``;

const DropDownButton = styled(Button)<{ $isActive: boolean | null }>`
  height: 60px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props) => {
    if (props.$isActive) {
      return css`
        color: white;

        svg {
          &:last-child {
            transform: rotate(180deg);
          }
        }
      `;
    }
    return css`
      color: ${props.theme.colors.purple3};
    `;
  }}

  svg {
    &:last-child {
      transition: transform 0.2s ease-out;
      width: 12px;
    }
  }
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  svg {
    width: 16px;
    margin-right: 8px;
  }
`;

const DropDownBody = styled.div<{
  $isActive: boolean | null;
  $subMenuCnt: number;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: ${(props) => props.theme.colors.primary};

  overflow: hidden;

  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  a {
    display: flex;
    align-items: center;
    height: 52px !important;
    padding: 16px 64px;
  }
  ${(props) => {
    if (props.$isActive) {
      return css`
        animation-name: ${visibleAni(props.$subMenuCnt * 52)};
      `;
    }
    if (props.$isActive === false) {
      return css`
        animation-name: ${closeAni(props.$subMenuCnt * 52)};
      `;
    }
    return css`
      height: 0px;
    `;
  }}
`;

const Title = styled.div``;

interface NavDropDownButtonProps {
  menuId: number;
  Icon: StyledComponent<
    React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >,
    DefaultTheme,
    any,
    never
  >;
  title: string;
  subMenu: {
    title: string;
    to: string;
    exact?: boolean;
  }[];
  initIsActive: boolean;
  setActiveMenuId: React.Dispatch<React.SetStateAction<number>>;
  onClickDropDownButton: (id: number) => void;
}

const NavDropDownButton = ({
  Icon,
  title,
  subMenu,
  initIsActive,
  menuId,
  setActiveMenuId,
  onClickDropDownButton,
}: NavDropDownButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean | null>(null);
  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsOpen((prev) => {
      return !prev;
    });
  };

  useEffect(() => {
    if (initIsActive) {
      setIsOpen(true);
    } else if (isOpen === true) {
      setIsOpen(false);
    }
  }, [initIsActive]);

  useEffect(() => {
    if (isOpen) {
      onClickDropDownButton(menuId);
    }
  }, [isOpen]);

  return (
    <Component>
      <DropDownButton $isActive={isOpen} isBlock onClick={onClick}>
        <TitleBox>
          <Icon className={isOpen ? 'focus' : 'default'} />
          <Title>{title}</Title>
        </TitleBox>
        <IconSMArrowDown className={isOpen ? 'focus' : 'default'} />
      </DropDownButton>
      <DropDownBody $isActive={isOpen} $subMenuCnt={subMenu.length}>
        {subMenu.map((element) => {
          return (
            <DefaultNavLink
              key={element.to}
              to={element.to}
              title={element.title}
              exact={element.exact}
            />
          );
        })}
      </DropDownBody>
    </Component>
  );
};

export default NavDropDownButton;
