import React from 'react';
import styled from 'styled-components';

const Component = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0%;
`;

interface CardImageProps {
  imgSrc: string;
}

const CardImage = ({ imgSrc }: CardImageProps) => {
  return (
    <Component>
      <Img src={imgSrc} alt="" />
    </Component>
  );
};

export default CardImage;
