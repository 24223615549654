import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';

import {
  GetAIVORYContentUsageBySearchWordParamType,
  GetAIVORYContentUsageBySearchWordResponseType,
  GetAIVORYSearchKeywordNetworkParamType,
  GetAIVORYSearchKeywordNetworkResponseType,
  GetAIVORYSearchUsageWordcloudParamType,
  GetAIVORYSearchUsageWordcloudResponseType,
} from '../../../api/aivoryUsageAnalytics/popularSearchAnalysis/type';

export const GET_AIVORY_SEARCH_KETWORD_NETWORK = 'aivoryUsageAnalytics/popularSearchAnalysis/GET_AIVORY_SEARCH_KETWORD_NETWORK' as const;
export const GET_AIVORY_SEARCH_KETWORD_NETWORK_SUCCESS = 'aivoryUsageAnalytics/popularSearchAnalysis/GET_AIVORY_SEARCH_KETWORD_NETWORK_SUCCESS' as const;
export const GET_AIVORY_SEARCH_KETWORD_NETWORK_ERROR = 'aivoryUsageAnalytics/popularSearchAnalysis/GET_AIVORY_SEARCH_KETWORD_NETWORK_ERROR' as const;

export const GET_AIVORY_SEARCH_USAGE_WORDCLOUD = 'aivoryUsageAnalytics/popularSearchAnalysis/GET_AIVORY_SEARCH_USAGE_WORDCLOUD' as const;
export const GET_AIVORY_SEARCH_USAGE_WORDCLOUD_SUCCESS = 'aivoryUsageAnalytics/popularSearchAnalysis/GET_AIVORY_SEARCH_USAGE_WORDCLOUD_SUCCESS' as const;
export const GET_AIVORY_SEARCH_USAGE_WORDCLOUD_ERROR = 'aivoryUsageAnalytics/popularSearchAnalysis/GET_AIVORY_SEARCH_USAGE_WORDCLOUD_ERROR' as const;

export const GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD = 'aivoryUsageAnalytics/popularSearchAnalysis/GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD' as const;
export const GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD_SUCCESS = 'aivoryUsageAnalytics/popularSearchAnalysis/GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD_SUCCESS' as const;
export const GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD_ERROR = 'aivoryUsageAnalytics/popularSearchAnalysis/GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD_ERROR' as const;

export const SET_ERROR_NULL_AIVORY_POPULAR_SEARCH =
  'aivoryUsageAnalytics/popularSearchAnalysis/SET_ERROR_NULL_AIVORY_POPULAR_SEARCH';

export const getAIVORYSearchKeywordNetworkAsync = createAsyncAction(
  GET_AIVORY_SEARCH_KETWORD_NETWORK,
  GET_AIVORY_SEARCH_KETWORD_NETWORK_SUCCESS,
  GET_AIVORY_SEARCH_KETWORD_NETWORK_ERROR
)<
  GetAIVORYSearchKeywordNetworkParamType,
  GetAIVORYSearchKeywordNetworkResponseType,
  AxiosErrorType
>();

export const getAIVORYSearchUsageWordcloudAsync = createAsyncAction(
  GET_AIVORY_SEARCH_USAGE_WORDCLOUD,
  GET_AIVORY_SEARCH_USAGE_WORDCLOUD_SUCCESS,
  GET_AIVORY_SEARCH_USAGE_WORDCLOUD_ERROR
)<
  GetAIVORYSearchUsageWordcloudParamType,
  GetAIVORYSearchUsageWordcloudResponseType,
  AxiosErrorType
>();

export const getAIVORYContentUsageBySearchWordAsync = createAsyncAction(
  GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD,
  GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD_SUCCESS,
  GET_AIVORY_CONTENT_USAGE_BY_SEARCH_WORD_ERROR
)<
  GetAIVORYContentUsageBySearchWordParamType,
  GetAIVORYContentUsageBySearchWordResponseType,
  AxiosErrorType
>();

export const setErrorNullAivoryPopularSearch = createAction(
  SET_ERROR_NULL_AIVORY_POPULAR_SEARCH
)();
