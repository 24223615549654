import { createGlobalStyle, css, DefaultTheme } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    spacing: {
      xxs: number;
      xs: number;
      xsm: number;
      sm: number;
      md: number;
      xlg: number;
      lg: number;
      xl: number;
    };
    device: {
      $desktop: string;
      $bigscreen: string;
    };
    colors: {
      // primary
      primary: '#7851e7';
      purple1: '#e8dcfd';
      purple2: '#d1bafc';
      purple3: '#b597f7';
      purple4: '#9d7bf0';
      purple5: '#7851e7';

      // secondary
      orange1: '#fff2d5';
      orange2: '#ffe2ac';
      orange3: '#ffce83';
      orange4: '#ffba64';
      orange5: '#ff9931';

      green1: '#fafbc9';
      green2: '#f4f894';
      green3: '#e4ea5d';
      green4: '#ced535';
      green5: '#b0b900';

      // gray scale
      white: '#ffffff';
      grey100: '#f5f5f5';
      grey200: '#eeeeee';
      grey300: '#e0e0e0';
      grey400: '#bdbdbd';
      grey500: '#9e9e9e';
      grey600: '#757575';
      grey700: '#616161';
      grey800: '#424242';
      grey900: '#333333';
      black: '#000000';

      // semantic colors
      danger1: '#feeae0';
      danger2: '#fdd1c2';
      danger3: '#fab2a2';
      danger4: '#f6938a';
      danger5: '#f16464';

      warning1: '#fef5d6';
      warning2: '#fee8ae';
      warning3: '#fcd785';
      warning4: '#f9c666';
      warning5: '#f5ab35';

      success1: '#dbfcf1';
      success2: '#b9f9e9';
      success3: '#92ecde';
      success4: '#72dbd3';
      success5: '#47c1c3';

      info1: '#cafcfd';
      info2: '#97f3fc';
      info3: '#62dff6';
      info4: '#3bc5ec';
      info5: '#00a0e1';

      heatmap01: '#f16464';
      heatmap02: '#f26e5d';
      heatmap03: '#f27857';
      heatmap04: '#f38250';
      heatmap05: '#f38b4a';
      heatmap06: '#f49543';
      heatmap07: '#f49f3d';
      heatmap08: '#f5a936';
      heatmap09: '#deae48';
      heatmap10: '#c2b15f';
      heatmap11: '#a6b575';
      heatmap12: '#8ab98c';
      heatmap13: '#6ebca3';
      heatmap14: '#52c0ba';
      heatmap15: '#47c1c3';
    };
  }
}

export const GlobalStyle = createGlobalStyle`${css`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700;900&family=Roboto:wght@400;500;700;900&display=swap');
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    /* font-size: 100%; */
    /* font: inherit; */
    /* vertical-align: baseline; */
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    /* line-height: 1; */
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto, 'Noto Sans KR', sans-serif;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    min-height: 100%;
  }

  .wordcloud-tooltip {
    font-weight: 400;
    font-size: 14px;
    color: white;
    background-color: #333333;
    height: 51px;
    padding: 16px;
    border-radius: 8px;
    ::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0px;
      width: 0px;
      height: 0px;
      transform: translate(-50%, 100%);
      border-top: 8px solid #333333;
      border-bottom: 8px solid none;
      border-left: 4.5px solid transparent;
      border-right: 4.5px solid transparent;
    }
  }
  /* .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #ab96ff;
    opacity: 0.2;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #ab96ff;
    opacity: 0.2;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #7851e7;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: none;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
    background: #ab96ff;
    opacity: 0.4;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before {
    background: #ab96ff;
    opacity: 0.2;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: #ab96ff;
    opacity: 0.2;
  }
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    background: #ab96ff;
    opacity: 0.4;
  } */

  #gnb_submenu_tooltip {
    position: absolute;
    display: none;
    flex-direction: column;
    /* right: -171px;
    top: 0px; */
    background-color: #7851e7;
    box-shadow: 0 4px 8px 0 rgba(120, 81, 231, 0.4);
    border-radius: 8px;
    padding: 24px 32px 24px 16px;
    transform: translate(0%, -50%);
    z-index: 30;
    /* width: 132px; */

    /* &.active {
      display: flex;
    } */

    ::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -7px;
      transform: translate(0%, -50%);

      width: 0px;
      height: 0px;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-right: 7px solid #7851e7;
      border-left: 7px solid none;
    }

    a {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      width: 100%;
      color: #b7b8ff;
      height: 20px;
      padding: 0px;
      :hover {
        color: white;
      }
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`}
   
`;

const deviceSizes = {
  $desktop: '0px',
  $bigscreen: '1440px',
};

export const theme: DefaultTheme = {
  spacing: {
    xxs: 4,
    xs: 8,
    xsm: 12,
    sm: 16,
    md: 20,
    xlg: 24,
    lg: 40,
    xl: 60,
  },
  device: {
    $desktop: `screen and (min-width: ${deviceSizes.$desktop})`,
    $bigscreen: `screen and (min-width: ${deviceSizes.$bigscreen})`,
  },
  colors: {
    // primary
    primary: '#7851e7',
    purple1: '#e8dcfd',
    purple2: '#d1bafc',
    purple3: '#b597f7',
    purple4: '#9d7bf0',
    purple5: '#7851e7',

    // secondary
    orange1: '#fff2d5',
    orange2: '#ffe2ac',
    orange3: '#ffce83',
    orange4: '#ffba64',
    orange5: '#ff9931',

    green1: '#fafbc9',
    green2: '#f4f894',
    green3: '#e4ea5d',
    green4: '#ced535',
    green5: '#b0b900',

    // gray scale
    white: '#ffffff',
    grey100: '#f5f5f5',
    grey200: '#eeeeee',
    grey300: '#e0e0e0',
    grey400: '#bdbdbd',
    grey500: '#9e9e9e',
    grey600: '#757575',
    grey700: '#616161',
    grey800: '#424242',
    grey900: '#333333',
    black: '#000000',

    // semantic colors
    danger1: '#feeae0',
    danger2: '#fdd1c2',
    danger3: '#fab2a2',
    danger4: '#f6938a',
    danger5: '#f16464',

    warning1: '#fef5d6',
    warning2: '#fee8ae',
    warning3: '#fcd785',
    warning4: '#f9c666',
    warning5: '#f5ab35',

    success1: '#dbfcf1',
    success2: '#b9f9e9',
    success3: '#92ecde',
    success4: '#72dbd3',
    success5: '#47c1c3',

    info1: '#cafcfd',
    info2: '#97f3fc',
    info3: '#62dff6',
    info4: '#3bc5ec',
    info5: '#00a0e1',

    heatmap01: '#f16464',
    heatmap02: '#f26e5d',
    heatmap03: '#f27857',
    heatmap04: '#f38250',
    heatmap05: '#f38b4a',
    heatmap06: '#f49543',
    heatmap07: '#f49f3d',
    heatmap08: '#f5a936',
    heatmap09: '#deae48',
    heatmap10: '#c2b15f',
    heatmap11: '#a6b575',
    heatmap12: '#8ab98c',
    heatmap13: '#6ebca3',
    heatmap14: '#52c0ba',
    heatmap15: '#47c1c3',
  },
};
