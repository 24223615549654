import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// Atoms
import {
  IconAIvoryLogo,
  IconPageAnaly,
  IconServiceAnaly,
  IconWysiwyg,
} from '../../Atoms/Icon';
import { LogoWhite } from '../../Atoms/Logo';

// Molecule
import NavDropDownButton from '../../Molecule/NavDropDownButton';
import Lisence from '../../Molecule/Lisence';
import ProfileLink from '../../Molecule/ProfileLink';
import Timer from '../../Molecule/Timer';
import NavFooterLink from '../../Molecule/NavFooterLink';
import Modal from '../../Molecule/Modal';
import NavFoldButton from '../../Molecule/NavFoldButton';
import DefaultNavLink from '../../Atoms/NavLink';

const Component = styled.div`
  position: relative;
  float: left;
  display: flex;
  flex-direction: column;
  width: 244px;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.primary};
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;
  padding: 0px 40px;

  svg {
    width: 58px;
    height: 40px;
  }
`;

const MainMenuWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey300};
    border-radius: 4px;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  .voda_profile_link {
    margin-top: 8px;
  }
  .voda_timer {
    margin-top: 8px;
  }
  .voda_nav_footer_link {
    margin: 40px 0px;
    margin-right: -3px;
  }
`;

const FoldButtonWrapper = styled.div`
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
`;

const Split = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.purple4};
`;

const SingleMenuButton = styled.div`
  display: flex;
  width: 100%;
  height: 60px;

  a {
    display: flex;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    height: 100%;
    width: 100%;
    svg {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
  }
`;

function getCookie(cookieName: string) {
  let cookieValue = null;
  if (document.cookie) {
    const array = document.cookie.split(`${escape(cookieName)}=`);
    if (array.length >= 2) {
      const arraySub = array[1].split(';');
      cookieValue = unescape(arraySub[0]);
    }
  }
  return cookieValue;
}

const deleteCookie = (
  sKey: string,
  sDomain: string | null,
  sPath: string | null
) => {
  if (getCookie(sKey)) {
    document.cookie = `${sKey}=${sPath ? `;path=${sPath}` : ''}${
      sDomain ? `;domain=${sDomain}` : ''
    };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
};

interface NavigationProps extends RouteComponentProps {
  lisenceInfo: {
    pv?: number;
    day?: number;
  };
  userName: string;
  isAIVORYAvailable: boolean;
  onSetLogout: () => void;
  onGetCheckSession: () => void;
}

const Navigation = ({
  location,
  lisenceInfo,
  userName,
  isAIVORYAvailable,
  onSetLogout,
  onGetCheckSession,
}: NavigationProps) => {
  let interval: ReturnType<typeof setTimeout> | null = null;
  const sessionInitTime = 3600;
  let sessionSecond = sessionInitTime;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(
    '정상적으로 로그아웃되었습니다.'
  );
  const [activeMenuId, setActiveMenuId] = useState(-1);
  const [timer, setTimer] = useState('59:59');

  const onClickLogout = () => {
    onSetLogout();
  };

  const onClickDropDownButton = (id: number) => {
    setActiveMenuId(id);
  };

  const TimerInterval = () => {
    const pad = (n: number, width: number) => {
      const numberToStr = `${n}`;
      return numberToStr.length >= width
        ? numberToStr
        : new Array(width - numberToStr.length + 1).join('0') + numberToStr;
    };

    interval = setInterval(() => {
      if (sessionSecond >= 0) {
        const voda_session_valid_time = sessionStorage.getItem(
          'voda_session_valid_time'
        );
        if (voda_session_valid_time && voda_session_valid_time !== null) {
          const currentTime = new Date();
          const validTime = new Date(voda_session_valid_time);

          const elapsedMsec = Math.floor(
            (validTime.getTime() - currentTime.getTime()) / 1000
          );

          const min = Math.floor(elapsedMsec / 60);
          const sec = elapsedMsec % 60;

          const minStr = pad(min, 2);
          const secStr = pad(sec, 2);
          setTimer(`${minStr}:${secStr}`);
          sessionSecond = elapsedMsec;

          if (elapsedMsec <= 1) {
            setModalMessage(
              '로그인 유지 시간이 만료되었습니다.\n다시 로그인 해주세요.'
            );

            // setIsModalOpen(true);

            if (interval) {
              clearInterval(interval);
            }
            localStorage.removeItem('voda_tenant');
            deleteCookie('sessionid', '.nerdfactory.ai', '/');
            deleteCookie('csrftoken', '.nerdfactory.ai', '/');
            onGetCheckSession();
          }
        }
      }
    }, 1000);
  };

  const ModalClose = () => {
    setIsModalOpen(false);
    onSetLogout();
  };

  useEffect(() => {
    const pathArr = location.pathname.split('/');

    switch (pathArr[1]) {
      case 'serviceAnalytics':
        setActiveMenuId(0);
        break;
      case 'pageAnalytics':
        setActiveMenuId(1);
        break;
      case 'aivoryUsageAnalytics':
        setActiveMenuId(2);
        break;
      case 'report':
        setActiveMenuId(3);
        break;
      default:
        setActiveMenuId(0);
        break;
    }
  }, []);

  useEffect(() => {
    const expire = new Date();
    expire.setMinutes(expire.getMinutes() + 60);
    sessionStorage.setItem('voda_session_valid_time', expire.toUTCString());
    axios.interceptors.response.use(
      (response) => {
        sessionSecond = sessionInitTime;
        const expireNew = new Date();
        expireNew.setMinutes(expireNew.getMinutes() + 60);
        sessionStorage.setItem(
          'voda_session_valid_time',
          expireNew.toUTCString()
        );
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    TimerInterval();
    return () => {
      if (interval !== null) {
        clearInterval(interval);
        sessionSecond = sessionInitTime;
      }
      sessionStorage.setItem('voda_session_valid_time', '');
      setIsModalOpen(false);
    };
  }, []);

  return (
    <Component>
      <Modal
        isPublic={false}
        isOpen={isModalOpen}
        message={modalMessage}
        handleClose={ModalClose}
      />
      <LogoWrapper>
        <LogoWhite />
      </LogoWrapper>
      <MainMenuWrapper>
        <NavDropDownButton
          menuId={0}
          initIsActive={activeMenuId === 0}
          Icon={IconServiceAnaly}
          title="서비스 이용 분석"
          subMenu={[
            {
              to: '/serviceAnalytics',
              title: '서비스 이용 분석',
            },
            {
              to: '/serviceAnalytics/userAnalytics',
              title: '사용자 수 분석',
            },
            {
              to: '/serviceAnalytics/pageViewAnalytics',
              title: '페이지 뷰 수 분석',
            },
            {
              to: '/serviceAnalytics/sessionAnalytics',
              title: '방문 횟수 분석',
            },
            {
              to: '/serviceAnalytics/environmentAnalysis',
              title: '사용 환경 분석',
            },
          ]}
          setActiveMenuId={setActiveMenuId}
          onClickDropDownButton={onClickDropDownButton}
        />
        <NavDropDownButton
          menuId={1}
          initIsActive={activeMenuId === 1}
          Icon={IconPageAnaly}
          title="페이지 상세 분석"
          subMenu={[
            {
              to: '/pageAnalytics/uxAnalysisList',
              title: '페이지 UX 분석',
              exact: false,
            },
            {
              to: '/pageAnalytics/userFlowAnalysisList',
              title: '사용 흐름 분석',
              exact: false,
            },
          ]}
          setActiveMenuId={setActiveMenuId}
          onClickDropDownButton={onClickDropDownButton}
        />
        {isAIVORYAvailable ? (
          <NavDropDownButton
            menuId={2}
            initIsActive={activeMenuId === 2}
            Icon={IconAIvoryLogo}
            title="AIVORY 이용 분석"
            subMenu={[
              {
                to: '/aivoryUsageAnalytics',
                title: 'AIVORY 이용 분석',
              },
              {
                to: '/aivoryUsageAnalytics/searchUsageAnalysis',
                title: '검색 이용 분석',
              },
              {
                to: '/aivoryUsageAnalytics/popularSearchAnalysis',
                title: '인기검색어 분석',
              },
              {
                to: '/aivoryUsageAnalytics/contentKeywordAnalysis',
                title: '콘텐츠 키워드 분석',
              },
            ]}
            setActiveMenuId={setActiveMenuId}
            onClickDropDownButton={onClickDropDownButton}
          />
        ) : null}

        <SingleMenuButton
          onClick={() => {
            onClickDropDownButton(3);
          }}
        >
          <DefaultNavLink key="/report" to="/report" title="월간 분석 리포트">
            <IconWysiwyg
              className={
                location.pathname.split('/')[1] === 'report'
                  ? 'focus'
                  : 'default'
              }
            />
          </DefaultNavLink>
        </SingleMenuButton>
      </MainMenuWrapper>
      <FooterWrapper>
        <Lisence pv={lisenceInfo.pv || 0} day={lisenceInfo.day || 0} />
        <ProfileLink userName={userName} />
        <Timer time={timer} />
        <NavFooterLink onClickLogout={onClickLogout} />
      </FooterWrapper>
      <Split />
      <FoldButtonWrapper>{/* <NavFoldButton /> */}</FoldButtonWrapper>
    </Component>
  );
};

export default withRouter(Navigation);
