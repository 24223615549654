import React from 'react';
import styled from 'styled-components';
import { DateErrorTypo } from '../Typo';

const Component = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DataError = () => {
  return (
    <Component>
      <DateErrorTypo>데이터를 불러오지 못했습니다.</DateErrorTypo>
    </Component>
  );
};

export default DataError;
