import React, { useState } from 'react';
import styled from 'styled-components';

import ContentBodyLayout from '../../Atoms/Layout/Contents/ContentBodyLayout';
import ContentsLayout from '../../Atoms/Layout/Contents/ContentsLayout';
import ContentsItemLayout from '../../Atoms/Layout/Contents/ContentsItemLayout';

import ButtonGroup from '../../Molecule/ButtonGroup';

import Header from '../../Organisms/Header';
import MouseHeatmapCard from '../../Organisms/Card/UXAnalysis/MouseHeatmapCard';
import {
  GET_PAGE_ELEMENT_DATA,
  GET_PAGE_MOUSE_STATICS,
  uxAnalyticsState,
} from '../../../modules/PageAnalytics/UXAnalytics';
import {
  MouseHeatmapTooltipProps,
  MouseHeatmapTooltipType,
} from '../../Molecule/Tooltip/MouseHeatmapTooltip';
import PageUsabilityCard, {
  PageUsabilityCardProps,
} from '../../Organisms/Card/UXAnalysis/PageUsabilityCard';
import MouseEventRankCard from '../../Organisms/Card/UXAnalysis/MouseEventRankCard';
import MouseFlowCard from '../../Organisms/Card/UXAnalysis/MouseFlowCard';
import { PageElementsFlowPayload } from '../../../api/pageAnalytics/uxAnalytics/mouseflow/type';
import MouseFlowRankCard from '../../Organisms/Card/UXAnalysis/MouseFlowRankCard';
import ScrollHeatmapCard from '../../Organisms/Card/UXAnalysis/ScrollHeatmapCard';
import { ScrollEventsPayload } from '../../../api/pageAnalytics/uxAnalytics/scroll/type';
import MouseScrollRankCard from '../../Organisms/Card/UXAnalysis/MouseScrollRankCard';
import { GET_SCROLL_EVENTS } from '../../../modules/PageAnalytics/UXAnalytics/Scroll';
import { loadingState } from '../../../modules/loading';
import { GET_PAGE_ELEMENTS_FLOW } from '../../../modules/PageAnalytics/UXAnalytics/MouseFlow';

interface PageUXAnalysisTemplateProps {
  headerTitle: string;
  pageId: string;
  pageEventStaticsData: uxAnalyticsState['mouseStaticsData'];
  pageElementData: uxAnalyticsState['elementData'];
  clickedElementPath: string;
  mouseoverElementPath: string;

  clickTooltipInfo: MouseHeatmapTooltipType | null;
  mouseoverTooltipInfo: MouseHeatmapTooltipType | null;
  pageUsabilityIsLoading: boolean;
  pageUsabilityData: PageUsabilityCardProps['data'];
  activeDataType: number;

  mouseflowData: PageElementsFlowPayload[] | null;
  clickedPlusElementPath: string;
  scrollEventsData: ScrollEventsPayload | null;
  loading: loadingState;
  setClickedElementPath: React.Dispatch<React.SetStateAction<string>>;
  setMouseoverElementPath: React.Dispatch<React.SetStateAction<string>>;
  setClickTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;

  setMouseoverTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;
  setActiveDataType: React.Dispatch<React.SetStateAction<number>>;
  setClickedPlusElementPath: React.Dispatch<React.SetStateAction<string>>;
}

const AnalysisTypeButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const EventTypeButtonGroup = styled(ButtonGroup)`
  button {
    max-width: 120px;
    min-width: 70px;
  }
`;

const PageUXAnalysisTemplate = ({
  headerTitle,
  pageId,
  pageEventStaticsData,
  pageElementData,
  clickedElementPath,
  mouseoverElementPath,
  clickTooltipInfo,
  mouseoverTooltipInfo,
  pageUsabilityData,
  pageUsabilityIsLoading,
  activeDataType,
  mouseflowData,
  clickedPlusElementPath,
  scrollEventsData,
  loading,
  setClickedElementPath,
  setMouseoverElementPath,
  setClickTooltipInfo,
  setMouseoverTooltipInfo,
  setActiveDataType,
  setClickedPlusElementPath,
}: PageUXAnalysisTemplateProps) => {
  const onClickDataTypeButton = (id: number) => {
    // onSetTargetElementId('');
    setActiveDataType(id);
    setClickedElementPath('');
    setMouseoverElementPath('');
    setMouseoverTooltipInfo(null);
    setClickTooltipInfo(null);
  };

  const printChart = () => {
    switch (activeDataType) {
      case 0:
      case 1:
        return (
          <MouseHeatmapCard
            pageId={pageId}
            pageEventStaticsData={pageEventStaticsData}
            pageElementData={pageElementData}
            clickedElementPath={clickedElementPath}
            mouseoverElementPath={mouseoverElementPath}
            clickTooltipInfo={clickTooltipInfo}
            isActiveDataType={activeDataType}
            mouseoverTooltipInfo={mouseoverTooltipInfo}
            isLoading={
              loading[GET_PAGE_MOUSE_STATICS] || loading[GET_PAGE_ELEMENT_DATA]
            }
            setClickedElementPath={setClickedElementPath}
            setMouseoverElementPath={setMouseoverElementPath}
            setClickTooltipInfo={setClickTooltipInfo}
            setMouseoverTooltipInfo={setMouseoverTooltipInfo}
          />
        );

      case 2:
        return (
          <MouseFlowCard
            pageId={pageId}
            pageElementData={mouseflowData}
            clickTooltipInfo={clickTooltipInfo}
            mouseoverTooltipInfo={mouseoverTooltipInfo}
            clickedElementPath={clickedElementPath}
            clickedPlusElementPath={clickedPlusElementPath}
            isLoading={loading[GET_PAGE_ELEMENTS_FLOW]}
            setClickTooltipInfo={setClickTooltipInfo}
            setMouseoverTooltipInfo={setMouseoverTooltipInfo}
            setClickedElementPath={setClickedElementPath}
            setClickedPlusElementPath={setClickedPlusElementPath}
          />
        );
      case 3:
        return (
          <ScrollHeatmapCard
            isLoading={loading[GET_SCROLL_EVENTS]}
            pageId={pageId}
            data={scrollEventsData}
          />
        );

      default:
        return null;
    }
  };

  const printRankCard = () => {
    switch (activeDataType) {
      case 0:
      case 1:
        return (
          <MouseEventRankCard
            clickedElementPath={clickedElementPath}
            elementData={pageElementData}
            pageId={pageId}
            activeDataType={activeDataType}
            isLoading={
              loading[GET_PAGE_MOUSE_STATICS] || loading[GET_PAGE_ELEMENT_DATA]
            }
            setClickedElementPath={setClickedElementPath}
            setClickTooltipInfo={setClickTooltipInfo}
          />
        );
      case 2:
        return (
          <MouseFlowRankCard
            pageId={pageId}
            pageElementData={mouseflowData}
            clickedElementPath={clickedElementPath}
            isLoading={loading[GET_PAGE_ELEMENTS_FLOW]}
            setClickedElementPath={setClickedElementPath}
          />
        );
      case 3:
        return (
          <MouseScrollRankCard
            pageId={pageId}
            data={scrollEventsData}
            activeDataType={activeDataType}
            isLoading={loading[GET_SCROLL_EVENTS]}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ContentsLayout>
      <Header title={headerTitle} isDatePicker />
      <AnalysisTypeButtonWrapper>
        <EventTypeButtonGroup
          buttonData={[
            { id: 0, title: '클릭' },
            { id: 1, title: '무브' },
            { id: 2, title: '마우스 플로우' },
            { id: 3, title: '스크롤' },
          ]}
          onClick={onClickDataTypeButton}
          activeID={activeDataType}
        />
      </AnalysisTypeButtonWrapper>
      <ContentBodyLayout>
        <ContentsItemLayout desktop={6}>{printChart()}</ContentsItemLayout>
        <ContentsItemLayout desktop={6}>
          <ContentBodyLayout>
            <ContentsItemLayout desktop={12}>
              <PageUsabilityCard
                data={pageUsabilityData}
                isLoading={pageUsabilityIsLoading}
              />
            </ContentsItemLayout>
            <ContentsItemLayout desktop={12}>
              {printRankCard()}
            </ContentsItemLayout>
          </ContentBodyLayout>
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default PageUXAnalysisTemplate;
