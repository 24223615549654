import styled from 'styled-components';

import { ReactComponent as LogoWhiteSVG } from '../../../assets/logo/logo-white.svg';
import { ReactComponent as LogoSymbolSVG } from '../../../assets/logo/logo-symbol.svg';

export const LogoWhite = styled(LogoWhiteSVG)`
  path {
    fill: white;
  }
`;
export const LogoSymbol = styled(LogoSymbolSVG)``;
