import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../Atoms/Button';
import { IconClose } from '../../Atoms/Icon';
import { H3 } from '../../Atoms/Typo';

const ModalBackBlur = styled.div<{ $isModalOpen: boolean; $isPublic: boolean }>`
  position: fixed;
  background-color: black;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  ${(props) => {
    if (props.$isPublic) {
      return css``;
    }
    return css`
      left: 244px;
    `;
  }}

  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
  z-index: 400;
`;

const ModalBackBlurNav = styled.div<{
  $isModalOpen: boolean;
}>`
  position: fixed;
  background-color: white;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 399;
  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
`;

const Component = styled.div<{
  $isModalOpen: boolean;
  $width?: number;
  $height?: number;
}>`
  position: fixed;
  width: ${(props) => props.$width || 600}px;
  height: ${(props) => props.$height || 768}px;

  padding: 24px 24px 40px 24px;
  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.colors.grey300};
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 500;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  button {
    width: 18px;
    height: 18px;
    background-color: transparent;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

interface DefaultModalProps {
  isOpen: boolean;
  title: string;
  width?: number;
  height?: number;
  children?: React.ReactNode;
  onClickCloseButton?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

const DefaultModal = ({
  isOpen,
  title,
  width,
  height,
  children,
  onClickCloseButton,
}: DefaultModalProps) => {
  return (
    <>
      <ModalBackBlurNav $isModalOpen={isOpen} />
      <ModalBackBlur $isModalOpen={isOpen} $isPublic={false} />
      <Component $isModalOpen={isOpen}>
        <ModalHeader>
          <H3>{title}</H3>
          <Button onClick={onClickCloseButton}>
            <IconClose className="disabled" />
          </Button>
        </ModalHeader>
        {children}
      </Component>
    </>
  );
};

export default DefaultModal;
