import React from 'react';
import styled from 'styled-components';

import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';

import TreemapChart from '../../Molecule/Chart/TreemapChart';
import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';
import Spinner from '../../Molecule/Spinner';

const Component = styled(CardLayout)``;

const Body = styled(CardBodyLayout)`
  position: relative;
  margin-top: 24px;
`;

export interface TreemapChartCardProps {
  data: {
    total: number;
    old: number;
    new: number;
    local_variant_en: string;
    local_variant_ko: string;
  }[];
  isLoading: boolean;
}

const TreemapChartCard = ({ data, isLoading }: TreemapChartCardProps) => {
  return (
    <Component>
      <CardHeaderLayout>
        <DefaultCardHeading title="국내 방문자" />
      </CardHeaderLayout>
      <Body>{isLoading ? <Spinner /> : <TreemapChart data={data} />}</Body>
    </Component>
  );
};

export default TreemapChartCard;
