import React, {
  ReactNodeArray,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import Chart from 'react-apexcharts';
import _ from 'lodash';
import { chart_tooltip } from '../../../Atoms/ChartTooltip';
import DataError from '../../../Atoms/DataError';

const Component = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 491px;

  .apexcharts-canvas {
    svg {
      .apexcharts-inner {
        .apexcharts-treemap-series {
          rect {
            filter: none;
          }
        }
      }
    }
  }

  ${chart_tooltip}
`;

const ChartBlind = styled.div<{ $isShow: boolean }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 40;

  ${(props) => {
    if (props.$isShow) {
      return css`
        display: none;
      `;
    }
    return css``;
  }}
`;

const GradientArr = styled.svg`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -99;
`;

interface TreemapChartState {
  series: {
    data: {
      x: string;
      y: number;
    }[];
  }[];
}

interface TreemapChartProps {
  data: {
    total: number;
    old: number;
    new: number;
    local_variant_en: string;
    local_variant_ko: string;
  }[];
}

const TreemapChart = ({ data }: TreemapChartProps) => {
  const ChartEl = useRef<HTMLDivElement | null>(null);
  const [isShow, setIsShow] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [mouseOverKey, setMouseOverKey] = useState<string | null>(null);
  const [seriesState, setSeries] = useState<TreemapChartState['series']>([
    {
      data: [],
    },
  ]);

  const TreemapOnMouseEnter = (e: any, chartContext: any, config: any) => {
    if (config.dataPointIndex !== -1) {
      const index = config.dataPointIndex;
      const key = config.w.config.series[0].data[index].x;

      setMouseOverKey(key);
    } else {
      setMouseOverKey(null);
    }
  };

  const TreemapOnMouseLeave = (e: any, chartContext: any, config: any) => {
    setMouseOverKey(null);
  };

  function printCustomTooltip({ series, seriesIndex, dataPointIndex, w }: any) {
    if (series[0]) {
      const key = w.config.series[0].data[dataPointIndex].x;
      let barValue0 = 0;
      let barValue1 = 0;
      let barRate0 = 0;
      let barRate1 = 0;

      if (data.length > 0) {
        data.some((element) => {
          if (key === '기타' && element.local_variant_ko === 'not set') {
            barValue0 = element.old;
            barValue1 = element.new;
            barRate0 = Math.round((barValue0 / (barValue0 + barValue1)) * 100);
            barRate1 = Math.round((barValue1 / (barValue0 + barValue1)) * 100);
            return true;
          }
          if (element.local_variant_ko === key) {
            barValue0 = element.old;
            barValue1 = element.new;
            barRate0 = Math.round((barValue0 / (barValue0 + barValue1)) * 100);
            barRate1 = Math.round((barValue1 / (barValue0 + barValue1)) * 100);
            return true;
          }
          return false;
        });
      }
      return `<div class="tooltip">
              <div class="innerBox">
                <div class="category-name">${key}</div>
              </div>
              <div class="innerBox">
                <div class="dot-return"></div>
                <div class="title">${'재방문 사용자 수'}</div>
                <div class="value">${barValue0}</div>
                <div class="rate-return">(${barRate0}%)</div>
              </div>
              <div class="innerBox">
                <div class="dot-new"></div>
                <div class="title">${'신규 사용자 수'}</div>
                <div class="value">${barValue1}</div>
                <div class="rate-new">(${barRate1}%)</div>
              </div>
            </div>`;
    }
    return '';
  }

  const options = {
    chart: {
      dropShadow: {
        enabled: true,
        top: 0,
        left: 0,
        blur: 0,
        color: '#b0b900',
        opacity: 1,
      },
      events: {
        animationEnd(chartContext: any, optionss: any) {
          setIsLoad(true);
        },
        dataPointMouseLeave: TreemapOnMouseLeave,
        dataPointMouseEnter: TreemapOnMouseEnter,
      },
      animation: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      redrawOnParentResize: false,
      redrawOnWindowResize: false,
    },
    legend: {
      show: false,
    },
    dataLabels: {
      style: {
        fontSize: '14px',
        fontFamily: 'Roboto, "Noto Sans KR", sans-serif',
        fontWeight: 400,
        colors: ['#757575'],
      },
    },
    stroke: {
      width: 8,
    },
    tooltip: {
      custom: printCustomTooltip,
    },
    grid: {
      padding: {
        right: -24,
      },
    },
    noData: {
      text: '데이터를 불러오지 못했습니다.',
      align: 'center' as const,
      verticalAlign: 'middle' as const,
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#424242',
        fontSize: '14px',
        fontFamily: 'Roboto, "Noto Sans KR", sans-serif',
      },
    },
  };

  useEffect(() => {
    const { current } = ChartEl;
    if (current && isLoad) {
      const TreemapSeriesSVG = current
        .getElementsByClassName('apexcharts-treemap-series')
        .item(0);
      if (TreemapSeriesSVG) {
        const child = TreemapSeriesSVG.children;
        const city: string[] = [];
        let left = 0;
        let right = 0;
        let bottom = 0;
        for (let index = 0; index < child.length; index += 1) {
          const element = child.item(index);

          if (element && element.nodeName === 'rect') {
            const rectLeft = element.getBoundingClientRect().left;
            const rectRight = element.getBoundingClientRect().right;
            const rectBottom = element.getBoundingClientRect().bottom;

            if (
              rectLeft !== null &&
              rectRight !== null &&
              rectBottom !== null
            ) {
              left = rectLeft;
              right = rectRight;
              bottom = rectBottom;
            }
          }

          if (element && element.tagName === 'g') {
            const textEl = element.children.item(0);
            let isTextOver = false;
            if (textEl) {
              const textLeft = textEl.getBoundingClientRect().left;
              const textRight = textEl.getBoundingClientRect().right;
              const textBottom = textEl.getBoundingClientRect().bottom;

              if (textRight > right - 8) {
                isTextOver = true;
              } else if (textBottom > bottom - 8) {
                isTextOver = true;
              } else if (textLeft < left - 8) {
                isTextOver = true;
              }
            }

            if (element.textContent) {
              city.push(element.textContent);
              if (isTextOver) {
                element.textContent = '';
              }
            }
          }
        }
        let cityIndex = 0;
        for (let index = 0; index < child.length; index += 1) {
          const element = child.item(index);
          if (element && element.nodeName === 'rect' && city[cityIndex]) {
            element.setAttribute(
              'fill',
              `url(#Gradient-${city[cityIndex].replace(/ /g, '')})`
            );
            cityIndex += 1;
          }
        }
        setTimeout(() => {
          setIsShow(true);
        }, 1000);
      }
    }
  }, [ChartEl.current, isLoad]);

  const addGrdiant = useMemo(() => {
    const result: ReactNodeArray = [];
    if (data.length > 0) {
      data.forEach((element) => {
        if (element.total > 0) {
          let rate = 0;
          if (element.old !== 0) {
            rate = Math.round((element.old / element.total) * 100);
          } else {
            rate = 100 - Math.round((element.new / element.total) * 100);
          }

          if (element.local_variant_ko === 'not set') {
            result.push(
              <linearGradient key="기타" id="Gradient-기타">
                {mouseOverKey === '기타' ? (
                  <>
                    <stop offset={`${rate}%`} stopColor="#b0b900" />
                    <stop offset={`${rate}%`} stopColor="#ff9931" />
                    <stop offset="100%" stopColor="#ff9931" />
                  </>
                ) : (
                  <>
                    <stop offset={`${rate}%`} stopColor="#d0d47c" />
                    <stop offset={`${rate}%`} stopColor="#fdb67d" />
                    <stop offset="100%" stopColor="#fdb67d" />
                  </>
                )}
              </linearGradient>
            );
          } else {
            result.push(
              <linearGradient
                key={element.local_variant_ko}
                id={`Gradient-${element.local_variant_ko.replace(/ /g, '')}`}
              >
                {element.local_variant_ko === mouseOverKey ? (
                  <>
                    <stop offset={`${rate}%`} stopColor="#b0b900" />
                    <stop offset={`${rate}%`} stopColor="#ff9931" />
                    <stop offset="100%" stopColor="#ff9931" />
                  </>
                ) : (
                  <>
                    <stop offset={`${rate}%`} stopColor="#d0d47c" />
                    <stop offset={`${rate}%`} stopColor="#fdb67d" />
                    <stop offset="100%" stopColor="#fdb67d" />
                  </>
                )}
              </linearGradient>
            );
          }
        }
      });
      return (
        <GradientArr>
          <defs>{result}</defs>
        </GradientArr>
      );
    }
    return [];
  }, [data, mouseOverKey]);

  const printChart = () => {
    if (data.length > 0 && seriesState[0].data.length > 0) {
      return (
        <Chart
          type="treemap"
          options={options}
          series={seriesState}
          width="100%"
          height="100%"
        />
      );
    }
    return <DataError />;
  };

  useEffect(() => {
    const tempSeries: TreemapChartState['series'] = [
      {
        data: [],
      },
    ];

    if (data.length > 0) {
      setIsLoad(false);
      setIsShow(false);
      data.forEach((element) => {
        if (element.total > 0) {
          if (element.local_variant_ko === 'not set') {
            tempSeries[0].data.push({
              x: '기타',
              y: element.total,
            });
          } else {
            tempSeries[0].data.push({
              x: element.local_variant_ko,
              y: element.total,
            });
          }
        }
      });
      setSeries(tempSeries);
    } else {
      setIsShow(true);
    }
  }, [data]);
  useEffect(() => {
    window.addEventListener(
      'resize',
      _.debounce(() => {
        // window.location.reload();
      }, 1000)
    );
  }, []);

  return (
    <Component ref={ChartEl}>
      {printChart()}
      {addGrdiant}
      <ChartBlind $isShow={isShow} />
    </Component>
  );
};

export default TreemapChart;
