import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../../Atoms/Button';
import Checkbox from '../../../Atoms/CheckBox';
import RadioButton from '../../../Atoms/RadioButton';
import { H3, H6, P2, P3 } from '../../../Atoms/Typo';

const ModalBackBlur = styled.div<{ $isModalOpen: boolean; $isPublic: boolean }>`
  position: fixed;
  background-color: black;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  ${(props) => {
    if (props.$isPublic) {
      return css``;
    }
    return css`
      left: 244px;
    `;
  }}

  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
  z-index: 400;
`;

const ModalBackBlurNav = styled.div<{
  $isModalOpen: boolean;
}>`
  position: fixed;
  background-color: white;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 399;
  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
`;

const ModalWrapper = styled.div<{ $isModalOpen: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border-radius: 8px;
  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
  z-index: 500;
  overflow: hidden;
`;

const ModalTitle = styled(H3)`
  padding: 24px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: 24px; */
  margin-bottom: 80px;
`;

const ModalItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  /* margin: 24px 0px; */
`;

const ModalItemTitle = styled(H6)`
  color: ${(props) => props.theme.colors.grey500};
  margin-bottom: 8px;
`;

const ModalItemButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`;

const ModalItemButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  &:last-child {
    margin-right: 0px;
  }

  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
    margin-left: 8px;
  }
`;

const ModalSplit = styled.div`
  padding: 0px 24px;
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
`;

const ModalbuttonWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;

  button {
    width: 50%;
    height: 100%;
    ${P2} {
      color: white;
    }

    background-color: ${(props) => props.theme.colors.grey500};
    &:last-child {
      background-color: ${(props) => props.theme.colors.grey900};
    }
  }
`;

interface ModalProps {
  isModalOpen: boolean;
  clickedRadioId: string;
  clickedCheckButtonId: string;
  onClickRadio?: (id: 'day' | 'week' | 'month' | 'hour') => void;
  onClickCheckButton?: (id: 'all' | 'return' | 'new') => void;
  onClickCancel?: () => void;
  onClickConfirm?: () => void;
}

const Modal = ({
  isModalOpen,
  clickedRadioId,
  clickedCheckButtonId,
  onClickRadio,
  onClickCheckButton,
  onClickCancel,
  onClickConfirm,
}: ModalProps) => {
  return (
    <>
      <ModalBackBlur $isModalOpen={isModalOpen} $isPublic={false} />
      <ModalBackBlurNav $isModalOpen={isModalOpen} />
      <ModalWrapper $isModalOpen={isModalOpen}>
        <ModalTitle>상세 필터</ModalTitle>

        <ModalBody>
          <ModalSplit />
          <ModalItem>
            <ModalItemTitle>기간</ModalItemTitle>
            <ModalItemButtonWrapper>
              <ModalItemButton>
                <RadioButton
                  isCheck={clickedRadioId === 'hour'}
                  onClick={() => {
                    if (onClickRadio) {
                      onClickRadio('hour');
                    }
                  }}
                />
                <P3>시간별</P3>
              </ModalItemButton>
              <ModalItemButton>
                <RadioButton
                  isCheck={clickedRadioId === 'day'}
                  onClick={() => {
                    if (onClickRadio) {
                      onClickRadio('day');
                    }
                  }}
                />
                <P3>일별</P3>
              </ModalItemButton>
              <ModalItemButton>
                <RadioButton
                  isCheck={clickedRadioId === 'week'}
                  onClick={() => {
                    if (onClickRadio) {
                      onClickRadio('week');
                    }
                  }}
                />
                <P3>주별</P3>
              </ModalItemButton>
              <ModalItemButton>
                <RadioButton
                  isCheck={clickedRadioId === 'month'}
                  onClick={() => {
                    if (onClickRadio) {
                      onClickRadio('month');
                    }
                  }}
                />
                <P3>월별</P3>
              </ModalItemButton>
            </ModalItemButtonWrapper>
          </ModalItem>
          <ModalSplit />
          {/* <ModalItem>
            <ModalItemTitle>사용자 그룹분류</ModalItemTitle>
            <ModalItemButtonWrapper>
              <ModalItemButton>
                <Checkbox
                  isCheck={clickedCheckButtonId === 'all'}
                  onClick={() => {
                    if (onClickCheckButton) {
                      onClickCheckButton('all');
                    }
                  }}
                />
                <div className="title">전체 사용자</div>
              </ModalItemButton>
              <ModalItemButton>
                <Checkbox
                  isCheck={clickedCheckButtonId === 'return'}
                  onClick={() => {
                    if (onClickCheckButton) {
                      onClickCheckButton('return');
                    }
                  }}
                />
                <div className="title">재방문 사용자</div>
              </ModalItemButton>
              <ModalItemButton>
                <Checkbox
                  isCheck={clickedCheckButtonId === 'new'}
                  onClick={() => {
                    if (onClickCheckButton) {
                      onClickCheckButton('new');
                    }
                  }}
                />
                <div className="title">신규 사용자</div>
              </ModalItemButton>
            </ModalItemButtonWrapper>
          </ModalItem>
          <ModalSplit /> */}
        </ModalBody>
        <ModalbuttonWrapper>
          <Button
            onClick={() => {
              if (onClickCancel) {
                onClickCancel();
              }
            }}
          >
            <P2>취소</P2>
          </Button>
          <Button
            onClick={() => {
              if (onClickConfirm) {
                onClickConfirm();
              }
            }}
          >
            <P2>확인</P2>
          </Button>
        </ModalbuttonWrapper>
      </ModalWrapper>
    </>
  );
};

export default Modal;
