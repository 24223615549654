import React from 'react';
import styled from 'styled-components';
import { P3 } from '../../../Atoms/Typo';

const Component = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LegendBar = styled.div`
  flex: 1;
  background-image: linear-gradient(to bottom, #9d7bf0, #e8dcfd);
  width: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const LegendValue = styled.div`
  ${P3};
  height: 19px;
  color: ${(props) => props.theme.colors.grey600};
`;

interface LocalMapLegendProps {
  max: number;
}

const LocalMapLegend = ({ max }: LocalMapLegendProps) => {
  return (
    <Component>
      <LegendValue>{max}</LegendValue>
      <LegendBar />
      <LegendValue>0</LegendValue>
    </Component>
  );
};

export default LocalMapLegend;
