import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../modules';

import ContentsTable from '../../../components/Molecule/Table/ContensTable';

import { getAIVORYTopicUsageWordcloudAsync } from '../../../modules/aivoryUsageAnalytics/contentKeywordAnalysis';
import { GetAIVORYTopicUsageWordcloudParamType } from '../../../api/aivoryUsageAnalytics/contentKeywordAnalysis/type';
import WordCloudChartCard from '../../../components/Organisms/Card/WordCloudChartCard';
import KeywordRankTable from '../../../components/Organisms/Table/KeywordRankTable';
import Header from '../../../components/Organisms/Header';
import ContentsLayout from '../../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';

const ContentsTableComponent = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  ${(props) => {
    if (props.$isOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
`;

const ModalBackground = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  left: 0px;
  height: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 30;
  ${(props) => {
    if (props.$isOpen) {
      return css`
        overflow-y: hidden !important;
      `;
    }
    return css`
      display: none;
    `;
  }}
`;

const ContentKeywordAnalysis = () => {
  const dispatch = useDispatch();
  const [isOpenContentsTable, setIsOpenContentsTable] = useState(false);
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );
  const { isLoadingTopicUsageWordcloud, topicUsageWordcloudData } = useSelector(
    (state: RootState) => state.aivoryContentKeywordAnalysis
  );

  const onGetAIVORYTopicUsageWordcloud = (
    info: GetAIVORYTopicUsageWordcloudParamType
  ) => {
    dispatch(getAIVORYTopicUsageWordcloudAsync.request(info));
  };

  useEffect(() => {
    const start_date = moment(startDate).format('YYYY-MM-DD');
    const end_date = moment(endDate).format('YYYY-MM-DD');
    if (!isDatePickerToggle) {
      onGetAIVORYTopicUsageWordcloud({
        start_date,
        end_date,
      });
    }
  }, [isDatePickerToggle, startDate, endDate]);

  const onCloseTable = () => {
    setIsOpenContentsTable(false);
  };

  // const onOpenTable = () => {
  //   setIsOpenContentsTable(true);
  // };
  return (
    <ContentsLayout>
      <ContentsTableComponent $isOpen={isOpenContentsTable}>
        <ContentsTable onCloseTable={onCloseTable} data={null} />
      </ContentsTableComponent>
      <ModalBackground $isOpen={isOpenContentsTable} />
      <Header title="콘텐츠 키워드 분석" isDatePicker />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={3}>
          <KeywordRankTable
            title="주요 콘텐츠 키워드"
            isLoading={isLoadingTopicUsageWordcloud}
            data={topicUsageWordcloudData?.topic_cloud || undefined}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={9}>
          <WordCloudChartCard
            isTopic
            isLoading={isLoadingTopicUsageWordcloud}
            data={topicUsageWordcloudData?.topic_cloud || undefined}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default ContentKeywordAnalysis;
