import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import {
  BotTrafficPagePayload,
  BotTrafficSitePayload,
} from '../../../../api/report/type';
import DataError from '../../../Atoms/DataError';
import { H3, P1, P3 } from '../../../Atoms/Typo';
import Header from '../../../Molecule/Card/CardHeader';
import ReportSummary from '../../../Molecule/Card/CardSummary/ReportSummary';
import Title from '../../../Molecule/Card/CardTitle/ReportTitle';
import Title2 from '../../../Molecule/Card/CardTitle/ReportTitle2';
import DounutChart from '../../../Molecule/Chart/DonutChart/Simple';
import PopularPageCard from './PopularPageCard';

const Component = styled.div`
  width: 100%;
  height: 100%;
  min-height: 470px;
  display: flex;
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  border-radius: 8px;
`;

const ReportRaw = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
`;

const Split = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
`;

const InnerComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  padding: 24px 24px 40px 24px;

  &:last-child {
    width: 75%;
  }
`;

const Body1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 24px;
`;

const Desc = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 24px;
  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
  } ;
`;

const ListWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const ListItem = styled.div`
  /* flex: 1 1 300px; */
  max-width: 33.33%;
  flex-basis: 33.33%;

  height: 100%;
  padding-left: 20px;
  padding-right: 20px;

  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 0px;
  }
`;

const Chart = styled.div`
  flex: 1;
  width: 100%;
`;

interface BotReportCardProps {
  siteData: BotTrafficSitePayload | null;
  pageData: BotTrafficPagePayload[] | null;
  month: string;
}

interface BotReportCardState {
  chartData: {
    group: string;
    rate: number;
  }[];
}

const BotReportCard = ({ siteData, pageData, month }: BotReportCardProps) => {
  const [chartData, setChartData] = useState<BotReportCardState['chartData']>(
    []
  );
  const [currentBotTraffic, setCurrentBotTraffic] = useState(0);
  const [previousMonth, setPreviousMonth] = useState(0);
  const [avgPercent, setAvgPercent] = useState(0);
  const [report, setReport] = useState('');

  useEffect(() => {
    if (siteData) {
      const chart_data: BotReportCardState['chartData'] = [];
      chart_data.push({
        group: '봇 트래픽',
        rate: siteData.bot_percent,
      });

      chart_data.push({
        group: '유저 트래픽',
        rate: 100 - siteData.bot_percent,
      });

      setAvgPercent(siteData.total_bot_avg);
      if (siteData.bot_percent < siteData.total_bot_avg) {
        setReport(`로 적절합니다.`);
      } else {
        setReport(`입니다.`);
      }

      setCurrentBotTraffic(siteData.bot_percent);
      setChartData(chart_data);
      setPreviousMonth(siteData.month_on_month);
    }
  }, [siteData]);

  //   useEffect(() => {
  //     if (pageData) {
  //     }
  //   }, [pageData]);

  return (
    <Component>
      <InnerComponent>
        <Title title="봇은 얼마나 유입되나요?" />
        <ReportSummary>
          <ReportRaw>
            <P1>{month} 한 달동안</P1>
          </ReportRaw>

          <ReportRaw>
            <P1> 봇 트래픽은</P1>
            <H3>{currentBotTraffic}%</H3>
            <P1>{report}</P1>
          </ReportRaw>
        </ReportSummary>
        <Body1>
          <Chart>
            <DounutChart data={chartData} isBot previousMonth={previousMonth} />
          </Chart>
        </Body1>
        <Desc>
          <P3>
            대부분의 웹 사이트는 봇 트래픽을 {avgPercent}% 이하로
            <br />
            유지하고 있습니다.
          </P3>
        </Desc>
      </InnerComponent>
      <Split />
      <InnerComponent>
        <Header>
          <Title2 title="페이지 별 봇 유입 현황" />
        </Header>
        <ListWrapper>
          {pageData && pageData.length > 0 ? (
            pageData.map((element) => {
              return (
                <ListItem key={element.page_id}>
                  <PopularPageCard
                    cardTitle=""
                    cardValue={0}
                    pageTitle={element.page_name}
                    pageUrl={element.simple_url}
                    imgUrl={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
                      'voda_tenant'
                    )}/auto/${element.page_id}/${encodeURIComponent(
                      'page_screenshot'
                    )}`}
                    isBot
                    botPercent={element.bot_percent}
                  />
                </ListItem>
              );
            })
          ) : (
            <DataError />
          )}
        </ListWrapper>
      </InnerComponent>
    </Component>
  );
};

export default BotReportCard;
