import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { ForecastPayload } from '../../../../api/report/type';
import { H3, P1 } from '../../../Atoms/Typo';
import Title from '../../../Molecule/Card/CardTitle/ReportTitle';
import MultiLineChart from '../../../Molecule/Chart/MultiLineChart';
import Count from '../../../Molecule/Count/NumberToKorean';
import Spinner from '../../../Molecule/Spinner';
import BarChartCard from '../BarChartCard';

const Component = styled.div`
  width: 100%;
  height: 497px;
  display: flex;
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  border-radius: 8px;
`;

const ReportSummaryWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  ${H3} {
    margin-left: 4px;
  }
  flex-wrap: wrap;
  white-space: nowrap;
`;

const PlusRate = styled(H3)`
  color: ${(props) => props.theme.colors.success5};
  margin-right: 4px;
`;

const MinusRate = styled(H3)`
  color: ${(props) => props.theme.colors.danger5};
  margin-right: 4px;
`;

const Split = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
`;

const InnerComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding: 24px 24px 40px 24px;
`;

const Body = styled.div`
  width: 100%;
  flex: 1;
  margin-top: 40px;
`;

interface ForecastReportCardProps {
  uvData: ForecastPayload | null;
  pvData: ForecastPayload | null;
  isLoading: boolean;
}

interface ForecastReportCardState {
  rebuildData: {
    [key: string]: number | boolean;
    isHighlight: boolean;
  }[];
}

const ForecastReportCard = ({
  uvData,
  pvData,
  isLoading,
}: ForecastReportCardProps) => {
  const [uvTotal, setUVTotal] = useState(0);
  const [pvTotal, setPVTotal] = useState(0);
  const [compareRateUV, setCompareRateUV] = useState(0);
  const [compareRatePV, setCompareRatePV] = useState(0);
  const [rebuildUVData, setRebuildUVData] = useState<
    ForecastReportCardState['rebuildData']
  >([]);
  const [rebuildPVData, setRebuildPVData] = useState<
    ForecastReportCardState['rebuildData']
  >([]);

  const printRate = (rate: number) => {
    if (rate > 0) {
      return <PlusRate>{`(+ ${rate.toFixed(0)}%)`}</PlusRate>;
    }
    return <MinusRate>{`(- ${Math.abs(rate).toFixed(0)}%)`}</MinusRate>;
  };

  useEffect(() => {
    if (uvData) {
      const rebuildData: ForecastReportCardState['rebuildData'] = [];
      const month_arr = Object.keys(uvData.graph);

      let max_uv = 0;

      month_arr.sort((a, b) => {
        const a_date = new Date(a);
        const b_date = new Date(b);
        const case1 = Number(a_date) - Number(b_date);
        return case1;
      });

      month_arr.forEach((element, index) => {
        if (max_uv < uvData.graph[element]) {
          max_uv = uvData.graph[element];
        }

        rebuildData.push({
          [`${element.split('-')[1]}월`]: uvData.graph[element],
          isHighlight: index === 2,
        });
      });
      setUVTotal(uvData.graph[month_arr[2]]);
      setRebuildUVData(rebuildData);
      setCompareRatePV(
        ((uvData.graph[month_arr[2]] - uvData.graph[month_arr[1]]) /
          uvData.graph[month_arr[1]]) *
          100
      );
    }
  }, [uvData]);

  useEffect(() => {
    if (pvData) {
      const rebuildData: ForecastReportCardState['rebuildData'] = [];
      const month_arr = Object.keys(pvData.graph);

      let max_pv = 0;

      month_arr.sort((a, b) => {
        const a_date = new Date(a);
        const b_date = new Date(b);
        const case1 = Number(a_date) - Number(b_date);
        return case1;
      });

      month_arr.forEach((element, index) => {
        if (max_pv < pvData.graph[element]) {
          max_pv = pvData.graph[element];
        }

        rebuildData.push({
          [`${element.split('-')[1]}월`]: pvData.graph[element],
          isHighlight: index === 2,
        });
      });
      setPVTotal(pvData.graph[month_arr[2]]);
      setRebuildPVData(rebuildData);
      setCompareRateUV(
        ((pvData.graph[month_arr[2]] - pvData.graph[month_arr[1]]) /
          pvData.graph[month_arr[1]]) *
          100
      );
    }
  }, [pvData]);
  return (
    <Component>
      <InnerComponent>
        <Title title="이번 달 사용자 수는?" />
        <ReportSummaryWrapper>
          <P1>이번 달은</P1>
          <H3>약 {uvTotal}명</H3>
          {printRate(compareRateUV)}
          <P1>이 방문할 것으로 예상됩니다.</P1>
        </ReportSummaryWrapper>
        <Body>
          {isLoading ? (
            <Spinner />
          ) : (
            <BarChartCard
              title=""
              desc=""
              data={rebuildUVData}
              isLabelInChart={false}
              isForecast
            />
          )}
        </Body>
      </InnerComponent>
      <Split />
      <InnerComponent>
        <Title title="이번 달 페이지 뷰 수는?" />
        <ReportSummaryWrapper>
          <P1>이번 달은</P1>
          <H3>약 {pvTotal}개</H3>
          {printRate(compareRatePV)}
          <P1>페이지를 이용할 것으로 예상됩니다.</P1>
        </ReportSummaryWrapper>
        <Body>
          {isLoading ? (
            <Spinner />
          ) : (
            <BarChartCard
              title=""
              desc=""
              data={rebuildPVData}
              isLabelInChart={false}
              isForecast
            />
          )}
        </Body>
      </InnerComponent>
    </Component>
  );
};

export default ForecastReportCard;
