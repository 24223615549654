import React from 'react';
import styled from 'styled-components';

import { Button } from '../../Atoms/Button';
import { IconSMArrowRight } from '../../Atoms/Icon';
import { P3 } from '../../Atoms/Typo';

const Component = styled.div`
  button {
    display: flex;
    align-items: center;
    height: 20px;
    background-color: transparent;
    width: fit-content;

    ${P3} {
      color: ${(props) => props.theme.colors.grey500};
      line-height: 1.79;
    }

    svg {
      width: 7px;
      height: 11.3px;
      margin-left: 8px;
    }
  }
`;

interface DetailButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const DetailButton = ({ onClick }: DetailButtonProps) => {
  return (
    <Component>
      <Button onClick={onClick}>
        <P3>자세히 보기</P3>
        <IconSMArrowRight className="disabled" />
      </Button>
    </Component>
  );
};

export default DetailButton;
