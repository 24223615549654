import React from 'react';
import styled from 'styled-components';
import { P4 } from '../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: ${(props) => props.theme.colors.purple4};
  border-radius: 12px;
  width: fit-content;
  ${P4} {
    color: ${(props) => props.theme.colors.purple1};
  } ;
`;

const Split = styled.div`
  width: 1px;
  height: 12px;
  margin-left: 4px;
  margin-right: 4px;
  background-color: ${(props) => props.theme.colors.purple2};
`;

interface LisenceProps {
  pv: number;
  day: number;
}

const Lisence = ({ pv, day }: LisenceProps) => {
  const makeComma = (numberValue: number) => {
    const numberStr = String(numberValue);

    return numberStr.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  };

  return (
    <Component>
      <div>
        <P4>{makeComma(pv)}PV 남음</P4>
      </div>
      <Split />
      <div>
        <P4>{day}일 남음</P4>
      </div>
    </Component>
  );
};

export default Lisence;
