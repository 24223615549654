import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  GetScrollEventsResponseType,
  UserUsabilityDatum,
} from '../../../../api/pageAnalytics/uxAnalytics/scroll/type';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import UXHeatmapBarChart from '../../../Molecule/Chart/UXHeatmapBarChart';
import FullPageScreenShot from '../../../Molecule/FullPageScreenShot';
import ScrollHeatmapTooltip, {
  ScrollHeatmapTooltipProps,
} from '../../../Molecule/Tooltip/ScrollHeatmapTooltip';
import ScrollHeatmapCrossHair from '../../../Molecule/ScrollHeatmapCrossHair';
import ScrollHeatmapLayer from '../../../Molecule/HeatmapLayer/ScrollHeatmapLayer';
import UXHeatmapLineChart from '../../../Molecule/Chart/UXHeatmapLineChart';
import Spinner from '../../../Molecule/Spinner';

const Component = styled(CardLayout)`
  padding: 0px;
  position: relative;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 200px;
  z-index: 20;
`;

export interface ScrollHeatmapCardProps {
  pageId: string;
  data: GetScrollEventsResponseType['payload'] | null;
  isLoading: boolean;
}

const ScrollHeatmapCard = ({
  pageId,
  data,
  isLoading,
}: ScrollHeatmapCardProps) => {
  const ComponentEl = useRef<HTMLDivElement>(null);
  const CrossHairEl = useRef<HTMLDivElement>(null);
  const TooltipEl = useRef<HTMLDivElement>(null);
  const fullPageScreenShotEl = useRef<HTMLImageElement>(null);
  const [isImgLoad, setIsImgLoad] = useState(false);
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  const [mouseoverTooltipInfo, setMouseoverTooltipInfo] = useState<
    ScrollHeatmapTooltipProps['data']
  >([]);
  const [isTooltipOverHalf, setIsTooltipOverHalf] = useState(false);
  const [barCnt, setBarCnt] = useState(1);
  const [pageHeight, setpageHeight] = useState(1);
  const [gradientHeight, setGradientHeight] = useState(0);
  const [gradientWidth, setGradientWidth] = useState(0);
  const [barHeight, setBarHeight] = useState(0);
  const [lastBarHeight, setLastBarHeight] = useState(0);
  const [averageHeightToRenderPixel, setAverageHeightToRenderPixel] =
    useState(0);

  const averageHeight = 900;

  const handleImageLoaded = () => {
    setIsImgLoad(true);
  };

  const handleImageLoadStart = () => {
    setIsImgLoad(false);
  };

  const onMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    dataLength: number,
    element: UserUsabilityDatum,
    index: number
  ) => {
    const { current } = ComponentEl;
    const crossHairElCurrent = CrossHairEl.current;
    const TooltipElCurrent = TooltipEl.current;
    if (current && crossHairElCurrent && TooltipElCurrent) {
      setMouseoverTooltipInfo([
        {
          id: 0,
          title: '평균 주목 시간',
          value: `${element.avg_attention_time}초`,
        },
        {
          id: 1,
          title: '주목 비율',
          value: `${element.attention_rate}%`,
        },
        {
          id: 2,
          title: '사용자 도달 비율',
          value: `${element.view_rate}%`,
        },
      ]);

      const componentTop =
        current.getBoundingClientRect().top + window.pageYOffset;

      if (index > dataLength / 2) {
        setIsTooltipOverHalf(true);
        TooltipElCurrent.style.top = `${
          event.pageY - componentTop - TooltipElCurrent.offsetHeight - 12
        }px`;
      } else {
        setIsTooltipOverHalf(false);
        TooltipElCurrent.style.top = `${event.pageY - componentTop + 12}px`;
      }

      crossHairElCurrent.style.top = `${event.pageY - componentTop}px`;
    }
  };

  const printTooltip = () => {
    return (
      <ScrollHeatmapTooltip
        tooltip_ref={TooltipEl}
        data={mouseoverTooltipInfo}
        isTooltipOverHalf={isTooltipOverHalf}
      />
    );
  };

  // 2
  useEffect(() => {
    const barHeightTemp = (gradientHeight * 100) / pageHeight;
    setBarHeight(barHeightTemp);

    setAverageHeightToRenderPixel(
      Math.round((gradientHeight * averageHeight) / pageHeight)
    );

    if (gradientHeight % barHeightTemp !== 0) {
      setLastBarHeight(gradientHeight % barHeightTemp);
    } else {
      setLastBarHeight(barHeightTemp);
    }
  }, [gradientHeight, gradientWidth, pageHeight, data]);

  // 1
  useEffect(() => {
    if (data) {
      setBarCnt(Math.ceil(data.height / 100));
      setpageHeight(data.height);
    }
  }, [data]);

  // 1
  useEffect(() => {
    const { current } = ComponentEl;
    if (current && isImgLoad) {
      setGradientHeight(current.getBoundingClientRect().height);
      setGradientWidth(current.getBoundingClientRect().width);
    }
  }, [isImgLoad, ComponentEl.current]);

  return (
    <Component card_ref={ComponentEl}>
      <FullPageScreenShot
        img_src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
          'voda_tenant'
        )}/auto/${pageId}/${encodeURIComponent('page_screenshot')}`}
        imgRef={fullPageScreenShotEl}
        onLoad={handleImageLoaded}
        onLoadStart={handleImageLoadStart}
      />
      {isLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          <ScrollHeatmapLayer
            data={data}
            barHeight={barHeight}
            isImgLoad={isImgLoad}
            gradientWidth={gradientWidth}
          />
          <UXHeatmapLineChart
            data={data}
            barHeight={barHeight}
            gradientWidth={gradientWidth}
          />
          <UXHeatmapBarChart
            data={data}
            barHeight={barHeight}
            lastBarHeight={lastBarHeight}
            barCnt={barCnt}
            onMouseMove={onMouseMove}
          />
          {printTooltip()}
          <ScrollHeatmapCrossHair crossHairRef={CrossHairEl} />
        </>
      )}
    </Component>
  );
};

export default ScrollHeatmapCard;
