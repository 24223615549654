import React, { ReactNodeArray } from 'react';
import styled from 'styled-components';
import { PageElementsFlowPayload } from '../../../api/pageAnalytics/uxAnalytics/mouseflow/type';

const Component = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

interface MouseFlowLineProps {
  elementBoxData: PageElementsFlowPayload[] | null;
}

const MouseFlowLine = ({ elementBoxData }: MouseFlowLineProps) => {
  const GenerateElementLine = () => {
    const result: ReactNodeArray = [];
    if (elementBoxData) {
      elementBoxData.forEach((element, index) => {
        if (index < elementBoxData.length - 1) {
          const d = `M ${element.element.x + element.element.width / 2} ${
            element.element.y + element.element.height / 2
          } L ${
            elementBoxData[index + 1].element.x +
            elementBoxData[index + 1].element.width / 2
          } ${
            elementBoxData[index + 1].element.y +
            elementBoxData[index + 1].element.height / 2
          }`;
          result.push(
            <path
              key={`element-line-${element.rank}`}
              d={d}
              stroke="#f5ab35"
              strokeDasharray="5"
              strokeWidth="2"
              fill="none"
            />
          );
        }
      });
    }
    return result;
  };

  return <Component>{GenerateElementLine()}</Component>;
};

export default MouseFlowLine;
