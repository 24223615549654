import React, { useEffect, useState, useCallback, ReactNodeArray } from 'react';
import styled from 'styled-components';
import Chart from 'react-apexcharts';

import { tooltip } from '../../../Atoms/ChartTooltip/LineChartTooltip';
import DataError from '../../../Atoms/DataError';

const Component = styled.div`
  width: 100%;
  height: 272px;

  ${tooltip};
  vertical-align: middle;
  text-align: center;
`;

interface LineChartProps {
  name: string;
  data?: {
    [key: string]: number;
  };
  option: string;
  isTimeCount: boolean;
  className?: string;
}

const LineChart = ({
  data,
  option,
  name,
  isTimeCount,
  className,
}: LineChartProps) => {
  const [statesSries, setSeries] = useState<
    {
      name: string;
      data: {
        x: string;
        y: number;
      }[];
    }[]
  >([]);
  const [categoryArr, setCatogory] = useState<string[]>([]);
  const [yAxisRange, setYAxisRange] = useState({ min: 0, max: 1 });

  const rebuildData = () => {
    const result: {
      x: string;
      y: number;
    }[] = [];
    let min = 0;
    let max = 1;

    if (data) {
      const weekCategory = ['월', '화', '수', '목', '금', '토', '일'];
      const monthCategory: string[] = [];
      const categoryArrTemp = Object.keys(data);

      switch (option) {
        case 'day':
          setCatogory(categoryArrTemp);
          categoryArrTemp.forEach((element) => {
            if (data[element] > max) {
              max = data[element];
            }
            if (data[element] < min) {
              min = data[element];
            }
            result.push({ x: `${element.split(' ')[1]}시`, y: data[element] });
          });
          break;
        case 'week':
          setCatogory(weekCategory);
          categoryArrTemp.forEach((element, index) => {
            if (data[element] > max) {
              max = data[element];
            }
            if (data[element] < min) {
              min = data[element];
            }
            result.push({ x: weekCategory[index], y: data[element] });
          });
          break;
        case 'month':
          categoryArrTemp.forEach((element) => {
            const categoryElement = `${element.split('-')[2]}일`;
            monthCategory.push(categoryElement);
            if (data[element] > max) {
              max = data[element];
            }
            if (data[element] < min) {
              min = data[element];
            }
            result.push({ x: categoryElement, y: data[element] });
          });
          setCatogory(monthCategory);
          break;
        default:
          break;
      }
      setSeries([
        {
          name,
          data: result,
        },
      ]);
      setYAxisRange({
        min: min - 0.01,
        max: max + 0.01,
      });
    }
  };

  const makeComma = (value: number) => {
    const numberStr = String(value);

    return numberStr.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  };

  const convertSec = (sec: number) => {
    const cvMin = Math.floor(sec / 60);
    const cvSec = Math.floor(sec % 60);
    return { sec: cvSec, min: cvMin };
  };

  const options = {
    noData: {
      text: '데이터를 불러오지 못했습니다.',
      align: 'center' as const,
      verticalAlign: 'middle' as const,
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#424242',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Roboto, "Noto Sans KR", sans-serif',
      },
    },
    dataLabels: {
      enabled: false,
    },
    chart: {
      // offsetX: -24,
      // offsetY: 0,
      // parentHeightOffset: 0,

      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      width: '100%',

      events: {
        mouseMove(event: any, chartContext: any, config: any) {
          const apexcharts_tooltip = chartContext.el.querySelector(
            '.apexcharts-tooltip'
          );
          const { pointsArray } = config.globals;
          const { seriesIndex } = config;
          const dataPointIndex =
            config.dataPointIndex === -1 ? 0 : config.dataPointIndex;

          if (seriesIndex !== -1) {
            const position = pointsArray[seriesIndex][dataPointIndex];

            apexcharts_tooltip.style.top = `${position[1] - 8}px`;
            apexcharts_tooltip.style.left = `${position[0]}px`;
          }
        },
      },
    },
    tooltip: {
      custom({
        series,
        seriesIndex,
        dataPointIndex,
      }: {
        series: any;
        seriesIndex: any;
        dataPointIndex: any;
      }) {
        if (isTimeCount) {
          const cv = convertSec(series[seriesIndex][dataPointIndex]);
          return `
            <div class="tooltip">
                <div class="value">
                    ${cv.min}분 ${cv.sec}초
                </div>
            </div>
          `;
        }
        return `
            <div class="tooltip">
                <div class="value">
                    ${makeComma(series[seriesIndex][dataPointIndex])}
                </div>
            </div>
        `;
      },
    },
    colors: ['#ab96ff'],
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      // padding: {
      //   right: -24,
      //   // left: -20,
      // },
    },
    stroke: {
      width: 2,
      curve: 'smooth' as const,
    },
    yaxis: {
      labels: {
        show: false,
      },
      min: yAxisRange.min,
      max: yAxisRange.max,
    },
    xaxis: {
      tooltip: {
        enabled: true,
        formatter(val: any, opts: any) {
          switch (option) {
            case 'day':
              return `${categoryArr[opts.dataPointIndex].split(' ')[1]}:00`;
            case 'week':
              return `${categoryArr[opts.dataPointIndex]}`;
            default:
              return `${categoryArr[opts.dataPointIndex]}`;
          }
        },
        offsetY: 10,
      },
      labels: {
        rotate: 0,
        style: {
          colors: '#9e9e9e',
          fontSize: '12px',
          fontFamily: 'Roboto, "Noto Sans KR", sans-serif',
          fontWeight: 400,
        },
      },
      tickAmount: 5,
      axisTicks: {
        show: false,
      },
      type: 'category' as const,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          {
            offset: 0,
            color: '#ab96ff',
            opacity: 1,
          },
          {
            offset: 2,
            color: '#ab96ff',
            opacity: 0.9,
          },
          {
            offset: 60,
            color: '#ab96ff',
            opacity: 0.3,
          },
          {
            offset: 100,
            color: '#ab96ff',
            opacity: 0,
          },
        ],
      },
    },
  };

  const printChart = useCallback(() => {
    const result: ReactNodeArray = [];
    if (data) {
      result.push(
        <Chart
          type="area"
          series={statesSries}
          options={options}
          width="100%"
          height="100%"
          key="LineChart"
        />
      );
    } else {
      result.push(<DataError key="DataError" />);
    }
    return result;
  }, [data, statesSries]);

  useEffect(() => {
    rebuildData();

    return () => {
      setSeries([]);
      setCatogory([]);
      setYAxisRange({ min: 0, max: 1 });
    };
  }, [data]);

  return <Component className={className}>{printChart()}</Component>;
};

export default LineChart;
