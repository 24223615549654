import React from 'react';
import styled from 'styled-components';
import { H3 } from '../../../Atoms/Typo';

const Component = styled.div``;

interface TitleProps {
  title: string;
}

const Title = ({ title }: TitleProps) => {
  return (
    <Component className="voda_chart_title">
      <H3>{title}</H3>
    </Component>
  );
};

export default Title;
