import React from 'react';
import styled from 'styled-components';

import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';

import LineChart from '../../Molecule/Chart/LineChart';
import RateCount from '../../Molecule/Count/RateCount';
import TimeCount from '../../Molecule/Count/TimeCount';
import ValueCount from '../../Molecule/Count/ValueCount';
import EngKorCardHeading from '../../Molecule/Heading/Card/EngKorCardHeading';
import Spinner from '../../Molecule/Spinner';

const Component = styled(CardLayout)`
  min-height: 450px;
`;

const Chart = styled(LineChart)`
  margin-bottom: 40px;
`;

const CountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export interface DashboardLineCardProps {
  titleEng: string;
  titleKor: string;

  lineChartName: string;
  option: string;
  isTimeCount: boolean;
  isLoading: boolean;
  data?: {
    [key: string]: number;
  };
  value?: number;
  rate?: number;
  time?: number;
}

const DashboardLineCard = ({
  titleEng,
  titleKor,
  lineChartName,
  option,
  isTimeCount,
  isLoading,
  data,
  value,
  rate,
  time,
}: DashboardLineCardProps) => {
  return (
    <Component>
      <CardHeaderLayout>
        <EngKorCardHeading titleEng={titleEng} titleKor={titleKor} />
      </CardHeaderLayout>
      <CardBodyLayout>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Chart
              name={lineChartName}
              option={option}
              isTimeCount={isTimeCount}
              data={data}
            />
            <CountWrapper>
              {isTimeCount ? (
                <TimeCount time={time} />
              ) : (
                <ValueCount value={value} />
              )}
              <RateCount rate={rate} />
            </CountWrapper>
          </>
        )}
      </CardBodyLayout>
    </Component>
  );
};
export default DashboardLineCard;
