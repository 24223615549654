import React from 'react';
import styled from 'styled-components';
import DefaultLink from '../../Atoms/Link';
import { IconSMArrowRight } from '../../Atoms/Icon';
import { P3 } from '../../Atoms/Typo';

const Component = styled.div`
  a {
    display: flex;
    align-items: center;
    color: white;
    div {
      line-height: 1;
    }
    svg {
      display: flex;
      width: 7px;
      height: 12px;
      margin-left: 8px;
      path {
        fill: white;
      }
    }
  }

  ${P3} {
    color: white;
    line-height: 1;
  }
`;

interface ProfileLinkProps {
  userName: string;
}

const ProfileLink = ({ userName }: ProfileLinkProps) => {
  return (
    <Component className="voda_profile_link">
      <DefaultLink to="/myPage">
        <P3>{userName} 님</P3>
        <IconSMArrowRight />
      </DefaultLink>
    </Component>
  );
};

export default ProfileLink;
