import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../modules';

// Private Public components
import PublicRoute from '../_PublicRoute';
import LoginPage from '../LoginPage';
import NotfoundPage from '../../components/NotfoundPage';

// Private components
import PrivateRoute from '../_PrivateRoute';
import ServiceDashboard from '../ServiceAnalytics/Dashboard';
import ServiceUserAnalytics from '../ServiceAnalytics/UserAnalytics';
import ServicePageViewAnalytics from '../ServiceAnalytics/PageViewAnalytics';
import ServiceSessionAnalytics from '../ServiceAnalytics/SessionAnalytics';
import ServiceEnvironmentAnalysis from '../ServiceAnalytics/EnvironmentAnalysis';

import PageUXAnalysisList from '../PageAnalytics/PageList/PageUXAnalysis';
import UserFlowAnalysisList from '../PageAnalytics/PageList/UserFlowAnalysis';
import UXAnalysis from '../PageAnalytics/UXAnalysis';
import PageUserFlowAnalysis from '../PageAnalytics/UserFlowAnalysis';

import AIVORYDashboard from '../AIVORYUsageAnalytics/Dashboard';
import SearchUsageAnalysis from '../AIVORYUsageAnalytics/SearchUsageAnalysis';
import PopularSearchAnalysis from '../AIVORYUsageAnalytics/PopularSearchAnalysis';
import ContentKeywordAnalysis from '../AIVORYUsageAnalytics/ContentKeywordAnalysis';

import MyPage from '../MyPage';

import Report from '../Report';

const env = process.env.NODE_ENV;
let isDevelopment = false;
if (env === 'development') {
  isDevelopment = true;
}
const Routes = () => {
  // redux
  const { isLogin } = useSelector((state: RootState) => state.authentication);

  return (
    <>
      {(isLogin && localStorage.getItem('voda_tenant')) || isDevelopment ? (
        <Switch>
          <PrivateRoute
            exact
            path="/serviceAnalytics"
            title="서비스 이용 분석"
            Component={ServiceDashboard}
          />
          <PrivateRoute
            exact
            path="/serviceAnalytics/userAnalytics"
            title="사용자 수 분석"
            Component={ServiceUserAnalytics}
          />
          <PrivateRoute
            exact
            path="/serviceAnalytics/pageViewAnalytics"
            title="페이지 뷰 분석"
            Component={ServicePageViewAnalytics}
          />
          <PrivateRoute
            exact
            path="/serviceAnalytics/sessionAnalytics"
            title="방문횟수(session) 분석"
            Component={ServiceSessionAnalytics}
          />
          <PrivateRoute
            exact
            path="/serviceAnalytics/environmentAnalysis"
            title="사용환경 분석"
            Component={ServiceEnvironmentAnalysis}
          />
          <PrivateRoute
            exact
            path="/pageAnalytics/uxAnalysisList"
            title="페이지 UX 분석"
            Component={PageUXAnalysisList}
          />
          <PrivateRoute
            exact
            path="/pageAnalytics/userFlowAnalysisList"
            title="사용 흐름 분석"
            Component={UserFlowAnalysisList}
          />
          <PrivateRoute
            exact
            path="/pageAnalytics/uxAnalysisList/uxAnalysis"
            title="UX 분석"
            Component={UXAnalysis}
          />
          <PrivateRoute
            exact
            path="/pageAnalytics/userFlowAnalysisList/userFlowAnalysis"
            title="사용자 흐름 분석"
            Component={PageUserFlowAnalysis}
          />

          <PrivateRoute
            exact
            path="/aivoryUsageAnalytics"
            title="AIVORY 이용 분석"
            Component={AIVORYDashboard}
          />
          <PrivateRoute
            exact
            path="/aivoryUsageAnalytics/searchUsageAnalysis"
            title="검색 이용 분석"
            Component={SearchUsageAnalysis}
          />
          <PrivateRoute
            exact
            path="/aivoryUsageAnalytics/popularSearchAnalysis"
            title="인기검색어 분석"
            Component={PopularSearchAnalysis}
          />
          <PrivateRoute
            exact
            path="/aivoryUsageAnalytics/contentKeywordAnalysis"
            title="콘텐츠 키워드 분석"
            Component={ContentKeywordAnalysis}
          />

          <PrivateRoute
            exact
            path="/myPage"
            title="마이페이지"
            Component={MyPage}
          />

          <PrivateRoute
            exact
            path="/report"
            title="월간 분석 리포트"
            Component={Report}
          />

          <PrivateRoute
            exact
            path="*"
            title="페이지 이용 분석"
            Component={ServiceDashboard}
          />

          <Route component={NotfoundPage} />
        </Switch>
      ) : (
        <Switch>
          <PublicRoute exact path="*" component={LoginPage} />
          <Route component={NotfoundPage} />
        </Switch>
      )}
    </>
  );
};

export default Routes;
