import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { ServiceActivationPayload } from '../../../../api/report/type';
import DefaultLink from '../../../Atoms/Link';
import { H3, P1, P3 } from '../../../Atoms/Typo';
import Header from '../../../Molecule/Card/CardHeader';
import Title from '../../../Molecule/Card/CardTitle/ReportTitle';
import Spinner from '../../../Molecule/Spinner';
import BarChartCard from '../BarChartCard';

const Component = styled.div`
  width: 100%;
  height: 531px;
  display: flex;
  border-radius: 8px;
  background-color: white;
`;

const InnerComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  height: 100%;
  padding: 24px 24px 40px 24px;
`;

const Split = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
`;

const ReportSummaryWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const ReportSummaryRaw = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
`;

const PlusRate = styled(H3)`
  color: ${(props) => props.theme.colors.success5};
  margin-right: 4px;
`;

const MinusRate = styled(H3)`
  color: ${(props) => props.theme.colors.danger5};
  margin-right: 4px;
`;

const LinkWrapper = styled.div`
  width: fit-content;

  a {
    ${P3} {
      color: ${(props) => props.theme.colors.grey500};
    }

    :hover {
      ${P3} {
        color: ${(props) => props.theme.colors.grey500};
      }
    }
  }
  margin-top: 24px;
`;

const Underline = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey500};
  margin-top: 4px;
`;

interface ServiceActivationReportCardProps {
  uvData: ServiceActivationPayload | null;
  pvData: ServiceActivationPayload | null;
  isLoading: boolean;
}

interface ServiceActivationReportCardState {
  rebuildData: {
    [key: string]: number | boolean;
    isHighlight: boolean;
  }[];
}

const ServiceActivationReportCard = ({
  uvData,
  pvData,
  isLoading,
}: ServiceActivationReportCardProps) => {
  const [totalUV, setTotalUV] = useState(0);
  const [totalPV, setTotalPV] = useState(0);
  const [uvReport, setUVReport] = useState('');
  const [pvReport, setPVReport] = useState('');
  const [currentMonth, setCurrentMonth] = useState('');
  const [rebuildUVData, setRebuildUVData] = useState<
    ServiceActivationReportCardState['rebuildData']
  >([]);
  const [rebuildPVData, setRebuildPVData] = useState<
    ServiceActivationReportCardState['rebuildData']
  >([]);
  const [mainUV, setMainUV] = useState(0);
  const [mainPV, setMainPV] = useState(0);
  const [compareRateUV, setCompareRateUV] = useState(0);
  const [compareRatePV, setCompareRatePV] = useState(0);

  const printRate = (rate: number) => {
    if (rate > 0) {
      return <PlusRate>{`(+ ${rate.toFixed(0)}%)`}</PlusRate>;
    }
    return <MinusRate>{`(- ${Math.abs(rate).toFixed(0)}%)`}</MinusRate>;
  };

  useEffect(() => {
    if (uvData) {
      setTotalUV(uvData.compare);
      const rebuildData: ServiceActivationReportCardState['rebuildData'] = [];
      const month_obj = uvData.month_count;
      const month_arr = Object.keys(month_obj);

      let most_uv_month = '';
      let max_uv = 0;

      setCurrentMonth(`${month_arr[2].split('-')[1]}월`);

      month_arr.forEach((element, index) => {
        if (max_uv < month_obj[element]) {
          max_uv = month_obj[element];
          most_uv_month = `${element.split('-')[1]}월`;
        }

        rebuildData.push({
          [`${element.split('-')[1]}월`]: month_obj[element],
          isHighlight: index === 2,
        });
      });

      setRebuildUVData(rebuildData);
      setMainUV(month_obj[month_arr[2]]);
      setCompareRateUV(
        ((month_obj[month_arr[2]] - month_obj[month_arr[1]]) /
          month_obj[month_arr[1]]) *
          100
      );

      if (
        month_obj[month_arr[0]] <= month_obj[month_arr[1]] &&
        month_obj[month_arr[1]] < month_obj[month_arr[2]]
      ) {
        setUVReport(
          '사용자수가 꾸준히 증가하고 있습니다.\n사이트 운영이 잘 되고 있습니다.'
        );
      } else if (
        month_obj[month_arr[2]] < month_obj[month_arr[1]] &&
        month_obj[month_arr[1]] <= month_obj[month_arr[0]]
      ) {
        setUVReport(
          `사용자수가 감소하고 있습니다.\n사용자 수가 가장 많았던 달은 ${most_uv_month} 입니다`
        );
      } else {
        setUVReport(
          `사용자가 꾸준히 방문하고 있습니다.\n사용자 수가 가장 많았던 달은 ${most_uv_month} 입니다`
        );
      }
    }
  }, [uvData]);

  useEffect(() => {
    if (pvData) {
      setTotalPV(pvData.compare);
      const rebuildData: ServiceActivationReportCardState['rebuildData'] = [];
      const month_obj = pvData.month_count;
      const month_arr = Object.keys(month_obj);

      let most_pv_month = '';
      let max_pv = 0;

      setCurrentMonth(`${month_arr[2].split('-')[1]}월`);

      month_arr.forEach((element, index) => {
        if (max_pv < month_obj[element]) {
          max_pv = month_obj[element];
          most_pv_month = `${element.split('-')[1]}월`;
        }

        rebuildData.push({
          [`${element.split('-')[1]}월`]: month_obj[element],
          isHighlight: index === 2,
        });
      });

      setRebuildPVData(rebuildData);
      setMainPV(month_obj[month_arr[2]]);
      setCompareRatePV(
        ((month_obj[month_arr[2]] - month_obj[month_arr[1]]) /
          month_obj[month_arr[1]]) *
          100
      );

      if (
        month_obj[month_arr[0]] <= month_obj[month_arr[1]] &&
        month_obj[month_arr[1]] < month_obj[month_arr[2]]
      ) {
        setPVReport(
          '페이지뷰 수가 꾸준히 증가하고 있습니다.\n사이트 운영이 잘 되고 있습니다.'
        );
      } else if (
        month_obj[month_arr[2]] < month_obj[month_arr[1]] &&
        month_obj[month_arr[1]] <= month_obj[month_arr[0]]
      ) {
        setPVReport(
          `페이지뷰 수가 감소하고 있습니다.\n페이지뷰 수가 가장 많았던 달은 ${most_pv_month} 입니다`
        );
      } else {
        setPVReport(
          `페이지뷰가 꾸준히 이용되고 있습니다.\n페이지뷰 수가 가장 많았던 달은 ${most_pv_month} 입니다`
        );
      }
    }
  }, [pvData]);

  return (
    <Component>
      <InnerComponent>
        <Header>
          <Title title="서비스가 얼마나 활성됐나요?" />
        </Header>
        {isLoading ? (
          <Spinner />
        ) : (
          <ReportSummaryWrapper>
            <P1>지난 달보다</P1>
            <ReportSummaryRaw>
              <H3>{Math.abs(totalUV)}명</H3>
              {printRate(compareRateUV)}
              {totalUV > 0 ? <P1>더 많이 방문하고</P1> : <P1>적게 방문하고</P1>}
            </ReportSummaryRaw>
            <ReportSummaryRaw>
              <H3>{Math.abs(totalPV)}개</H3>
              {printRate(compareRatePV)}
              {totalPV > 0 ? (
                <P1>더 많은 페이지를 이용했어요.</P1>
              ) : (
                <P1>적은 페이지를 이용했어요.</P1>
              )}
            </ReportSummaryRaw>

            <LinkWrapper>
              <DefaultLink to="/serviceAnalytics/userAnalytics">
                <P3>사용자 수 분석으로 이동</P3>
              </DefaultLink>
              <Underline />
            </LinkWrapper>
            <LinkWrapper>
              <DefaultLink to="/serviceAnalytics/pageViewAnalytics">
                <P3>페이지 뷰 수 분석으로 이동</P3>
              </DefaultLink>
              <Underline />
            </LinkWrapper>
          </ReportSummaryWrapper>
        )}
      </InnerComponent>
      <Split />
      <InnerComponent>
        {isLoading ? (
          <Spinner />
        ) : (
          <BarChartCard
            title={`${currentMonth} 사용자 수 합계`}
            value={mainUV}
            desc={uvReport}
            data={rebuildUVData}
            isLabelInChart={false}
          />
        )}
      </InnerComponent>
      <Split />
      <InnerComponent>
        {isLoading ? (
          <Spinner />
        ) : (
          <BarChartCard
            title={`${currentMonth} 페이지 뷰 수 합계`}
            value={mainPV}
            desc={pvReport}
            data={rebuildPVData}
            isLabelInChart={false}
          />
        )}
      </InnerComponent>
    </Component>
  );
};

export default ServiceActivationReportCard;
