import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../modules';
import config from '../../api/config-api.json';
import { SetLoginParamType } from '../../api/authentication/type';
import { setLoginAsync, SET_LOGIN } from '../../modules/authentication';
import {
  Component,
  IconLogoSignature,
  ImageSlideButton,
  ImageSlideButtonWrapper,
  LoginContainerGrid,
  LoginForm,
  LoginFormItem,
  LoginFormWrapper,
  LoginImageWrapper,
  LoginInput,
  LoginItemGrid,
  LoginVodaMainImage,
  LoginVodaMainImageAbsolute,
  LoginVodaTitleWrapper,
  LogoWrapper,
  LoginFormItemTitle,
  LoginButton,
  Split,
  ContactBox,
  ContactEmail,
  ContactPhone,
  ContactTitle,
  CopyRight,
  VodaHealthWrapper,
  VodaHealthStatus,
  LoginRightWrapper,
  VodaContactWrapper,
  IconCheckCircle,
  IconError,
  VodaVersionWrapper,
  SpinnerWrapper,
  VodaHealthProgressWrapper,
  VodaHealthText,
  VodaProgressBarBackgorund,
  VodaProgressBar,
  VodaProgressRate,
  BackgroundBlur,
  VodaContactModal,
  VodaContactModalBody,
  VodaContactBodySplit,
  VodaContactButtonWrapper,
  VodaContactButton,
  VodaContactButtonSplit,
  VodaRetryButton,
} from './styled';

import IllustAnalysisImage from '../../assets/images/illust-analysis@2x.png';
import IllustAnalysisImage2 from '../../assets/images/illust-analysis-2@2x.png';
import Spinner from '../../components/Molecule/Spinner';
import { H1, H5, P2, P3, P4 } from '../../components/Atoms/Typo';

const env = process.env.NODE_ENV;
let { apiInfo } = config;
if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

const LoginPage = () => {
  let isPause = false;
  const loading = useSelector((state: RootState) => state.loading);
  const dispatch = useDispatch();
  const onSetLogin = (info: SetLoginParamType) => {
    dispatch(setLoginAsync.request(info));
  };

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isVodaAvailable, setIsVodaAvailable] = useState<null | boolean>(null);
  const [progressValue, setPrpgressValue] = useState(0);
  const [interval, setIntervalFunc] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [imageHighlightId, setImageHighlightId] = useState<number | null>(null);

  const onClickLoginButton = () => {
    onSetLogin({
      username,
      password,
    });
  };

  const onChangeUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };
  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const intervalFunc = () => {
    if (!isPause) {
      setImageHighlightId((prev) => {
        if (prev === 0) {
          return 1;
        }
        if (prev === 1) {
          return 0;
        }
        if (prev === null) {
          return 1;
        }
        return 1;
      });
    }
  };

  const startIntervalSlider = () => {
    if (interval) {
      isPause = true;
      clearInterval(interval);
      setIntervalFunc(null);
      setTimeout(() => {
        isPause = false;
      }, 1000);
    }

    setIntervalFunc(setInterval(intervalFunc, 4000));
  };

  const onClickImageSlidebutton = (id: number) => {
    setImageHighlightId(id);
    startIntervalSlider();
  };

  const printStatus = () => {
    if (isVodaAvailable) {
      return (
        <VodaHealthStatus>
          <IconCheckCircle />
          <P3>VODA가 정상 작동중 입니다.</P3>
        </VodaHealthStatus>
      );
    }
    if (isVodaAvailable === false) {
      return (
        <>
          <VodaHealthStatus>
            <IconError />
            <P3>VODA에 접속할 수 없습니다. 관리자에게 문의하세요</P3>
          </VodaHealthStatus>
          <VodaContactWrapper>
            <P4>관리자 문의</P4>
            <P4>contact@nerdfactory.ai </P4>
            <P4> / 042-820-9718</P4>
          </VodaContactWrapper>
        </>
      );
    }
    return [];
  };

  const onChangeProgressValue = (value: number) => {
    if (value === 100) {
      setPrpgressValue(value);
    } else {
      setPrpgressValue(value);
    }
  };

  useEffect(() => {
    let resettimer: ReturnType<typeof setTimeout> | null = null;
    if (progressValue === 100) {
      resettimer = setTimeout(() => {
        setPrpgressValue(0);
      }, 1000);
    }

    return () => {
      if (resettimer) {
        clearTimeout(resettimer);
      }
      setPrpgressValue(0);
    };
  }, [progressValue]);

  // axios
  const defaultClient = axios.create({
    baseURL: apiInfo.api_url,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      Tenant: localStorage.getItem('voda_tenant'),
    },
    withCredentials: false,
  });

  defaultClient.defaults.timeout = 10000;

  const progressAxios = axios.create();
  progressAxios.defaults.timeout = 10000;

  let progress = 0; // 0 ~ 100, request percent
  let timerId: NodeJS.Timeout | null = null; // timer id

  function setProgress(value: number) {
    progress = value;
    onChangeProgressValue(value);
  }

  function timer() {
    if (progress < 98) {
      const diff = 100 - progress;
      const inc = diff / (10 + progress * (1 + progress / 100)); // 증가값
      setProgress(progress + inc);
    }
    timerId = setTimeout(timer, 50); // 50 ms 단위로 timer 재귀호출
  }

  defaultClient.interceptors.request.use(
    (configs) => {
      // 요청 인터셉터
      setProgress(0);
      timer();
      setIsVodaAvailable(null);
      return configs;
    },
    (error) => {
      setIsVodaAvailable(false);
      return Promise.reject(error);
    }
  );

  defaultClient.interceptors.response.use(
    (response) => {
      // 응답 인터셉터
      if (timerId) {
        clearTimeout(timerId); // HTTP 응답시 timer 해제
        timerId = null;
      }
      setProgress(100);
      setIsVodaAvailable(true);
      return response;
    },
    (error) => {
      setIsVodaAvailable(false);
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    defaultClient.get('/health/', {});
    // onGetVodaHealth();
    startIntervalSlider();
    return () => {
      if (interval) {
        clearInterval(interval);
      }
      setIntervalFunc(null);
      isPause = false;
    };
  }, []);

  return (
    <Component>
      <BackgroundBlur $isModalOpen={isVodaAvailable !== true} />
      <VodaHealthProgressWrapper $isShow={isVodaAvailable === null}>
        <VodaHealthText>
          <P3>VODA 서버 상태를 확인중입니다.</P3>
          <P3>잠시만 기다려주세요</P3>
        </VodaHealthText>
        <VodaProgressBarBackgorund>
          <VodaProgressBar $width={progressValue} />
        </VodaProgressBarBackgorund>
        <VodaProgressRate>{Math.floor(progressValue)}%</VodaProgressRate>
      </VodaHealthProgressWrapper>
      <VodaContactModal $isOpenModal={isVodaAvailable === false}>
        <VodaContactModalBody>
          <P3>VODA에 접속할 수 없습니다.</P3>
          <P3>장애가 지속될 시 관리자에게 문의하세요</P3>
        </VodaContactModalBody>
        <VodaContactBodySplit />
        <VodaContactButtonWrapper>
          <VodaRetryButton
            onClick={() => {
              defaultClient.get('/health/', {});
            }}
          >
            <P3>다시 시도하기</P3>
          </VodaRetryButton>
          <VodaContactButtonSplit />
          <VodaContactButton href="mailto:contact@nerdfactory.ai">
            <P3>문의 하기</P3>
          </VodaContactButton>
        </VodaContactButtonWrapper>
      </VodaContactModal>
      <LoginContainerGrid>
        <LoginItemGrid desktop={7}>
          <LoginImageWrapper>
            <LoginVodaTitleWrapper>
              <H1>VODA와 함께</H1>
              <H1>보다 쉽고 확실한 데이터 분석</H1>
            </LoginVodaTitleWrapper>
            <LoginVodaMainImage>
              <LoginVodaMainImageAbsolute $id={0} $isSelect={imageHighlightId}>
                <img src={IllustAnalysisImage} alt="" />
              </LoginVodaMainImageAbsolute>
              <LoginVodaMainImageAbsolute $id={1} $isSelect={imageHighlightId}>
                <img src={IllustAnalysisImage2} alt="" />
              </LoginVodaMainImageAbsolute>
            </LoginVodaMainImage>
            <ImageSlideButtonWrapper>
              <ImageSlideButton
                $isSelect={imageHighlightId === 0 || imageHighlightId === null}
                onClick={() => {
                  onClickImageSlidebutton(0);
                }}
              />
              <ImageSlideButton
                $isSelect={imageHighlightId === 1}
                onClick={() => {
                  onClickImageSlidebutton(1);
                }}
              />
            </ImageSlideButtonWrapper>
          </LoginImageWrapper>
        </LoginItemGrid>
        <LoginItemGrid desktop={5}>
          <LoginRightWrapper>
            <VodaHealthWrapper $isVodaAvailable={isVodaAvailable}>
              {printStatus()}
            </VodaHealthWrapper>
            <LoginFormWrapper>
              <LogoWrapper>
                <IconLogoSignature />
              </LogoWrapper>
              <LoginForm>
                <LoginFormItem>
                  <LoginFormItemTitle>
                    <H5>아이디</H5>
                  </LoginFormItemTitle>
                  <LoginInput
                    // name="username"
                    type="text"
                    placeholder="아이디를 입력하세요"
                    onChange={onChangeUser}
                    autoComplete="username"
                  />
                </LoginFormItem>
                <LoginFormItem>
                  <LoginFormItemTitle>
                    <H5>비밀번호</H5>
                  </LoginFormItemTitle>
                  <LoginInput
                    // name="password"
                    type="password"
                    placeholder="패스워드를 입력하세요"
                    onChange={onChangePassword}
                    autoComplete="current-password"
                  />
                </LoginFormItem>
                {loading[SET_LOGIN] ? (
                  <SpinnerWrapper>
                    <Spinner />
                  </SpinnerWrapper>
                ) : (
                  <LoginButton
                    type="submit"
                    onClick={(event) => {
                      event.preventDefault();
                      onClickLoginButton();
                    }}
                  >
                    <P2>로그인</P2>
                  </LoginButton>
                )}
              </LoginForm>

              <Split />
              <ContactBox>
                <ContactTitle>비밀번호 문의</ContactTitle>
                <ContactEmail>contact@nerdfactory.ai&nbsp;</ContactEmail>
                <ContactPhone>/ 042-820-9718</ContactPhone>
              </ContactBox>
              <CopyRight>Copyright&copy; Plani All Rights Reserved.</CopyRight>
            </LoginFormWrapper>
            <VodaVersionWrapper>
              <P4>AIVORY VODA v1.1</P4>
            </VodaVersionWrapper>
          </LoginRightWrapper>
        </LoginItemGrid>
      </LoginContainerGrid>
    </Component>
  );
};

export default LoginPage;
