import React from 'react';
import styled, { css } from 'styled-components';
import { H4, P3 } from '../../../Atoms/Typo';

interface TooltipWrapperProps {
  $top: number;
  $left: number;
  $isShow: boolean;
}
const TooltipWrapperVariable = css`
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.grey300};
  border-radius: 4px;
  padding: 8px 16px;
`;

const Component = styled.div<TooltipWrapperProps>`
  ${TooltipWrapperVariable}
  position: absolute;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  ${(props) => {
    if (props.$isShow) {
      return css`
        display: flex;
      `;
    }
    return css``;
  }}
  ${(props) => {
    if (props.$left && props.$top) {
      return css`
        top: ${props.$top + 5}px;
        left: ${props.$left + 5}px;
      `;
    }
    return css``;
  }}
`;

const TooltipItemVariable = css`
  ${P3};
  height: 20px;
  width: 100%;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0px;
  }
`;
const TooltipItem = styled.div`
  ${TooltipItemVariable}
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
`;

const TooltipItemCategoryName = styled.div`
  ${H4};
  font-size: 14px;
  color: ${(props) => props.theme.colors.grey600};
`;

const TooltipItemTitle = styled.div`
  color: ${(props) => props.theme.colors.grey500};
  margin-right: 4px;
`;

const TooltipItemCount = styled.div`
  color: ${(props) => props.theme.colors.grey700};
`;

const TooltipItemRate = styled.div`
  color: ${(props) => props.theme.colors.grey500};
  margin-left: 8px;
`;

interface TooltipProps {
  isShow: boolean;
  top: number;
  left: number;
  korName: string;
  total: number;
  old: number;
  newUser: number;
}

const Tooltip = ({
  isShow,
  top,
  left,
  korName,
  total,
  old,
  newUser,
}: TooltipProps) => {
  return (
    <Component $isShow={isShow} $top={top} $left={left}>
      <TooltipItem>
        <TooltipItemCategoryName>{korName}</TooltipItemCategoryName>
      </TooltipItem>
      <TooltipItem>
        <TooltipItemTitle>전체 사용자 수</TooltipItemTitle>
        <TooltipItemCount>{total}</TooltipItemCount>
      </TooltipItem>
      <TooltipItem>
        <TooltipItemTitle>재방문 사용자 수</TooltipItemTitle>
        <TooltipItemCount>{old}</TooltipItemCount>
        <TooltipItemRate>
          {total ? `(${Math.round((old / total) * 100)}%)` : '0%'}
        </TooltipItemRate>
      </TooltipItem>
      <TooltipItem>
        <TooltipItemTitle>신규 사용자 수</TooltipItemTitle>
        <TooltipItemCount>{newUser}</TooltipItemCount>
        <TooltipItemRate>
          {total ? `(${Math.round((newUser / total) * 100)}%)` : '0%'}
        </TooltipItemRate>
      </TooltipItem>
    </Component>
  );
};

export default Tooltip;
