import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { RootState } from '../../../modules';

import {
  GetPageViewCountParamType,
  GetRankPageViewCountParamType,
  GetWeeklyPageViewCountParamType,
} from '../../../api/serviceAnalytics/pageviewAnalysis/type';
import {
  getPageViewCountAsync,
  getRankPageViewCountAsync,
  getWeeklyPageViewCountAsync,
} from '../../../modules/serviceAnalytics/pageViewAnalysis';

import ServiceAnalysisTemplate from '../../../components/Templates/ServiceAnalysisTemplate';

const ServiceUserAnalytics = () => {
  // redux
  const {
    PVCData,
    WPVCData,
    RPVCData,
    isGetPVCLoading,
    isGetWPVCLoading,
    isGetRPVCLoading,
  } = useSelector((state: RootState) => state.servicePageViewAnalysis);

  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );

  const dispatch = useDispatch();
  const onGetPageViewCount = (info: GetPageViewCountParamType) => {
    dispatch(getPageViewCountAsync.request(info));
  };
  const onGetWeeklyPageViewCount = (info: GetWeeklyPageViewCountParamType) => {
    dispatch(getWeeklyPageViewCountAsync.request(info));
  };
  const onGetRankPageViewCount = (info: GetRankPageViewCountParamType) => {
    dispatch(getRankPageViewCountAsync.request(info));
  };

  // state
  const [selectedTimeSort, setSelectedTimeSort] = useState('day');
  const [selectedUserSort, setSelectedUserSort] = useState('all');

  useEffect(() => {
    if (!isDatePickerToggle) {
      onGetPageViewCount({
        unit: selectedTimeSort,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetWeeklyPageViewCount({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetRankPageViewCount({
        ordering: 'asc',
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
    }
  }, [isDatePickerToggle, startDate, endDate]);

  const onChangeFliter = () => {
    onGetPageViewCount({
      unit: selectedTimeSort,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    });
  };

  const total_label = '전체 페이지 뷰 수';
  const label1 = '재방문 사용자의 페이지 뷰 수';
  const label2 = '신규 사용자의 페이지 뷰 수';

  return (
    <ServiceAnalysisTemplate
      headerTitle="페이지 뷰 수 분석"
      multiChartData={{
        Data: PVCData || null,
        selectedTimeSort,
        selectedUserSort,
        isLoading: isGetPVCLoading,
        setSelectedTimeSort,
        setSelectedUserSort,
        onChangeFliter,
        lineLabel: total_label,
        bar1Label: label1,
        bar2Label: label2,
        legendData: [
          {
            title: total_label,
            status: 'all',
          },
          {
            title: label1,
            status: 'return',
          },
          {
            title: label2,
            status: 'new',
          },
          {
            title: `${total_label}(추정)`,
            status: 'all_future',
          },
          {
            title: `${label1}(추정)`,
            status: 'return_future',
          },
          {
            title: `${label2}(추정)`,
            status: 'new_future',
          },
        ],
      }}
      stackedColumnsChartData={{
        Data: WPVCData,
        loading: isGetWPVCLoading,
        bar1Label: label1,
        bar2Label: label2,
      }}
      heatmapChartData={{
        Data: WPVCData,
        loading: isGetWPVCLoading,
        totalLabel: total_label,
        label1,
        label2,
      }}
      PageViewTableData={{
        Data: RPVCData,
        loading: isGetRPVCLoading,
      }}
    />
  );
};

export default ServiceUserAnalytics;
