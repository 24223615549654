import React from 'react';
import {
  GetDashboardStatisticsResponseType,
  GetPVMeanStatistics,
} from '../../../api/serviceAnalytics/dashboard/type';
import ContentBodyLayout from '../../Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../Atoms/Layout/Contents/ContentsItemLayout';
import ContentsLayout from '../../Atoms/Layout/Contents/ContentsLayout';
import ButtonGroup from '../../Molecule/ButtonGroup';
import DashboardDefaultCard from '../../Organisms/Card/DashboardDefaultCard';
import DashboardLineCard from '../../Organisms/Card/DashboardLineCard';
import Header from '../../Organisms/Header';

interface DashboardTemplateProps {
  isGetUniqueVisitorLoading: boolean;
  isGetNewVisitorLoading: boolean;
  isGetPageViewLoading: boolean;
  isGetSessionLoading: boolean;
  isGetPVPerSession: boolean;
  isGetSessionPerUV: boolean;
  isGetSessionDurationPerSession: boolean;

  uniqueVisitorData: GetDashboardStatisticsResponseType['payload'] | null;
  newVisitorData: GetDashboardStatisticsResponseType['payload'] | null;
  pageViewData: GetDashboardStatisticsResponseType['payload'] | null;
  sessionData: GetDashboardStatisticsResponseType['payload'] | null;

  pvPerSessionData: GetPVMeanStatistics | null;
  sessionPerUVData: GetPVMeanStatistics | null;
  sessionDurationPerSession: GetPVMeanStatistics | null;

  activeTabID: number;
  activeDurationName: string;
  onClickButtonGroup: (id: number) => void;
}

const DashboardTemplate = ({
  isGetUniqueVisitorLoading,
  isGetNewVisitorLoading,
  isGetPageViewLoading,
  isGetSessionLoading,
  isGetPVPerSession,
  isGetSessionPerUV,
  isGetSessionDurationPerSession,

  uniqueVisitorData,
  newVisitorData,
  pageViewData,
  sessionData,

  pvPerSessionData,
  sessionPerUVData,
  sessionDurationPerSession,

  activeTabID,
  activeDurationName,
  onClickButtonGroup,
}: DashboardTemplateProps) => {
  return (
    <ContentsLayout>
      <Header title="서비스 이용 분석" isDatePicker={false}>
        <ButtonGroup
          buttonData={[
            {
              id: 0,
              title: '어제',
            },
            {
              id: 1,
              title: '지난주',
            },
            {
              id: 2,
              title: '지난달',
            },
          ]}
          activeID={activeTabID}
          onClick={onClickButtonGroup}
        />
      </Header>
      <ContentBodyLayout>
        <ContentsItemLayout desktop={3}>
          <DashboardDefaultCard
            titleEng="Unique Visitor"
            titleKor="전체 사용자 수"
            rate={uniqueVisitorData ? uniqueVisitorData.rate : undefined}
            value={uniqueVisitorData ? uniqueVisitorData.count : undefined}
            isLoading={isGetUniqueVisitorLoading}
            isTimeCount={false}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={3}>
          <DashboardDefaultCard
            titleEng="New Visitor"
            titleKor="신규 사용자 수"
            rate={newVisitorData ? newVisitorData.rate : undefined}
            value={newVisitorData ? newVisitorData.count : undefined}
            isLoading={isGetNewVisitorLoading}
            isTimeCount={false}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={3}>
          <DashboardDefaultCard
            titleEng="Page View"
            titleKor="페이지뷰 수"
            rate={pageViewData ? pageViewData.rate : undefined}
            value={pageViewData ? pageViewData.count : undefined}
            isLoading={isGetPageViewLoading}
            isTimeCount={false}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={3}>
          <DashboardDefaultCard
            titleEng="Session"
            titleKor="방문 횟수"
            rate={sessionData ? sessionData.rate : undefined}
            value={sessionData ? sessionData.count : undefined}
            isLoading={isGetSessionLoading}
            isTimeCount={false}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={4}>
          <DashboardLineCard
            titleEng="PV per Session"
            titleKor="1회 방문당 평균 페이지뷰 수"
            rate={
              pvPerSessionData && pvPerSessionData.rate
                ? pvPerSessionData.rate
                : undefined
            }
            value={pvPerSessionData ? pvPerSessionData.total : undefined}
            isLoading={isGetPVPerSession}
            data={pvPerSessionData ? pvPerSessionData.graph : undefined}
            lineChartName="페이지 뷰"
            option={activeDurationName}
            isTimeCount={false}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={4}>
          <DashboardLineCard
            titleEng="SESSION PER UV"
            titleKor="1인당 평균 방문 횟수"
            data={sessionPerUVData ? sessionPerUVData.graph : undefined}
            lineChartName="페이지 뷰"
            isTimeCount={false}
            isLoading={isGetSessionPerUV}
            option={activeDurationName}
            rate={
              sessionPerUVData && sessionPerUVData.rate
                ? sessionPerUVData.rate
                : undefined
            }
            value={sessionPerUVData ? sessionPerUVData.total : undefined}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={4}>
          <DashboardLineCard
            titleEng="Session Duration per Session"
            titleKor="1회 방문당 평균 서비스 이용 시간"
            rate={
              sessionDurationPerSession && sessionDurationPerSession.rate
                ? sessionDurationPerSession.rate
                : undefined
            }
            time={
              sessionDurationPerSession
                ? sessionDurationPerSession.total
                : undefined
            }
            isLoading={isGetSessionDurationPerSession}
            data={
              sessionDurationPerSession
                ? sessionDurationPerSession.graph
                : undefined
            }
            lineChartName="이용 시간"
            option={activeDurationName}
            isTimeCount
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default DashboardTemplate;
