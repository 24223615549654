import React from 'react';
import styled from 'styled-components';
import { H3 } from '../../../Atoms/Typo';

const Component = styled.div``;

interface DefaultCardHeadingProps {
  className?: string;
  title: string;
}

const DefaultCardHeading = ({ title, className }: DefaultCardHeadingProps) => {
  return (
    <Component className={className}>
      <H3>{title}</H3>
    </Component>
  );
};

export default DefaultCardHeading;
