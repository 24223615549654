import React from 'react';
import styled, { css } from 'styled-components';
import Dot from '../../../Atoms/Dot';
import { P3 } from '../../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  align-items: center;
`;

const LegendDot = styled(Dot)<{
  $status:
    | 'all'
    | 'return'
    | 'new'
    | 'all_future'
    | 'return_future'
    | 'new_future';
}>`
  ${(props) => {
    switch (props.$status) {
      case 'all':
        return css`
          background-color: ${props.theme.colors.purple4};
        `;
      case 'return':
        return css`
          background-color: ${props.theme.colors.green4};
        `;
      case 'new':
        return css`
          background-color: ${props.theme.colors.orange4};
        `;
      case 'all_future':
        return css`
          background-color: ${props.theme.colors.grey400};
        `;
      case 'return_future':
        return css`
          border: solid 1px ${props.theme.colors.green4};
          background-color: ${props.theme.colors.green1};
        `;
      case 'new_future':
        return css`
          border: solid 1px ${props.theme.colors.orange4};
          background-color: ${props.theme.colors.orange1};
        `;

      default:
        return css``;
    }
  }}
`;

const Title = styled(P3)`
  color: ${(props) => props.theme.colors.grey500};
  margin-left: 4px;
`;

interface LegendTitleProps {
  title?: string;
  status?:
    | 'all'
    | 'return'
    | 'new'
    | 'all_future'
    | 'return_future'
    | 'new_future';
  className?: string;
}

const LegendTitleAllUser = ({
  title,
  status = 'all',
  className,
}: LegendTitleProps) => {
  return (
    <Component className={className}>
      <LegendDot $status={status} />
      <Title>{title}</Title>
    </Component>
  );
};

export default LegendTitleAllUser;
