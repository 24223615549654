import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../modules';
import {
  getPVPerSessionAsync,
  getSessionDurationPerSessionAsync,
  getSessionPerUVAsync,
  getTotalNewUserStatisticsAsync,
  getTotalPageViewStatisticsAsync,
  getTotalSessionStatisticsAsync,
  getTotalUserStatisticsAsync,
  GET_PV_PER_SESSION,
  GET_SESSION_DURATION_PER_SESSION,
  GET_SESSION_PER_UV,
  GET_TOTAL_NEW_USER_STATISTICS,
  GET_TOTAL_PAGE_VIEW_STATISTICS,
  GET_TOTAL_SESSION_STATISTICS,
  GET_TOTAL_USER_STATISTICS,
} from '../../../modules/serviceAnalytics/dashboard';
import {
  GetDashboardStatisticsParamType,
  GetPVMeanStatisticsParamType,
  GetUsageMeanStatisticsParamType,
  GetUVMeanStatisticsParamType,
} from '../../../api/serviceAnalytics/dashboard/type';

// components
import DashboardTemplate from '../../../components/Templates/Dashboard';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

const ServiceDashboard = () => {
  // redux
  const loading = useSelector((state: RootState) => state.loading);

  const {
    TUSData,
    TNUSData,
    TPVSData,
    TSSData,

    SPUData,
    PPSData,
    SDPSData,
  } = useSelector((state: RootState) => state.serviceDashboard);
  const dispatch = useDispatch();

  const onGetTotalStatistics = (info: GetDashboardStatisticsParamType) => {
    dispatch(getTotalUserStatisticsAsync.request(info));
    dispatch(getTotalNewUserStatisticsAsync.request(info));
    dispatch(getTotalPageViewStatisticsAsync.request(info));
    dispatch(getTotalSessionStatisticsAsync.request(info));
  };

  const onGetPVPerSession = (info: GetPVMeanStatisticsParamType) => {
    dispatch(getPVPerSessionAsync.request(info));
  };
  const onGetSessionPerUV = (info: GetUVMeanStatisticsParamType) => {
    dispatch(getSessionPerUVAsync.request(info));
  };
  const onGetSessionDurationPerSession = (
    info: GetUsageMeanStatisticsParamType
  ) => {
    dispatch(getSessionDurationPerSessionAsync.request(info));
  };

  // state

  const [activeTabID, setActiveTabID] = useState(0);

  const [activeDurationName, setActiveDurationName] = useState('day');

  const onGetDataDashboard = (unit: string) => {
    const info = {
      unit,
    };
    onGetTotalStatistics(info);
    onGetPVPerSession(info);
    onGetSessionPerUV(info);
    onGetSessionDurationPerSession(info);
  };

  useEffect(() => {
    onGetDataDashboard('day');
  }, []);

  const onClickTab = (id: number) => {
    setActiveTabID(id);
    switch (id) {
      case 0:
        onGetDataDashboard('day');
        setActiveDurationName('day');
        break;
      case 1:
        onGetDataDashboard('week');
        setActiveDurationName('week');
        break;
      case 2:
        onGetDataDashboard('month');
        setActiveDurationName('month');
        break;
      default:
        onGetDataDashboard('day');
        setActiveDurationName('day');
        break;
    }
  };

  return (
    <Component>
      <DashboardTemplate
        isGetUniqueVisitorLoading={loading[GET_TOTAL_USER_STATISTICS]}
        isGetNewVisitorLoading={loading[GET_TOTAL_NEW_USER_STATISTICS]}
        isGetPageViewLoading={loading[GET_TOTAL_PAGE_VIEW_STATISTICS]}
        isGetSessionLoading={loading[GET_TOTAL_SESSION_STATISTICS]}
        isGetPVPerSession={loading[GET_PV_PER_SESSION]}
        isGetSessionPerUV={loading[GET_SESSION_PER_UV]}
        isGetSessionDurationPerSession={
          loading[GET_SESSION_DURATION_PER_SESSION]
        }
        uniqueVisitorData={TUSData}
        newVisitorData={TNUSData}
        pageViewData={TPVSData}
        sessionData={TSSData}
        pvPerSessionData={PPSData}
        sessionPerUVData={SPUData}
        sessionDurationPerSession={SDPSData}
        activeDurationName={activeDurationName}
        activeTabID={activeTabID}
        onClickButtonGroup={onClickTab}
      />
    </Component>
  );
};

export default ServiceDashboard;
