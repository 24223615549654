import React from 'react';
import styled, { css } from 'styled-components';
import { P2, P3 } from '../../Atoms/Typo';

const Component = styled.div<{
  $x: number;
  $y: number;
  $width: number;
  $height: number;
  $rank: number;
  $size: number;
  $isClicked: boolean;
}>`
  ${P2} {
    color: ${(props) => props.theme.colors.grey900};
  }

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  left: 0;
  top: 0;
  cursor: pointer;
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.grey300};

  ${(props) => {
    if (props.$isClicked) {
      return css`
        border: solid 1px ${props.theme.colors.warning5};
        background-color: ${props.theme.colors.warning5};
        ${P2} {
          color: white;
        }
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (
      props.$width !== null &&
      props.$height !== null &&
      props.$x !== null &&
      props.$y !== null &&
      props.$rank !== null
    ) {
      return css`
        left: ${props.$x + props.$width / 2}px;
        top: ${props.$y + props.$height / 2}px;
        transform: translate(-50%, -50%);
        width: ${props.$size}px;
        height: ${props.$size}px;
        border-radius: ${props.$size / 2}px;
        z-index: ${100 - props.$rank};

        :hover {
          ${P3} {
            color: white;
          }

          border: solid 1px ${props.theme.colors.warning5};
          background-color: ${props.theme.colors.warning5};
        }
      `;
    }
    return css``;
  }}
`;

interface MouseFlowCircleProps {
  x: number;
  y: number;
  width: number;
  height: number;
  rank: number;
  size: number;
  id: string;
  isClicked: boolean;
  circle_ref?: React.RefObject<HTMLDivElement>;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const MouseFlowCircle = ({
  x,
  y,
  width,
  height,
  rank,
  size,
  id,
  isClicked,
  circle_ref,
  children,
  onClick,
}: MouseFlowCircleProps) => {
  return (
    <Component
      id={id}
      $x={x}
      $y={y}
      $width={width}
      $height={height}
      $rank={rank}
      $size={size}
      $isClicked={isClicked}
      ref={circle_ref}
      onClick={onClick}
    >
      <P2>{rank}</P2>

      {children}
    </Component>
  );
};

export default MouseFlowCircle;
