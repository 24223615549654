import React from 'react';
import styled, { css } from 'styled-components';

import { H6, P4 } from '../../Atoms/Typo';

const Component = styled.div<{
  $isTooltipOverHalf: boolean;
}>`
  width: 211px;
  padding: 16px;
  right: 24px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  border-radius: 8px;
  z-index: 150;

  &:after {
    position: absolute;
    content: '';

    ${(props) => {
      if (props.$isTooltipOverHalf) {
        return css`
          bottom: 0px;
          left: 12px;
          transform: translate(0%, 100%);
          border-top: 8px solid white;
          border-bottom: 8px solid transparent;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
        `;
      }
      return css`
        top: 0px;
        left: 12px;
        transform: translate(0%, -100%);
        border-bottom: 8px solid white;
        border-top: 8px solid transparent;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
      `;
    }}
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;

  ${P4} {
    color: ${(props) => props.theme.colors.grey600};
  }
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export interface ScrollHeatmapTooltipType {
  data: {
    id: number;
    title: string;
    value: string;
  }[];
}

export interface ScrollHeatmapTooltipProps {
  data: {
    id: number;
    title: string;
    value: string;
  }[];
  isTooltipOverHalf: boolean;
  tooltip_ref?: React.RefObject<HTMLDivElement>;
}

const ScrollHeatmapTooltip = ({
  data,
  isTooltipOverHalf,
  tooltip_ref,
}: ScrollHeatmapTooltipProps) => {
  return (
    <Component $isTooltipOverHalf={isTooltipOverHalf} ref={tooltip_ref}>
      {data.map((element) => {
        return (
          <Item key={element.title}>
            <P4>{element.title}</P4>
            <H6>{element.value}</H6>
          </Item>
        );
      })}
    </Component>
  );
};

export default ScrollHeatmapTooltip;
