import React from 'react';
import styled from 'styled-components';
import { P4 } from '../../Atoms/Typo';

const Component = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(0%, -50%);
  padding: 4px 8px 4px 8px;
  height: 24px;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px ${(props) => props.theme.colors.warning5};
  border-radius: 12px;
  z-index: auto;

  ${P4} {
    color: ${(props) => props.theme.colors.warning5};
  }
`;

interface PlusButtonProps {
  extLength: number;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const PlusButton = ({ extLength, children, onClick }: PlusButtonProps) => {
  return (
    <Component onClick={onClick}>
      <P4>{extLength}+</P4>
      {children}
    </Component>
  );
};

export default PlusButton;
