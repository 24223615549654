import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const NotfoundPage = () => {
  return (
    <Grid className="notfoundArea">
      <h2>404</h2>

      <div>
        <Button
          className="btn bg-default btn-radius"
          component={Link}
          to="/Analytics/PageList"
        >
          back To Home
        </Button>
      </div>
    </Grid>
  );
};

export default NotfoundPage;
