import React from 'react';
import styled from 'styled-components';

const Component = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 16px;
`;

const LeftWrapper = styled.div`
  width: 730px;
`;
const RightWrapper = styled.div`
  flex: 1;
`;
const Split = styled.div`
  width: 1px;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  div {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.grey300};
  }
`;

interface GeoMapTreeMapCardProps {
  LocalMapChartCard: React.ReactNode;
  TreeMapChartCard: React.ReactNode;
}

const GeoMapTreeMapCard = ({
  LocalMapChartCard,
  TreeMapChartCard,
}: GeoMapTreeMapCardProps) => {
  return (
    <Component>
      <LeftWrapper>{LocalMapChartCard}</LeftWrapper>
      <Split>
        <div />
      </Split>
      <RightWrapper>{TreeMapChartCard}</RightWrapper>
    </Component>
  );
};

export default GeoMapTreeMapCard;
