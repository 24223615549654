import React, { ReactNodeArray, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Chart from 'react-apexcharts';
import { H2, H4, P2, P3 } from '../../../Atoms/Typo';
import DataError from '../../../Atoms/DataError';

const Component = styled.div<{ $id: number | null }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 240px;

  .apexcharts-pie-series {
    filter: none;
    ${(props) => {
      if (props.$id === null) {
        return css``;
      }
      if (props.$id === 0) {
        return css`
          &:nth-child(1) {
            path {
              filter: none;
              fill: ${props.theme.colors.primary};
            }
          }
        `;
      }
      if (props.$id === 1) {
        return css`
          &:nth-child(2) {
            path {
              filter: none;
              fill: ${props.theme.colors.orange5};
            }
          }
        `;
      }
      if (props.$id === 2) {
        return css`
          &:nth-child(3) {
            path {
              filter: none;
              fill: ${props.theme.colors.green5};
            }
          }
        `;
      }
      return css``;
    }}
  }
`;

const ChartDataLabelWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ChartDataLabelTitle = styled.div`
  ${P3} {
    color: ${(props) => props.theme.colors.grey700};
  } ;
`;

const ChartDataLabelRate = styled.div<{ $id: number | null }>`
  ${H2} {
    font-size: 32px;

    ${(props) => {
      if (props.$id === null) {
        return css`
          color: ${props.theme.colors.purple3};
        `;
      }
      if (props.$id === 0) {
        return css`
          color: ${props.theme.colors.primary};
        `;
      }
      if (props.$id === 1) {
        return css`
          color: ${props.theme.colors.orange5};
        `;
      }
      if (props.$id === 2) {
        return css`
          color: ${props.theme.colors.green5};
        `;
      }
      return css``;
    }}
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Item = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0px;
  }
  display: flex;
  align-items: center;
  width: 100%;
`;

const ItemDot = styled.div<{ $id: number }>`
  width: 16px;
  height: 16px;

  margin-right: 16px;
  ${(props) => {
    switch (props.$id) {
      case 0:
        return css`
          background-color: ${props.theme.colors.purple4};
        `;

      case 1:
        return css`
          background-color: ${props.theme.colors.orange4};
        `;

      case 2:
        return css`
          background-color: ${props.theme.colors.green4};
        `;

      default:
        return css``;
    }
  }}
`;

const ItemTitle = styled.div`
  ${P2} {
    color: ${(props) => props.theme.colors.grey700};
  } ;
`;

const ItemValue = styled.div`
  ${H4} {
    color: ${(props) => props.theme.colors.grey700};
  }

  margin-left: auto;
`;

interface DounutChartProps {
  data: {
    [key: string]: string | number;
    cnt: number;
    rate: number;
  }[];
}

const DounutChart = ({ data }: DounutChartProps) => {
  const [seriesState, setSeries] = useState<number[]>([]);
  const [mouseOverKey, setMouseOverKey] = useState<number | null>(null);

  const DonutChartOnMouseEnter = (e: any, chartContext: any, config: any) => {
    if (config.dataPointIndex !== -1) {
      const index = config.dataPointIndex;
      setMouseOverKey(index);
    } else {
      setMouseOverKey(null);
    }
  };

  const DonutChartOnMouseLeave = (e: any, chartContext: any, config: any) => {
    setMouseOverKey(null);
  };

  const options = {
    chart: {
      type: 'donut' as const,
      events: {
        dataPointMouseEnter: DonutChartOnMouseEnter,
        dataPointMouseLeave: DonutChartOnMouseLeave,
      },
    },
    colors: ['#b597f7', '#ffba64', '#ced535'],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,

        donut: {
          size: '83.4%',
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  const printChart = useMemo(() => {
    if (seriesState.length > 0) {
      return (
        <Chart
          type="donut"
          series={seriesState}
          options={options}
          width="100%"
          height="100%"
        />
      );
    }
    return <DataError />;
  }, [seriesState]);

  const printDataLabelMemo = useMemo(() => {
    if (mouseOverKey !== null && data.length > 0) {
      const keyArr = Object.keys(data[mouseOverKey]);
      const key = keyArr[0];

      return (
        <ChartDataLabelWrapper>
          <ChartDataLabelTitle>
            <P3>{data[mouseOverKey][key]}</P3>
          </ChartDataLabelTitle>
          <ChartDataLabelRate $id={mouseOverKey}>
            <H2>{data[mouseOverKey].rate}%</H2>
          </ChartDataLabelRate>
        </ChartDataLabelWrapper>
      );
    }
    if (data.length > 0) {
      const keyArr = Object.keys(data[0]);
      const key = keyArr[0];
      return (
        <ChartDataLabelWrapper>
          <ChartDataLabelTitle>
            <P3>{data[0][key]}</P3>
          </ChartDataLabelTitle>
          <ChartDataLabelRate $id={mouseOverKey}>
            <H2>{data[0].rate}%</H2>
          </ChartDataLabelRate>
        </ChartDataLabelWrapper>
      );
    }
    return [];
  }, [mouseOverKey, data]);

  const printDataTable = useMemo(() => {
    const result: ReactNodeArray = [];
    if (data.length > 0) {
      data.some((element, index) => {
        if (index > 2) {
          return true;
        }
        const keyArr = Object.keys(element);
        const key = keyArr[0];

        result.push(
          <Item key={element[key]}>
            <ItemDot $id={index} />
            <ItemTitle>
              <P2>{element[key]}</P2>
            </ItemTitle>
            <ItemValue>
              <H4>{element.rate}%</H4>
            </ItemValue>
          </Item>
        );
        return false;
      });
    }
    return result;
  }, [data]);

  useEffect(() => {
    if (data.length > 0) {
      const seriesTemp: number[] = [];
      data.some((element, index) => {
        if (index > 2) {
          return true;
        }
        seriesTemp.push(element.rate);
        return false;
      });
      setSeries(seriesTemp);
    }
  }, [data]);

  return (
    <>
      <Component $id={mouseOverKey}>
        {printChart}
        {printDataLabelMemo}
      </Component>
      <ItemWrapper>{printDataTable}</ItemWrapper>
    </>
  );
};

export default DounutChart;
