import React from 'react';
import { RankPageViewCount } from '../../../api/serviceAnalytics/pageviewAnalysis/type';

import { WeeklyUserCountPayload } from '../../../api/serviceAnalytics/userAnalysis/type';
import ContentBodyLayout from '../../Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../Atoms/Layout/Contents/ContentsItemLayout';
import ContentsLayout from '../../Atoms/Layout/Contents/ContentsLayout';

import HeatmapChartCard from '../../Organisms/Card/HeatmapChartCard';
import MultiLineChartCard from '../../Organisms/Card/MultiLineChartCard';
import PageViewTableCard from '../../Organisms/Card/PageViewTableCard';
import StackedColumnsChartCard from '../../Organisms/Card/StackedColumnsChartCard';
import Header from '../../Organisms/Header';

interface ServiceAnalysisTemplateProps {
  headerTitle: string;
  multiChartData: {
    legendData: {
      title: string;
      status:
        | 'all'
        | 'return'
        | 'new'
        | 'all_future'
        | 'return_future'
        | 'new_future';
    }[];
    Data: {
      [key: string]: {
        total: number;
        old: number;
        new: number;
        is_futrue?: boolean;
      };
    } | null;
    isLoading: boolean;
    selectedTimeSort: string;
    selectedUserSort: string;
    lineLabel: string;
    bar1Label?: string;
    bar2Label?: string;

    setSelectedTimeSort: React.Dispatch<React.SetStateAction<string>>;
    setSelectedUserSort: React.Dispatch<React.SetStateAction<string>>;
    onChangeFliter: () => void;
  };
  stackedColumnsChartData: {
    Data: WeeklyUserCountPayload | null;
    bar1Label: string;
    bar2Label: string;
    loading: boolean;
  };
  heatmapChartData: {
    Data: WeeklyUserCountPayload | null;
    label1: string;
    label2: string;
    totalLabel: string;
    loading: boolean;
  };
  PageViewTableData?: {
    Data: RankPageViewCount[] | null;
    loading: boolean;
  };
}

const ServiceAnalysisTemplate = ({
  headerTitle,
  multiChartData,
  stackedColumnsChartData,
  heatmapChartData,
  PageViewTableData,
}: ServiceAnalysisTemplateProps) => {
  return (
    <ContentsLayout>
      <Header title={headerTitle} isDatePicker />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <MultiLineChartCard {...multiChartData} />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={3}>
          <StackedColumnsChartCard {...stackedColumnsChartData} />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={9}>
          <HeatmapChartCard {...heatmapChartData} />
        </ContentsItemLayout>
        {PageViewTableData ? (
          <ContentsItemLayout desktop={12}>
            <PageViewTableCard {...PageViewTableData} />
          </ContentsItemLayout>
        ) : null}
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default ServiceAnalysisTemplate;
