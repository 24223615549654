import React, { memo } from 'react';
import styled from 'styled-components';
import { ScrollEventsPayload } from '../../../../api/pageAnalytics/uxAnalytics/scroll/type';

const Component = styled.svg`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
`;

interface UXHeatmapLineChartProps {
  data: ScrollEventsPayload | null;
  barHeight: number;
  gradientWidth: number;
}

const UXHeatmapLineChart = ({
  data,
  barHeight,
  gradientWidth,
}: UXHeatmapLineChartProps) => {
  const genLine = () => {
    let d = 'M1,0';
    const max = 0.8;
    let maxTime = 0;
    let sumHeight = 0;
    if (data) {
      data.scroll_usability.forEach((element) => {
        maxTime = Math.max(element.avg_attention_time, maxTime);
      });
      data.scroll_usability.forEach((element, index) => {
        const timeRate = (element.avg_attention_time / maxTime) * 100;
        let realWidth = (gradientWidth * (timeRate * max)) / 100;

        if (realWidth === 0) {
          realWidth = 1;
        }

        d += `L${realWidth},${barHeight / 2 + sumHeight} `;

        if (data.scroll_usability.length - 1 === index) {
          d += `L1,${sumHeight + barHeight} `;
        }

        sumHeight += barHeight;
      });
    }
    return d;
  };

  return (
    <Component>
      <path d={genLine()} stroke="white" strokeWidth="2" fill="none" />
    </Component>
  );
};

export default memo(UXHeatmapLineChart);
