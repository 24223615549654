import React from 'react';
import styled from 'styled-components';
import { IconTime } from '../../Atoms/Icon';
import { P4 } from '../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  align-items: center;

  div {
    ${P4} {
      line-height: 1.2;
      color: ${(props) => props.theme.colors.grey300};
    }
  }
  svg {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    path {
      fill: ${(props) => props.theme.colors.grey300};
    }
  }
`;

interface TimerProps {
  time: string;
}

const Timer = ({ time }: TimerProps) => {
  return (
    <Component className="voda_timer">
      <IconTime />
      <div>
        <P4>{time}</P4>
      </div>
      <div>
        <P4>초 후 로그인 만료</P4>
      </div>
    </Component>
  );
};

export default Timer;
