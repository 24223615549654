import React, { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';
import { PageListPayload } from '../../../api/pageAnalytics/pageList/type';
import DataError from '../../Atoms/DataError';
import ContentBodyLayout from '../../Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../Atoms/Layout/Contents/ContentsItemLayout';
import ContentsLayout from '../../Atoms/Layout/Contents/ContentsLayout';
import DefaultPagenation from '../../Molecule/Pagenation';
import SortButton from '../../Molecule/SortButton';
import Spinner from '../../Molecule/Spinner';
import PageThumbnailCard from '../../Organisms/Card/PageThumbnailCard';
import Header from '../../Organisms/Header';

const PaginationWrapper = styled.div`
  width: 100%;
  margin-top: 80px;
`;

interface PageListTemplateProps {
  headerTitle: string;
  pageListData: PageListPayload[];
  isLoading: boolean;
  isPageUXAnalysis: boolean;
}

const PageListTemplate = ({
  headerTitle,
  isLoading,
  pageListData,
  isPageUXAnalysis,
}: PageListTemplateProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [lastPageNumber, setLastPageNumber] = useState(1);
  const [rebuildData, setRebuildData] = useState<PageListPayload[]>([]);
  const [isAsc, setIsAsc] = useState(false);

  const printPageList = useMemo(() => {
    const result: React.ReactNodeArray = [];
    const ux_analysis_list_url = '/uxAnalysisList/uxAnalysis';
    const user_flow_analysis_list = '/userFlowAnalysisList/userFlowAnalysis';

    if (rebuildData.length > 0) {
      for (
        let index = (pageNumber - 1) * 9;
        index <= pageNumber * 9 - 1;
        index += 1
      ) {
        if (index > rebuildData.length - 1) {
          break;
        }
        const element = rebuildData[index];

        result.push(
          <ContentsItemLayout desktop={4} key={element.page_id + index}>
            <PageThumbnailCard
              page_id={element.page_id}
              simple_url={element.simple_url}
              page_name={element.page_name}
              to={`/pageAnalytics${
                isPageUXAnalysis
                  ? ux_analysis_list_url
                  : user_flow_analysis_list
              }?pageid=${element.page_id}&pagelocation=${
                element.simple_url
              }&pagename=${element.page_name}`}
            />
          </ContentsItemLayout>
        );
      }
    } else {
      result.push(
        <ContentsItemLayout desktop={12} key="dataerror">
          <DataError />
        </ContentsItemLayout>
      );
    }
    return result;
  }, [rebuildData, pageNumber, isAsc]);

  const onChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
    window.scrollTo(0, 0);
  };

  const onClickSortButton = () => {
    if (!isAsc) {
      setRebuildData([...pageListData].reverse());
    } else {
      setRebuildData(pageListData);
    }
    setIsAsc((prev) => {
      return !prev;
    });
  };

  useEffect(() => {
    setLastPageNumber(Math.ceil(pageListData.length / 9));
    setRebuildData(pageListData);
  }, [pageListData]);

  return (
    <ContentsLayout>
      <Header title={headerTitle} isDatePicker={false}>
        <SortButton
          title={isAsc ? '페이지 뷰 낮은 순' : '페이지 뷰 높은 순'}
          isAsc={isAsc}
          onClick={onClickSortButton}
        />
      </Header>

      <ContentBodyLayout>
        {isLoading ? <Spinner /> : printPageList}
        <PaginationWrapper>
          <DefaultPagenation count={lastPageNumber} onChange={onChangePage} />
        </PaginationWrapper>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default PageListTemplate;
