import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PageElementsFlowPayload } from '../../../../api/pageAnalytics/uxAnalytics/mouseflow/type';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import Line from '../../../Atoms/Line';
import { H4, P3 } from '../../../Atoms/Typo';
import DefaultCardHeading from '../../../Molecule/Heading/Card/DefaultCardHeading';
import Table, { TableProps } from '../../../Molecule/Table';
import ElementThumbnailImage from '../../../Molecule/Thumbnail/ElementThumbnailImage';
import Spinner from '../../../Molecule/Spinner';

const Component = styled(CardLayout)``;

const Header = styled(CardHeaderLayout)`
  align-items: flex-start;
`;

const SubHeader = styled.div`
  margin-top: 8px;
  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
  }
`;

const ElementImage = styled(ElementThumbnailImage)`
  height: 40px;
  padding: 0px;
  border: none;
  justify-content: flex-start;
`;

interface MouseFlowRankCardProps {
  pageId: string;
  pageElementData: PageElementsFlowPayload[] | null;
  clickedElementPath: string;
  isLoading: boolean;
  setClickedElementPath: React.Dispatch<React.SetStateAction<string>>;
}

const MouseFlowRankCard = ({
  pageId,
  pageElementData,
  clickedElementPath,
  isLoading,
  setClickedElementPath,
}: MouseFlowRankCardProps) => {
  const [rebuildData, setRebuildData] = useState<TableProps['data']>([]);

  useEffect(() => {
    const data_temp: TableProps['data'] = [];
    if (pageElementData) {
      pageElementData.forEach((element, index) => {
        data_temp.push({
          id: `${index}`,
          rank: <H4>{element.rank}</H4>,
          contents: (
            <ElementImage
              img_src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
                'voda_tenant'
              )}/auto/${pageId}/${encodeURIComponent(
                element.element.element_path
              )}`}
              isBackground={false}
            />
          ),
          duration: <H4>{element.duration.toFixed(2)}초</H4>,
          onClick: () => {
            setClickedElementPath(`${element.rank}`);
          },
          isHighlignt: clickedElementPath === `${element.rank}`,
        });
      });
      setRebuildData(data_temp);
    }
  }, [pageElementData, clickedElementPath]);
  return (
    <Component>
      <Header>
        <DefaultCardHeading title="마우스 플로우" />
        {/* <ButtonWrapper>
          <SortButton title="내림 차순" isAsc={false} />
        </ButtonWrapper> */}
      </Header>
      <SubHeader>
        <P3>
          사용자의 마우스 커서 움직임을 추적해 사용자의 콘텐츠 관심 순위를
          파악합니다.
        </P3>
      </SubHeader>
      <Line isColumn />
      <CardBodyLayout>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table
            columns={[
              {
                title: '순서',
                id: 'rank',
                isSorting: true,
                isAsc: true,
              },
              {
                title: '콘텐츠',
                id: 'contents',
                isSorting: false,
              },
              {
                title: '평균 체류 시간',
                id: 'duration',
                isSorting: false,
              },
            ]}
            data={rebuildData}
          />
        )}
      </CardBodyLayout>
    </Component>
  );
};

export default MouseFlowRankCard;
