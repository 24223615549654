import produce, { Draft, Immutable } from 'immer';
import React, { ReactNodeArray } from 'react';
import styled, { css } from 'styled-components';

import { PageElementsFlowPayload } from '../../../api/pageAnalytics/uxAnalytics/mouseflow/type';
import { P2 } from '../../Atoms/Typo';
import { elementBoxSetType } from '../../Organisms/Card/UXAnalysis/MouseFlowCard';
import { MouseHeatmapTooltipType } from '../Tooltip/MouseHeatmapTooltip';

const Component = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  bottom: -11px;
  right: 0px;
  transform: translate(0%, 100%);
  min-height: 56px;

  border-radius: 8px;
  display: none;

  flex-direction: column;
  padding: 8px 8px 8px 8px;
  border: solid 1px ${(props) => props.theme.colors.warning5};
  background-color: white;

  ::after {
    position: absolute;
    transform: translate(0%, -100%);
    top: 1px;
    right: 8px;
    width: 0px;
    height: 0px;
    border-bottom: solid 7px white;
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;
    content: '';
  }
  ::before {
    position: absolute;
    transform: translate(0%, -100%);
    top: 0px;
    right: 8px;
    width: 0px;
    height: 0px;
    border-bottom: solid 7px ${(props) => props.theme.colors.warning5};
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;
    content: '';
  }
  ${(props) => {
    if (props.$isOpen) {
      return css`
        display: flex;
      `;
    }
    return css``;
  }}
`;

const ExtraElementWrapperInner = styled.div`
  display: flex;
  align-items: center;
`;

const ExtraElementItem = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px ${(props) => props.theme.colors.grey300};
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;

  background-color: white;

  margin: 4px;

  ${P2} {
    color: ${(props) => props.theme.colors.grey900};
  }
  :hover {
    ${P2} {
      color: white;
    }
    border: solid 1px ${(props) => props.theme.colors.warning5};
    background-color: #f5ab35;
  }
`;

interface ExtraCircleProps {
  isOpen: boolean;
  element: Immutable<{
    main: number;
    extra: PageElementsFlowPayload[];
  }>;
  index: number;
  pageId: string;
  imgSize: {
    width: number;
    height: number;
  };
  setElementBoxSet: React.Dispatch<React.SetStateAction<elementBoxSetType>>;
  setClickedPlusElementPath: React.Dispatch<React.SetStateAction<string>>;
  setClickTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;
  setClickedElementPath: React.Dispatch<React.SetStateAction<string>>;
}

const ExtraCircle = ({
  isOpen,
  element,
  index,
  pageId,
  imgSize,
  setElementBoxSet,
  setClickedPlusElementPath,
  setClickTooltipInfo,
  setClickedElementPath,
}: ExtraCircleProps) => {
  const onClickExtraItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    elements: {
      rate: number;
      session_cnt: number;
      click_cnt: number;
      duration: number;
      rank: number;
      element: {
        x: number;
        y: number;
        width: number;
        height: number;
        depth: number;
        element_path: string;
      };
    },
    indexs: number
  ) => {
    setClickedElementPath(`${elements.rank}`);
    setClickedPlusElementPath('');
  };

  const printExtraCircle = () => {
    const extraElementArr: ReactNodeArray = [];
    const extraElementInnerArr: ReactNodeArray = [];
    const extraElement = element.extra.filter(
      (extraEl) => extraEl.rank !== element.main
    );
    extraElement.forEach((extraElementItem, indexItem) => {
      const extraNode = (
        <ExtraElementItem
          id={`voda_mouseflow_ext_node${extraElementItem.rank}`}
          key={`voda_mouseflow_ext_node${extraElementItem.rank}`}
          onClick={(event) => {
            event.stopPropagation();
            onClickExtraItem(event, extraElementItem, index);
            // setIsMouseClickPlus(false);
          }}
        >
          {extraElementItem.rank}
        </ExtraElementItem>
      );
      extraElementArr.push(extraNode);
      if (indexItem !== 0 && indexItem % 4 === 0) {
        extraElementInnerArr.push(
          <ExtraElementWrapperInner key={extraElementItem.element.element_path}>
            {extraElementArr.map((exElementRow, indexRow) => {
              if (indexRow < indexItem && indexRow >= indexItem - 4) {
                return exElementRow;
              }
              return null;
            })}
          </ExtraElementWrapperInner>
        );
      }
    });

    if (extraElementArr.length % 5 !== 0) {
      extraElementInnerArr.push(
        <ExtraElementWrapperInner key="ExtraElementWrapperInner">
          {extraElementArr.map((exElementRow, indexRow) => {
            if (
              indexRow < extraElementArr.length &&
              indexRow >= extraElementArr.length - (extraElementArr.length % 4)
            ) {
              return exElementRow;
            }
            return null;
          })}
        </ExtraElementWrapperInner>
      );
    }

    return extraElementInnerArr;
  };
  return <Component $isOpen={isOpen}>{printExtraCircle()}</Component>;
};

export default ExtraCircle;
