import { css } from 'styled-components';
import { P3 } from '../Typo';

export const tooltip = css`
  .apexcharts-tooltip {
    border: none;
  }
  .tooltip {
    height: 36px;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background-color: ${(props) => props.theme.colors.grey900};
    display: flex;
    align-items: center;
    justify-content: center;

    .value {
      ${P3};
      color: white;
    }
  }
  .apexcharts-xaxistooltip {
    height: 36px;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    background-color: ${(props) => props.theme.colors.grey900};
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      ${P3};
      color: white;
    }

    ::before {
      border-color: transparent;
      border-bottom-color: transparent;
    }
    ::after {
      border-color: transparent;
      border-bottom-color: ${(props) => props.theme.colors.grey900};
      border-width: 5px;
      margin-left: -5px;
    }
  }
`;

export { tooltip as defaults };
