import React from 'react';
import styled from 'styled-components';
import DefaultLink from '../../Atoms/Link';
import { P4 } from '../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  align-items: center;

  a {
    ${P4} {
      color: ${(props) => props.theme.colors.purple3};
    }
  }
`;

const Split = styled.div`
  width: 1px;
  height: 14px;
  background-color: ${(props) => props.theme.colors.purple3};
  margin-right: 8px;
  margin-left: 8px;
`;

interface NavFooterLinkProps {
  onClickLogout: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}

const NavFooterLink = ({ onClickLogout }: NavFooterLinkProps) => {
  return (
    <Component className="voda_nav_footer_link">
      <DefaultLink to="/" onClick={onClickLogout}>
        <P4>로그아웃</P4>
      </DefaultLink>
      <Split />
      <DefaultLink to="/">
        <P4>이용약관</P4>
      </DefaultLink>
      <Split />
      <DefaultLink to="/">
        <P4>고객센터</P4>
      </DefaultLink>
    </Component>
  );
};

export default NavFooterLink;
