import { css } from 'styled-components';

const dot = css`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-left: 8px;
  margin-right: 8px;
`;

export const chart_tooltip = css`
  .apexcharts-tooltip {
    z-index: 999;
  }

  .tooltip {
    padding: 8px 16px;
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.colors.grey300};
    z-index: 999;
    .innerBox {
      display: flex;
      align-items: center;

      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0px;
      }
      .category-name {
        font-weight: 700;
        font-size: 16px;
        font-size: 14px;
        color: ${(props) => props.theme.colors.grey600};
      }

      .dot-all {
        ${dot};
        background-color: ${(props) => props.theme.colors.purple4};
      }
      .dot-all-future {
        background-color: ${(props) => props.theme.colors.grey400};
      }
      .dot-return {
        ${dot};
        background-color: ${(props) => props.theme.colors.green4};
      }
      .dot-return-future {
        border: solid 1px ${(props) => props.theme.colors.green4};
        background-color: ${(props) => props.theme.colors.green1};
      }
      .dot-new {
        ${dot};
        background-color: ${(props) => props.theme.colors.orange4};
      }
      .dot-new-future {
        border: solid 1px ${(props) => props.theme.colors.orange4};
        background-color: ${(props) => props.theme.colors.orange1};
      }

      .title {
        font-weight: 400;
        font-size: 14px;
        margin-right: 8px;
        color: ${(props) => props.theme.colors.grey500};
      }

      .value {
        font-weight: 400;
        font-size: 14px;
        margin-right: 4px;
        color: ${(props) => props.theme.colors.grey700};
      }
      .rate-return {
        font-weight: 400;
        font-size: 14px;
        color: ${(props) => props.theme.colors.green4};
      }
      .rate-new {
        font-weight: 400;
        font-size: 14px;
        color: ${(props) => props.theme.colors.orange4};
      }
    }
  }
`;

export { chart_tooltip as default };
