import React from 'react';
import styled from 'styled-components';
import { AIVORYSearchKeywordNetworkPayload } from '../../../api/aivoryUsageAnalytics/popularSearchAnalysis/type';
import Header from '../../Molecule/Card/CardHeader';
import Title from '../../Molecule/Card/CardTitle/ChartTitle';
import NetworkChart from '../../Molecule/Chart/NetworkChart';
import Spinner from '../../Molecule/Spinner';

const Component = styled.div`
  width: 100%;
  height: 100%;
  min-height: 585px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px 24px 40px 24px;
  border-radius: 16px;
`;

const Body = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 40px;
`;

export interface NetworkChartCardProps {
  propsData?: AIVORYSearchKeywordNetworkPayload;
  isLoading: boolean;
  onClickKeyword?: (word: string) => void;
}

const NetworkChartCard = ({
  propsData,
  isLoading,
  onClickKeyword,
}: NetworkChartCardProps) => {
  return (
    <Component>
      <Header>
        <Title title="검색어 네트워크" />
      </Header>
      <Body>
        {isLoading ? (
          <Spinner />
        ) : (
          <NetworkChart propsData={propsData} onClickKeyword={onClickKeyword} />
        )}
      </Body>
    </Component>
  );
};

export default NetworkChartCard;
