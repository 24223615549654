import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import ReactECharts from 'echarts-for-react';
import { AIVORYSearchKeywordNetworkPayload } from '../../../../api/aivoryUsageAnalytics/popularSearchAnalysis/type';
import DataError from '../../../Atoms/DataError';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface NetworkChartProps {
  propsData?: AIVORYSearchKeywordNetworkPayload;
  onClickKeyword?: (word: string) => void;
}

const NetworkChart = ({ propsData, onClickKeyword }: NetworkChartProps) => {
  let max = 0;
  const ChartEl = useRef<ReactECharts | null>(null);

  const tooltipFormat = (params: any, ticket: string) => {
    if (params.dataType === 'node') {
      const text = params.data.name;
      const cnt = params.data.value;
      let rate = 0;
      if (max !== 0) {
        rate = Math.round((cnt / max) * 100);
      }

      return `<div>
        ${text} ${cnt} (${rate}%)
      </div>`;
    }
    return '';
  };

  const options = {
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        name: '',
        type: 'graph',
        layout: 'force',
        data: [],
        links: [],

        roam: true,
        label: {
          show: true,
          position: 'bottom',
          color: '#757575',
          fontWeight: 400,
          fontSize: 14,
          fontFamily: 'Roboto, "Noto Sans KR", sans-serif',
        },
        itemStyle: {
          opacity: 1,
        },
        lineStyle: {
          curveness: 0,
          width: 1,
          opacity: 1,
        },
        force: {
          layoutAnimation: true,
          repulsion: 200,
          edgeLength: [10, 100],
          gravity: 0.1,
          friction: 0.1,
        },
        zoom: 2,
        tooltip: {
          formatter: tooltipFormat,
          backgroundColor: '#333333',
          borderWidth: 0,
          padding: [16, 24],
          textStyle: {
            color: '#ffffff',
            fontFamily: 'Roboto, "Noto Sans KR", sans-serif',
            fontWeight: 400,
            fontSize: 14,
          },
        },
      },
    ],
  };

  const onMouseoverChart = (params: any) => {
    const { current } = ChartEl;
    if (current) {
      const echartInstance = current.getEchartsInstance();
      const series = echartInstance.getOption().series as any;
      if (params.dataType === 'node' && series) {
        const series_data = series[0].data;
        const series_link = series[0].links;
        const connect_node: string[] = [];
        series_link.forEach((element: any) => {
          if (element.target === params.data.id) {
            connect_node.push(element.source);
          } else if (element.source === params.data.id) {
            connect_node.push(element.target);
          }
        });

        echartInstance.setOption({
          ...options,
          series: [
            {
              data: series_data.map((element: any, index: number) => {
                if (connect_node.includes(element.id)) {
                  return {
                    ...element,
                    itemStyle: {
                      color: '#7851e7',
                    },
                  };
                }

                if (element.id === params.data.id) {
                  return {
                    ...element,
                    itemStyle: {
                      color: '#7851e7',
                    },
                  };
                }
                return {
                  ...element,
                  itemStyle: {
                    color: '#e0e0e0',
                  },
                };
              }),
              links: series_link.map((element: any, index: number) => {
                if (
                  element.target === params.data.id ||
                  element.source === params.data.id
                ) {
                  return {
                    ...element,
                    lineStyle: {
                      color: '#7851e7',
                    },
                  };
                }

                return {
                  ...element,
                  lineStyle: {
                    color: '#e0e0e0',
                  },
                };
              }),
            },
          ],
        });
      }
    }
  };
  const onMouseoutChart = (params: any) => {
    const { current } = ChartEl;
    if (current) {
      const echartInstance = current.getEchartsInstance();
      const series = echartInstance.getOption().series as any;
      if (params.dataType === 'node' && series) {
        const series_data = series[0].data;
        const series_link = series[0].links;

        echartInstance.setOption({
          ...options,
          series: [
            {
              data: series_data.map((element: any, index: number) => {
                return {
                  ...element,
                  itemStyle: {
                    color: '#ab96ff',
                  },
                };
              }),
              links: series_link.map((element: any, index: number) => {
                return {
                  ...element,
                  lineStyle: {
                    color: '#e0e0e0',
                  },
                };
              }),
            },
          ],
        });
      }
    }
  };
  const onMouseclick = (params: any) => {
    if (onClickKeyword) {
      onClickKeyword(params.name);
    }
  };

  const onEvents = {
    mouseover: onMouseoverChart,
    mouseout: onMouseoutChart,
    click: onMouseclick,
  };

  const printChart = useMemo(() => {
    if (propsData && propsData.nodes.length > 0) {
      return (
        <ReactECharts
          ref={ChartEl}
          onEvents={onEvents}
          style={{ height: '100%', width: '100%' }}
          option={options}
        />
      );
    }

    return <DataError />;
  }, [propsData]);

  useEffect(() => {
    const { current } = ChartEl;
    if (current && propsData) {
      const echartInstance = current.getEchartsInstance();

      const node_color = '#ab96ff';
      const line_color = '#e0e0e0';
      let max_temp = 0;
      const convertNodeData: {
        value: number;
        symbolSize: number;
        name: string;
        id: string;
        itemStyle: {
          color: string;
        };
      }[] = [];
      const convertEdgeData: {
        value: number;
        target: string;
        source: string;
        lineStyle: {
          color: string;
        };
      }[] = [];
      propsData.nodes.forEach((element) => {
        max_temp += element.value;
        let symbolSize = element.value;
        if (symbolSize > 50) {
          symbolSize = 50;
        } else if (symbolSize < 10) {
          symbolSize = 10;
        }
        convertNodeData.push({
          value: element.value,
          symbolSize,
          name: element.name,
          id: element.id,
          itemStyle: {
            color: node_color,
          },
        });
      });
      propsData.edge.forEach((element) => {
        convertEdgeData.push({
          value: 1,
          target: element.target,
          source: element.source,
          lineStyle: {
            color: line_color,
          },
        });
      });

      max = max_temp;

      echartInstance.setOption({
        ...options,
        series: [
          {
            data: convertNodeData,
            links: convertEdgeData,
          },
        ],
      });

      const canvas = current.ele.getElementsByTagName('canvas').item(0);
      if (canvas) {
        canvas.addEventListener(
          'wheel',
          (event) => {
            event.preventDefault();
            let isZoomIn = true;
            const series = echartInstance.getOption().series as any;
            const zoomLevel = series[0].zoom;
            if (event.deltaY > 0) {
              isZoomIn = false;
            }

            if (zoomLevel < 1) {
              if (isZoomIn) {
                echartInstance.setOption({
                  series: [
                    {
                      roam: true,
                    },
                  ],
                });
              } else {
                echartInstance.setOption({
                  series: [
                    {
                      roam: 'move',
                    },
                  ],
                });
              }
            } else if (zoomLevel > 2.5) {
              if (isZoomIn) {
                echartInstance.setOption({
                  series: [
                    {
                      roam: 'move',
                    },
                  ],
                });
              } else {
                echartInstance.setOption({
                  series: [
                    {
                      roam: true,
                    },
                  ],
                });
              }
            }
          },
          true
        );
      }
    }
  }, [ChartEl.current, propsData]);

  return <Component>{printChart}</Component>;
};

export default NetworkChart;
