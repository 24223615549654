import React, { useEffect, useState, useRef } from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../modules';

import {
  // BreadcumbTitle,
  MainContainer,
  MainContentInner,
  MainContentRouter,
  MainContentRouterInner,
} from './styled';
import {
  getCheckSessionAsync,
  getIsAIVORYAvailableAsync,
  getPVUsageAsync,
  setLogoutAsync,
} from '../../modules/authentication';
import { setIsGNBFold } from '../../modules/sidebarNav';
import ErrorComponent from './ErrorComponent';
import Navigation from '../../components/Organisms/Navigation';

interface PrivateRouteProps extends RouteComponentProps {
  exact: boolean;
  path: string;
  title: string;
  Component: any;
}

const PrivateRoute = ({ exact, path, title, Component }: PrivateRouteProps) => {
  // redux
  const { subMenuLinkComponent, gnbIsFold } = useSelector(
    (state: RootState) => state.sidebarNav
  );
  const {
    isAIVORYAvailable,
    user_name,
    sessionValidTime,
    isSessionValid,
    pvUsageData,
  } = useSelector((state: RootState) => state.authentication);

  const dispatch = useDispatch();

  const onSetLogout = () => {
    dispatch(setLogoutAsync.request());
  };

  const onSetIsGNBFold = (isFold: boolean | null) => {
    dispatch(setIsGNBFold(isFold));
  };

  const onGetIsAIVORYAvailable = () => {
    dispatch(getIsAIVORYAvailableAsync.request());
  };

  const onGetPVUsage = () => {
    dispatch(getPVUsageAsync.request());
  };

  const onGetCheckSession = () => {
    dispatch(getCheckSessionAsync.request());
  };

  // state
  // const [colupsMenu, setColupsMenu] = useState(false);

  // const [screen, setScreen] = useState(false);
  // const [state, setState] = useState({
  //   collapsable_menu: false,
  //   top_menu: false,
  //   squared_card: false,
  //   block_shadow: false,
  // });
  const [isScroll, setIsScroll] = useState(false);
  const MainContentRef = useRef<HTMLDivElement | null>(null);
  const MainContentInnerEl = useRef<HTMLDivElement | null>(null);

  // const handleChange = (name: any) => (event: any) => {
  //   setState({ ...state, [name]: event.target.checked });
  // };
  // const colupsMenuHandler = () => {
  //   setColupsMenu(!colupsMenu);
  // };
  // const fullScreenHandler = () => {
  //   setScreen(!screen);
  // };

  useEffect(() => {
    onGetIsAIVORYAvailable();
    onGetPVUsage();
    if (localStorage.getItem('voda_tenant')) {
      setTimeout(() => {
        // onGetCheckSession();
      }, 5000);
    }
  }, []);

  useEffect(() => {
    const { current } = MainContentRef;
    if (current) {
      if (current.offsetHeight < current.scrollHeight) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    }
  }, [MainContentRef.current]);

  return (
    <MainContainer id="private_router">
      <ErrorComponent />

      <Navigation
        lisenceInfo={{
          pv: pvUsageData?.remain_pv,
          day: pvUsageData?.remain_date,
        }}
        userName={user_name}
        isAIVORYAvailable={isAIVORYAvailable}
        onSetLogout={onSetLogout}
        onGetCheckSession={onGetCheckSession}
      />
      <MainContentRouter $isFold={gnbIsFold}>
        <MainContentRouterInner id="main-content-inner" ref={MainContentRef}>
          <MainContentInner $isScroll={isScroll}>
            <Route exact path={path} render={() => <Component />} />
          </MainContentInner>
        </MainContentRouterInner>
      </MainContentRouter>
      <div id="gnb_submenu_tooltip">{subMenuLinkComponent}</div>
    </MainContainer>
  );
};

export default withRouter(connect(null, null)(PrivateRoute));
