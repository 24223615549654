import React, { useState } from 'react';

import styled from 'styled-components';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';

import LocalMapLegend from '../../Molecule/Chart/Legend/LocalMapLegend';
import LocalMapChart from '../../Molecule/Chart/LocalMapChart';
import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';
import Spinner from '../../Molecule/Spinner';

const Component = styled(CardLayout)``;

const Body = styled(CardBodyLayout)`
  position: relative;
  margin-top: 24px;
`;

export interface LocalMapChartCardProps {
  isLoading: boolean;
  data: {
    total: number;
    old: number;
    new: number;
    local_variant_en: string;
    local_variant_ko: string;
  }[];
}

const LocalMapChartCard = ({ isLoading, data }: LocalMapChartCardProps) => {
  const [max, setMax] = useState(0);
  return (
    <Component>
      <CardHeaderLayout>
        <DefaultCardHeading title="시도별 방문통계" />
      </CardHeaderLayout>
      <Body>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <LocalMapChart data={data} setMax={setMax} />
            <LocalMapLegend max={max} />
          </>
        )}
      </Body>
    </Component>
  );
};

export default LocalMapChartCard;
