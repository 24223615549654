import produce, { Immutable, Draft } from 'immer';
import { cloneDeep } from 'lodash';
import React, {
  useRef,
  useState,
  ReactNodeArray,
  useEffect,
  useMemo,
} from 'react';

import styled from 'styled-components';
import { PageElementsFlowPayload } from '../../../../api/pageAnalytics/uxAnalytics/mouseflow/type';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';

import FullPageScreenShot from '../../../Molecule/FullPageScreenShot';
import MouseFlowCircle from '../../../Molecule/MouseFlowCircle';
import ExtraCircle from '../../../Molecule/MouseFlowCircle/ExtraCircle';
import PlusButton from '../../../Molecule/MouseFlowCircle/PlusButton';
import MouseFlowLine from '../../../Molecule/MouseFlowLine';
import MouseHeatmapTooltip, {
  MouseHeatmapTooltipType,
} from '../../../Molecule/Tooltip/MouseHeatmapTooltip';
import Spinner from '../../../Molecule/Spinner';

const Component = styled(CardLayout)`
  padding: 0px;
  position: relative;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 200px;
  z-index: 20;
`;

export interface MouseFlowCardProps {
  pageId: string;
  pageElementData: PageElementsFlowPayload[] | null;
  clickTooltipInfo: MouseHeatmapTooltipType | null;
  mouseoverTooltipInfo: MouseHeatmapTooltipType | null;
  clickedElementPath: string;
  clickedPlusElementPath: string;
  isLoading: boolean;
  setClickTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;
  setMouseoverTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;
  setClickedElementPath: React.Dispatch<React.SetStateAction<string>>;
  setClickedPlusElementPath: React.Dispatch<React.SetStateAction<string>>;
}

interface MouseFlowCardState {
  eventCoordinates: {
    x: number;
    y: number;
    value: number;
    radius: number;
  }[];
  convertEventData: { [key: string]: { [key: string]: number } };
  elementBoxSet: {
    main: number;
    extra: PageElementsFlowPayload[];
  }[];
}

export type elementBoxSetType = Immutable<
  {
    main: number;
    extra: PageElementsFlowPayload[];
  }[]
>;

const MouseFlowCard = ({
  pageId,
  pageElementData,
  clickTooltipInfo,
  mouseoverTooltipInfo,
  clickedElementPath,
  clickedPlusElementPath,
  isLoading,
  setClickTooltipInfo,
  setMouseoverTooltipInfo,
  setClickedElementPath,
  setClickedPlusElementPath,
}: MouseFlowCardProps) => {
  const fullPageScreenShotEl = useRef<HTMLImageElement>(null);
  const componentEl = useRef<HTMLDivElement>(null);
  const [isImgLoad, setIsImgLoad] = useState(false);
  const [elementBoxData, setElementBoxData] = useState<
    MouseFlowCardProps['pageElementData'] | null
  >(null);
  const [elementBoxSet, setElementBoxSet] = useState<elementBoxSetType>([]);
  const [max, setMax] = useState(0);
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });

  const CalcOvelapCircle = () => {
    if (elementBoxData) {
      let maxTemp = 0;
      elementBoxData.forEach((element) => {
        maxTemp = Math.max(maxTemp, element.duration);
      });

      setMax(maxTemp);

      const overlapCircleData = cloneDeep(elementBoxData);
      const elementBoxSetTemp: MouseFlowCardState['elementBoxSet'] = [];

      overlapCircleData.forEach((element, index) => {
        let size = (element.duration / maxTemp) * 140;
        if (size < 40) {
          size = 40;
        }
        const r1 = size / 2;

        elementBoxSetTemp.push({
          main: element.rank,
          extra: [element],
        });

        overlapCircleData.forEach((element2) => {
          if (element.rank !== element2.rank) {
            let size2 = (element2.duration / maxTemp) * 140;
            if (size2 < 40) {
              size2 = 40;
            }
            const r2 = size2 / 2;
            const x1 = element.element.x + element.element.width / 2;
            const x2 = element2.element.x + element2.element.width / 2;
            const y1 = element.element.y + element.element.height / 2;
            const y2 = element2.element.y + element2.element.height / 2;

            const isOverap =
              Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2) <= Math.max(r1, r2);

            if (isOverap) {
              let isAlreadySet = false;
              elementBoxSetTemp.forEach((element3) => {
                element3.extra.some((element4) => {
                  if (element4.rank === element2.rank) {
                    isAlreadySet = true;
                    return true;
                  }
                  return false;
                });
              });

              if (!isAlreadySet) {
                elementBoxSetTemp[index].extra.push(element2);
              }
            }
          }
        });
      });

      const overOvelapCircleRank: number[] = [];
      elementBoxSetTemp.forEach((element) => {
        element.extra.forEach((elementExtra) => {
          if (element.main !== elementExtra.rank) {
            elementBoxSetTemp.forEach((element2) => {
              if (element2.main === elementExtra.rank) {
                overOvelapCircleRank.push(element2.main);
              }
            });
          }
        });
      });

      const result = elementBoxSetTemp.filter((element) => {
        let mainNode = true;

        overOvelapCircleRank.forEach((elementOverlapRank) => {
          if (elementOverlapRank === element.main) {
            mainNode = false;
          }
        });

        return mainNode;
      });

      setElementBoxSet(result);
    }
  };

  const calculateElementCoordinates = (imgWidth: number, imgHeight: number) => {
    const temp: MouseFlowCardProps['pageElementData'] = [];
    if (pageElementData && pageElementData.length > 0) {
      pageElementData.forEach((element) => {
        if (
          !(
            element.element.x < 0 ||
            element.element.y < 0 ||
            element.element.x > 1 ||
            element.element.y > 1 ||
            element.element.width > 1 ||
            element.element.height > 1 ||
            element.element.width < 0 ||
            element.element.height < 0 ||
            element.element.x + element.element.width > 1
          )
        ) {
          const x = element.element.x * imgWidth;
          const y = element.element.y * imgHeight;
          const width = element.element.width * imgWidth;
          const height = element.element.height * imgHeight;
          const pushdata = {
            click_cnt: element.click_cnt,
            rate: element.rate,
            rank: element.rank,
            duration: element.duration,
            session_cnt: element.session_cnt,
            element: {
              x,
              y,
              width,
              height,
              element_path: element.element.element_path,
              depth: element.element.depth,
            },
          };

          temp.push(pushdata);
        }
      });
    }
    if (temp.length > 0) {
      setElementBoxData(temp);
    }
  };

  const handleImageLoaded = () => {
    setIsImgLoad(true);
  };

  const handleImageLoadStart = () => {
    setIsImgLoad(false);
  };

  const GenerateElementBox = () => {
    const result: ReactNodeArray = [];

    if (elementBoxSet.length > 0) {
      elementBoxSet.forEach((element, index) => {
        const mainElement = element.extra.filter(
          (extraEl) => extraEl.rank === element.main
        );

        mainElement.forEach((extraMainElement) => {
          let size = (extraMainElement.duration / max) * 140;

          if (size < 40) {
            size = 40;
          }

          const node = (
            <MouseFlowCircle
              circle_ref={componentEl}
              isClicked={`${extraMainElement.rank}` === clickedElementPath}
              x={extraMainElement.element.x}
              y={extraMainElement.element.y}
              width={extraMainElement.element.width}
              height={extraMainElement.element.height}
              rank={extraMainElement.rank}
              size={size}
              id={`voda_mouseflow_node${extraMainElement.rank}`}
              key={`voda_mouseflow_node${extraMainElement.rank}`}
              onClick={() => {
                setClickedElementPath(`${extraMainElement.rank}`);
              }}
            >
              {element.extra.length > 1 ? (
                <PlusButton
                  extLength={element.extra.length - 1}
                  onClick={(event) => {
                    event.stopPropagation();
                    setClickedPlusElementPath(`${extraMainElement.rank}`);
                    setClickTooltipInfo(null);
                  }}
                >
                  <ExtraCircle
                    key={extraMainElement.element.element_path}
                    isOpen={
                      `${extraMainElement.rank}` === clickedPlusElementPath
                    }
                    element={element}
                    index={index}
                    pageId={pageId}
                    imgSize={imgSize}
                    setElementBoxSet={setElementBoxSet}
                    setClickedPlusElementPath={setClickedPlusElementPath}
                    setClickTooltipInfo={setClickTooltipInfo}
                    setClickedElementPath={setClickedElementPath}
                  />
                </PlusButton>
              ) : null}
            </MouseFlowCircle>
          );
          result.push(node);
        });
      });
    }

    return result;
  };

  const printElementBox = useMemo(() => {
    if (elementBoxSet.length > 0) {
      return GenerateElementBox();
    }
    return null;
  }, [
    elementBoxSet,
    clickedPlusElementPath,
    clickedElementPath,
    imgSize,
    clickTooltipInfo,
  ]);

  const printLine = useMemo(() => {
    if (elementBoxData && elementBoxData.length > 0) {
      return (
        <MouseFlowLine elementBoxData={elementBoxData} key="mouse_flow_line" />
      );
    }
    return null;
  }, [elementBoxData]);

  const printTooltip = useMemo(() => {
    if (mouseoverTooltipInfo) {
      return (
        <MouseHeatmapTooltip
          {...mouseoverTooltipInfo}
          imgSize={imgSize}
          key="mouse_over_tooltip"
        />
      );
    }
    if (clickTooltipInfo) {
      return (
        <MouseHeatmapTooltip
          {...clickTooltipInfo}
          imgSize={imgSize}
          key="mouse_click_tooltip"
        />
      );
    }
    return null;
  }, [mouseoverTooltipInfo, clickTooltipInfo]);

  useEffect(() => {
    let temp: PageElementsFlowPayload | undefined;

    elementBoxSet.some((element) => {
      temp = element.extra.find(
        (element2) => `${element2.rank}` === clickedElementPath
      );

      if (temp) {
        return true;
      }
      return false;
    });

    if (temp) {
      const targetCircle = document.getElementById(
        `voda_mouseflow_node${temp.rank}`
      );

      if (targetCircle) {
        const parent = targetCircle.parentElement;
        let parentLeft = 0;
        let parentTop = 0;
        const targetLeft = targetCircle.getBoundingClientRect().left;
        const targetTop = targetCircle.getBoundingClientRect().top;
        const targetWidth = targetCircle.getBoundingClientRect().width;
        const targetHeight = targetCircle.getBoundingClientRect().height;

        let targetRelativeLeft = targetLeft;
        let targetRelativeTop = targetTop;
        if (parent) {
          parentLeft = parent.getBoundingClientRect().left;
          targetRelativeLeft = targetLeft - parentLeft;
          parentTop = parent.getBoundingClientRect().top;
          targetRelativeTop = targetTop - parentTop;
        }

        setClickTooltipInfo({
          data: [
            {
              id: 0,
              title: '관심 순위',
              value: `${temp.rank}위`,
            },
            {
              id: 1,
              title: '평균 체류 시간',
              value: `${temp.duration.toFixed(2)}초`,
            },
            {
              id: 2,
              title: '관심 비율',
              value: `${temp.rate.toFixed(2)}%`,
            },
          ],
          img_src: `https://voda-media.nerdfactory.ai/${localStorage.getItem(
            'voda_tenant'
          )}/auto/${pageId}/${encodeURIComponent(temp.element.element_path)}`,
          isOpenTooltip: true,
          elementX: targetRelativeLeft / imgSize.width,
          elementY: targetRelativeTop / imgSize.height,
          elementHeight: targetHeight / imgSize.height,
          elementWidth: targetWidth / imgSize.width,
        });
      }
    }
  }, [elementBoxSet, clickedElementPath]);

  useEffect(() => {
    setElementBoxSet(
      produce((draft: Draft<elementBoxSetType>) => {
        draft.some((element, index) => {
          const data = element.extra.find(
            (element2) => `${element2.rank}` === clickedElementPath
          );
          if (data) {
            element.main = data.rank;
            return true;
          }
          return false;
        });
      })
    );
  }, [clickedElementPath]);

  useEffect(() => {
    if (elementBoxData && elementBoxData.length > 0) {
      CalcOvelapCircle();
    }
  }, [elementBoxData]);

  useEffect(() => {
    if (isImgLoad && fullPageScreenShotEl.current) {
      const width = fullPageScreenShotEl.current.offsetWidth;
      const height = fullPageScreenShotEl.current.offsetHeight;
      setImgSize({ width, height });
      calculateElementCoordinates(width, height);
    }
  }, [isImgLoad, fullPageScreenShotEl.current, pageElementData]);

  // useEffect(() => {
  //   console.log(componentEl, 'componentEl');
  // }, [componentEl.current]);

  return (
    <Component>
      <FullPageScreenShot
        img_src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
          'voda_tenant'
        )}/auto/${pageId}/${encodeURIComponent('page_screenshot')}`}
        imgRef={fullPageScreenShotEl}
        onLoad={handleImageLoaded}
        onLoadStart={handleImageLoadStart}
      />
      {isLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          {printElementBox}
          {printLine}
          {printTooltip}
        </>
      )}
    </Component>
  );
};

export default MouseFlowCard;
