import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { SimilarScaleTypePayload } from '../../../../api/report/type';
import Title from '../../../Molecule/Card/CardTitle/ReportTitle';
import Title3 from '../../../Molecule/Card/CardTitle/ReportTitle3';
import DounutChart from '../../../Molecule/Chart/DonutChart/Simple';
import Spinner from '../../../Molecule/Spinner';
import BarChartCard from '../BarChartCard';

const Component = styled.div`
  width: 100%;
  height: 464px;
  display: flex;
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  border-radius: 8px;
`;

const Split = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
`;

const InnerComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 24px 24px 40px 24px;
`;

const Body1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const Chart = styled.div`
  flex: 1;
  width: 100%;
`;

interface SimilarVSReportCardProps {
  title: string;
  type: string;
  scaleUVData?: {
    percent: number;
    count: number;
    top_10_per: number;
    top_90_per: number;
  } | null;
  uvData?: {
    percent: number;
    my_uv: number;
    top_10_per: number;
    top_90_per: number;
  } | null;
  pvData: {
    percent: number;
    my_pv: number;
    top_10_per: number;
    top_90_per: number;
  } | null;
  group?: { [key: string]: number } | null;
  group2?: SimilarScaleTypePayload | null;
  isLoading: boolean;
}

interface SimilarVSReportCardState {
  chartData: {
    group: string;
    rate: number;
  }[];
  rebuildData: {
    [key: string]: number | boolean;
    isHighlight: boolean;
  }[];
}

const SimilarVSReportCard = ({
  group,
  group2,
  title,
  type,
  uvData,
  pvData,
  scaleUVData,
  isLoading,
}: SimilarVSReportCardProps) => {
  const [chartData, setChartData] = useState<
    SimilarVSReportCardState['chartData']
  >([]);

  const [mainType, setMainType] = useState('');

  const [uvRate, setUVRate] = useState(0);
  const [pvRate, setPVRate] = useState(0);
  const [uvReport, setUVReport] = useState('');
  const [pvReport, setPVReport] = useState('');
  const [rebuildUVData, setRebuildUVData] = useState<
    SimilarVSReportCardState['rebuildData']
  >([]);
  const [rebuildPVData, setRebuildPVData] = useState<
    SimilarVSReportCardState['rebuildData']
  >([]);

  useEffect(() => {
    if (group2) {
      const key_arr = Object.keys(group2.scale);
      const chart_data: SimilarVSReportCardState['chartData'] = [];

      setMainType(group2.my_scale);

      chart_data.push({
        group: group2.my_scale,
        rate: group2.scale[group2.my_scale],
      });

      key_arr.forEach((element) => {
        if (element !== group2.my_scale) {
          chart_data.push({
            group: element,
            rate: group2.scale[element],
          });
        }
      });

      setChartData(chart_data);
    }
  }, [group2]);

  useEffect(() => {
    if (group) {
      const key_arr = Object.keys(group);
      const chart_data: SimilarVSReportCardState['chartData'] = [];

      setMainType(key_arr[0]);

      key_arr.forEach((element) => {
        chart_data.push({
          group: element,
          rate: group[element],
        });
      });

      setChartData(chart_data);
    }
  }, [group]);

  useEffect(() => {
    if (scaleUVData) {
      // setUVRate(uvData.percent);

      const rebuildData: SimilarVSReportCardState['rebuildData'] = [];

      rebuildData.push({
        '하위 10%': scaleUVData.top_90_per,
        isHighlight: false,
      });
      rebuildData.push({
        '내 웹 사이트': scaleUVData.count,
        isHighlight: true,
      });
      rebuildData.push({
        '상위 10%': scaleUVData.top_10_per,
        isHighlight: false,
      });

      if (scaleUVData.percent <= 30) {
        setUVReport(
          `${
            type === '기관 유형' ? '유사 기관 중' : '유사 규모 중'
          } 사용자가 많은 편입니다.\n웹 사이트 운영이 잘 되고 있습니다.`
        );
      } else if (scaleUVData.percent > 30 && scaleUVData.percent <= 70) {
        setUVReport(
          `${
            type === '기관 유형' ? '유사 기관 중' : '유사 규모 중'
          } 사용자 수가 평균 수준입니다.\n상위 10%의 웹사이트는 한달 간 ${
            scaleUVData.top_10_per
          } 명이 웹사이트를 이용합니다.`
        );
      } else {
        setUVReport(
          `${
            type === '기관 유형' ? '유사 기관 중' : '유사 규모 중'
          } 사용자가 적은 편입니다.\n상위 10%의 웹사이트는 한달 간 ${
            scaleUVData.top_10_per
          } 명이 웹사이트를 이용합니다.`
        );
      }

      setRebuildUVData(rebuildData);
    }
  }, [scaleUVData]);

  useEffect(() => {
    if (uvData) {
      setUVRate(uvData.percent);

      const rebuildData: SimilarVSReportCardState['rebuildData'] = [];

      rebuildData.push({
        '하위 10%': uvData.top_90_per,
        isHighlight: false,
      });
      rebuildData.push({
        '내 웹 사이트': uvData.my_uv,
        isHighlight: true,
      });
      rebuildData.push({
        '상위 10%': uvData.top_10_per,
        isHighlight: false,
      });

      if (uvData.percent <= 30) {
        setUVReport(
          `${
            type === '기관 유형' ? '유사 기관 중' : '유사 규모 중'
          } 사용자가 많은 편입니다.\n웹 사이트 운영이 잘 되고 있습니다.`
        );
      } else if (uvData.percent > 30 && uvData.percent <= 70) {
        setUVReport(
          `${
            type === '기관 유형' ? '유사 기관 중' : '유사 규모 중'
          } 사용자 수가 평균 수준입니다.\n상위 10%의 웹사이트는 한달 간 ${
            uvData.top_10_per
          } 명이 웹사이트를 이용합니다.`
        );
      } else {
        setUVReport(
          `${
            type === '기관 유형' ? '유사 기관 중' : '유사 규모 중'
          } 사용자가 적은 편입니다.\n상위 10%의 웹사이트는 한달 간 ${
            uvData.top_10_per
          } 명이 웹사이트를 이용합니다.`
        );
      }

      setRebuildUVData(rebuildData);
    }
  }, [uvData]);

  useEffect(() => {
    if (pvData) {
      setPVRate(pvData.percent);

      const rebuildData: SimilarVSReportCardState['rebuildData'] = [];

      rebuildData.push({
        '하위 10%': pvData.top_90_per,
        isHighlight: false,
      });
      rebuildData.push({
        '내 웹 사이트': pvData.my_pv,
        isHighlight: true,
      });
      rebuildData.push({
        '상위 10%': pvData.top_10_per,
        isHighlight: false,
      });

      if (pvData.percent <= 30) {
        setPVReport(
          `${
            type === '기관 유형' ? '유사 기관 중' : '유사 규모 중'
          } 페이지 이용이 많은 편입니다.\n웹 사이트 운영이 잘 되고 있습니다.`
        );
      } else if (pvData.percent > 30 && pvData.percent <= 70) {
        setPVReport(
          `${
            type === '기관 유형' ? '유사 기관 중' : '유사 규모 중'
          } 페이지 이용이 평균 수준입니다.\n상위 10%의 웹사이트는 한달 간 ${
            pvData.top_10_per
          }개의 페이지가 이용됩니다.`
        );
      } else {
        setPVReport(
          `${
            type === '기관 유형' ? '유사 기관 중' : '유사 규모 중'
          } 페이지 이용이 적은 편입니다.\n상위 10%의 웹사이트는 한달 간 ${
            pvData.top_10_per
          }개의 페이지가 이용됩니다.`
        );
      }
      setRebuildPVData(rebuildData);
    }
  }, [pvData]);

  return (
    <Component>
      <InnerComponent>
        <Title title={title} />
        <Body1>
          <Title3 mainTitle={type} subTitle={mainType} />
          {isLoading ? (
            <Spinner />
          ) : (
            <Chart>
              <DounutChart data={chartData} />
            </Chart>
          )}
        </Body1>
      </InnerComponent>
      {uvData ? (
        <>
          <Split />
          <InnerComponent>
            {isLoading ? (
              <Spinner />
            ) : (
              <BarChartCard
                title="사용자 수"
                value={uvRate}
                desc={uvReport}
                data={rebuildUVData}
                isLabelInChart
              />
            )}
          </InnerComponent>
        </>
      ) : null}
      {scaleUVData ? (
        <>
          <Split />
          <InnerComponent>
            {isLoading ? (
              <Spinner />
            ) : (
              <BarChartCard
                title="사용자 수"
                valueString={mainType}
                value={uvRate}
                desc={uvReport}
                data={rebuildUVData}
                isLabelInChart
              />
            )}
          </InnerComponent>
        </>
      ) : null}

      <Split />
      <InnerComponent>
        {isLoading ? (
          <Spinner />
        ) : (
          <BarChartCard
            title="페이지뷰 수"
            value={pvRate}
            desc={pvReport}
            data={rebuildPVData}
            isLabelInChart
          />
        )}
      </InnerComponent>
    </Component>
  );
};

export default SimilarVSReportCard;
