import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import {
  LocalPVPayload,
  LocalRegionPayload,
  LocalUVPayload,
} from '../../../../api/report/type';
import Title from '../../../Molecule/Card/CardTitle/ReportTitle';
import Title3 from '../../../Molecule/Card/CardTitle/ReportTitle3';
import Map from '../../../Molecule/Chart/LocalMapChart/Map';
import Spinner from '../../../Molecule/Spinner';
import BarChartCard from '../BarChartCard';

const Component = styled.div`
  width: 100%;
  height: 464px;
  display: flex;
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.grey200};
  border-radius: 8px;
`;

const Split = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
`;

const InnerComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  height: 100%;
  padding: 24px 24px 40px 24px;
`;

const Body1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const MapWrapper = styled.div`
  width: 247px;
  height: 100%;
`;

interface LocalReportCardProps {
  regionData: LocalRegionPayload | null;
  pvData: LocalPVPayload | null;
  uvData: LocalUVPayload | null;
  isLoading: boolean;
}

interface LocalReportCardState {
  rebuildData: {
    [key: string]: number | boolean;
    isHighlight: boolean;
  }[];
}

const LocalReportCard = ({
  pvData,
  regionData,
  uvData,
  isLoading,
}: LocalReportCardProps) => {
  const [region, setRegion] = useState('');
  const [uvRate, setUVRate] = useState(0);
  const [pvRate, setPVRate] = useState(0);
  const [uvReport, setUVReport] = useState('');
  const [pvReport, setPVReport] = useState('');
  const [rebuildUVData, setRebuildUVData] = useState<
    LocalReportCardState['rebuildData']
  >([]);
  const [rebuildPVData, setRebuildPVData] = useState<
    LocalReportCardState['rebuildData']
  >([]);

  useEffect(() => {
    if (regionData) {
      setRegion(regionData.region);
    }
  }, [regionData]);

  useEffect(() => {
    if (uvData) {
      setUVRate(uvData.percent);

      const rebuildData: LocalReportCardState['rebuildData'] = [];

      rebuildData.push({
        '하위 10%': uvData.top_90_per,
        isHighlight: false,
      });
      rebuildData.push({
        '내 웹 사이트': uvData.my_uv,
        isHighlight: true,
      });
      rebuildData.push({
        '상위 10%': uvData.top_10_per,
        isHighlight: false,
      });

      if (uvData.percent <= 30) {
        setUVReport(
          '지역 내 웹사이트 중 사용자가 많은 편입니다.\n웹 사이트 운영이 잘 되고 있습니다.'
        );
      } else if (uvData.percent > 30 && uvData.percent <= 70) {
        setUVReport(
          `지역 내 웹사이트 중 사용자 수가 평균 수준입니다.\n상위 10%의 웹사이트는 한달 간 ${uvData.top_10_per} 명이 웹사이트를 이용합니다.`
        );
      } else {
        setUVReport(
          `지역 내 웹사이트 중 사용자가 적은 편입니다.\n상위 10%의 웹사이트는 한달 간 ${uvData.top_10_per} 명이 웹사이트를 이용합니다.`
        );
      }

      setRebuildUVData(rebuildData);
    }
  }, [uvData]);

  useEffect(() => {
    if (pvData) {
      setPVRate(pvData.percent);

      const rebuildData: LocalReportCardState['rebuildData'] = [];

      rebuildData.push({
        '하위 10%': pvData.top_90_per,
        isHighlight: false,
      });
      rebuildData.push({
        '내 웹 사이트': pvData.my_pv,
        isHighlight: true,
      });
      rebuildData.push({
        '상위 10%': pvData.top_10_per,
        isHighlight: false,
      });

      if (pvData.percent <= 30) {
        setPVReport(
          '지역 내 웹사이트 중 페이지 이용이 많은 편입니다.\n웹 사이트 운영이 잘 되고 있습니다.'
        );
      } else if (pvData.percent > 30 && pvData.percent <= 70) {
        setPVReport(
          `지역 내 웹사이트 중 페이지 이용이 평균 수준입니다.\n상위 10%의 웹사이트는 한달 간 ${pvData.top_10_per}개의 페이지가 이용됩니다.`
        );
      } else {
        setPVReport(
          `지역 내 웹사이트 중 페이지 이용이 적은 편입니다.\n상위 10%의 웹사이트는 한달 간 ${pvData.top_10_per}개의 페이지가 이용됩니다.`
        );
      }
      setRebuildPVData(rebuildData);
    }
  }, [pvData]);

  return (
    <Component>
      <InnerComponent>
        <Title title="지역 내에서 내 웹 사이트는?" />
        <Body1>
          <Title3 mainTitle="지역" subTitle={region} />
          <MapWrapper>
            <Map region={region} />
          </MapWrapper>
        </Body1>
      </InnerComponent>
      <Split />
      <InnerComponent>
        {isLoading ? (
          <Spinner />
        ) : (
          <BarChartCard
            title="사용자 수"
            value={uvRate}
            desc={uvReport}
            data={rebuildUVData}
            isLabelInChart
          />
        )}
      </InnerComponent>
      <Split />
      <InnerComponent>
        {isLoading ? (
          <Spinner />
        ) : (
          <BarChartCard
            title="페이지 뷰 수"
            value={pvRate}
            desc={pvReport}
            data={rebuildPVData}
            isLabelInChart
          />
        )}
      </InnerComponent>
    </Component>
  );
};

export default LocalReportCard;
