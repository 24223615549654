import React, { ReactNodeArray, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as IconCloseSVG } from '../../../assets/icons/icon-close.svg';
import { ReactComponent as IconFillArrowDownSVG } from '../../../assets/icons/icon-fill-arrow-down.svg';

import Spinner from '../Spinner';

// icon
const IconFillArrowDown = styled(IconFillArrowDownSVG)``;
const IconClose = styled(IconCloseSVG)``;

const ContentsTableComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 1200px;
  height: 884px;
  max-height: 90vh;
  background-color: white;
  border-radius: 16px;
  padding: 24px 24px 40px 24px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 29px;
  margin-bottom: 40px;
`;

const HeaderTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: ${(props) => props.theme.colors.grey800};
`;

const HeaderCloseButton = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  ${IconClose} {
    width: 24px;
    height: 24px;
  }
`;

const Body = styled.div`
  flex: 1;
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const BodySchema = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
`;

const BodySchemaItem = styled.div<{ $width: number }>`
  font-weight: 400;
  display: flex;
  width: ${(props) => props.$width}px;
  padding: 16px 24px;
  align-items: center;
  font-size: 16px;
  color: ${(props) => props.theme.colors.grey500};
  background-color: ${(props) => props.theme.colors.grey100};
`;

const BodySchemaItemButton = styled.button<{ $isAsc: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  svg {
    width: 10px;
    height: 5px;
    ${(props) => {
      if (props.$isAsc) {
        return css`
          transform: rotate(180deg);
        `;
      }
      return css``;
    }}
  }
  background-color: transparent;
  align-items: center;
`;
const BodySchemaItemInnerText = styled.div`
  margin-right: 8px;
`;

const BodyItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const BodyItem = styled.div`
  display: flex;
  align-items: center;
  height: 138px;
  border-bottom: solid 1px ${(props) => props.theme.colors.grey300};
`;

const BodyItemBox = styled.div<{ $width: number }>`
  display: flex;
  align-items: center;
  padding: 24px;
  width: ${(props) => props.$width}px;
  height: 100%;
`;

const BodyItemTitleBox = styled.a`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  :hover {
    text-decoration: underline;
    text-decoration-color: ${(props) => props.theme.colors.grey800};
  }
`;
const BodyItemTitle = styled.div`
  font-weight: 700;
  width: 100%;
  height: 20px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.grey800};
  margin-bottom: 8px;
`;
const BodyItemDesc = styled.div`
  font-weight: 400;
  width: 100%;
  font-size: 14px;
  height: 40px;
  color: ${(props) => props.theme.colors.grey600};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const BodyItemCntBold = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.colors.grey800};
`;

const ProgressBarBack = styled.div`
  position: relative;
  width: 228px;
  height: 24px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: ${(props) => props.theme.colors.grey200};
  z-index: 30;
  margin-right: 16px;
`;

const ProgressBar = styled.div<{ $rate: number }>`
  position: absolute;
  z-index: 40;
  top: 0px;
  left: 0px;
  width: ${(props) => props.$rate}%;
  height: 24px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: ${(props) => props.theme.colors.purple3};
`;

const NoData = styled.div`
  width: 100%;
  height: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.grey800};
`;

interface ContentsTableProps {
  onCloseTable: () => void;
  clickKeyword?: string;
  data:
    | {
        document_id: string;
        count: number;
        rate: number;
        results: {
          title: string;
          contents: string;
          url: string;
          pk: string;
        };
      }[]
    | null;
  isLoading?: boolean;
}

const ContentsTable = ({
  onCloseTable,
  clickKeyword,
  data,
  isLoading,
}: ContentsTableProps) => {
  const [isAsc, setIsAsc] = useState(false);

  const printTableBody = useMemo(() => {
    const result: ReactNodeArray = [];
    if (data && data.length > 0) {
      data.some((element, index) => {
        if (index > 9) {
          return true;
        }

        result.push(
          <BodyItem key={element.document_id}>
            <BodyItemBox $width={629}>
              {/* <BodyItemThumbnail src={element.img_url} /> */}
              <BodyItemTitleBox target="_blank" href={element.results.url}>
                <BodyItemTitle>{element.results.title}</BodyItemTitle>
                <BodyItemDesc
                  dangerouslySetInnerHTML={{ __html: element.results.contents }}
                />
              </BodyItemTitleBox>
            </BodyItemBox>
            {/* <BodyItemBox $width={173}>
              <BodyItemTime>{timeString}</BodyItemTime>
            </BodyItemBox> */}
            <BodyItemBox $width={173}>
              <BodyItemCntBold>{element.count}</BodyItemCntBold>
            </BodyItemBox>
            <BodyItemBox $width={350}>
              <ProgressBarBack>
                <ProgressBar $rate={element.rate} />
              </ProgressBarBack>
              <BodyItemCntBold>
                {Math.round(element.rate * 100) / 100}%
              </BodyItemCntBold>
            </BodyItemBox>
          </BodyItem>
        );
        return false;
      });
    } else {
      result.push(<NoData key={0}>데이터가 없습니다.</NoData>);
    }

    if (isAsc) {
      return result.reverse();
    }
    return result;
  }, [data, isAsc]);

  return (
    <ContentsTableComponent>
      <Header>
        <HeaderTitle>&apos;{clickKeyword}&apos; 주요 콘텐츠</HeaderTitle>
        <HeaderCloseButton
          onClick={() => {
            setIsAsc(false);
            onCloseTable();
          }}
        >
          <IconClose />
        </HeaderCloseButton>
      </Header>
      <Body>
        <BodySchema>
          <BodySchemaItem $width={629}>콘텐츠 명</BodySchemaItem>
          {/* <BodySchemaItem $width={173}>평균 체류시간</BodySchemaItem> */}
          <BodySchemaItem $width={173}>
            <BodySchemaItemButton
              onClick={() => {
                setIsAsc(!isAsc);
              }}
              $isAsc={isAsc}
            >
              <BodySchemaItemInnerText>조회수</BodySchemaItemInnerText>
              <IconFillArrowDown />
            </BodySchemaItemButton>
          </BodySchemaItem>
          <BodySchemaItem $width={350}>클릭비율(%)</BodySchemaItem>
        </BodySchema>
        {isLoading ? (
          <Spinner />
        ) : (
          <BodyItemWrapper>{printTableBody}</BodyItemWrapper>
        )}
      </Body>
    </ContentsTableComponent>
  );
};

export default ContentsTable;
