import React from 'react';
import styled from 'styled-components';

const Component = styled.div`
  position: absolute;
  width: 100%;
  border: dashed 2px #fafbc9;
  z-index: 20;
`;

interface ScrollHeatmapCrossHairProps {
  crossHairRef?: React.RefObject<HTMLDivElement>;
  className?: string;
}

const ScrollHeatmapCrossHair = ({
  crossHairRef,
  className,
}: ScrollHeatmapCrossHairProps) => {
  return <Component ref={crossHairRef} className={className} />;
};

export default ScrollHeatmapCrossHair;
