import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../modules';

import { GetAIVORYSearchUsageAnalysisParamType } from '../../../api/aivoryUsageAnalytics/searchUsageAnalysis/type';
import {
  getAIVORYSearchUsageAnalysisAsync,
  getAIVORYContentsUsageAnalysisAsync,
} from '../../../modules/aivoryUsageAnalytics/SearchUsageAnalysis/actions';
import MultiLineChartCard from '../../../components/Organisms/Card/MultiLineChartCard';
import Header from '../../../components/Organisms/Header';
import ContentsLayout from '../../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';

const SearchUsageAnalysis = () => {
  const dispatch = useDispatch();
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );

  const {
    isLoadingSearchUsageAnalysis,
    isLoadingContentsUsageAnalysis,
    searchUsageAnalysisData,
    contentsUsageAnalysisData,
  } = useSelector((state: RootState) => state.aivorySearchUsageAnalysis);

  const onGetAIVORYSearchUsageAnalysis = (
    info: GetAIVORYSearchUsageAnalysisParamType
  ) => {
    dispatch(getAIVORYSearchUsageAnalysisAsync.request(info));
  };

  const onGetAIVORYContentsUsageAnalysis = (
    info: GetAIVORYSearchUsageAnalysisParamType
  ) => {
    dispatch(getAIVORYContentsUsageAnalysisAsync.request(info));
  };

  // state
  const [selectedTimeSortSearch, setSelectedTimeSortSearch] = useState('day');
  const [selectedUserSortSearch, setSelectedUserSortSearch] = useState('all');
  const [selectedTimeSortContents, setSelectedTimeSortContents] = useState(
    'day'
  );
  const [selectedUserSortContents, setSelectedUserSortContents] = useState(
    'all'
  );

  const onChangeFliterSearch = () => {
    const start_date = moment(startDate).format('YYYY-MM-DD');
    const end_date = moment(endDate).format('YYYY-MM-DD');
    onGetAIVORYSearchUsageAnalysis({
      end_date,
      start_date,
      unit: selectedTimeSortSearch,
    });
  };

  const onChangeFliterContents = () => {
    const start_date = moment(startDate).format('YYYY-MM-DD');
    const end_date = moment(endDate).format('YYYY-MM-DD');
    onGetAIVORYContentsUsageAnalysis({
      end_date,
      start_date,
      unit: selectedTimeSortContents,
    });
  };

  useEffect(() => {
    const start_date = moment(startDate).format('YYYY-MM-DD');
    const end_date = moment(endDate).format('YYYY-MM-DD');
    if (!isDatePickerToggle) {
      onGetAIVORYSearchUsageAnalysis({
        end_date,
        start_date,
        unit: selectedTimeSortSearch,
      });
      onGetAIVORYContentsUsageAnalysis({
        end_date,
        start_date,
        unit: selectedTimeSortContents,
      });
    }
  }, [isDatePickerToggle, startDate, endDate]);

  return (
    <ContentsLayout>
      <Header title="검색 이용 분석" isDatePicker />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <MultiLineChartCard
            legendData={[{ status: 'all', title: '전체 검색 이용 횟수' }]}
            Data={searchUsageAnalysisData?.result || null}
            isLoading={isLoadingSearchUsageAnalysis}
            selectedTimeSort={selectedTimeSortSearch}
            selectedUserSort={selectedUserSortSearch}
            lineLabel="전체 검색 이용 횟수"
            headerTitle="검색 이용 횟수"
            setSelectedTimeSort={setSelectedTimeSortSearch}
            setSelectedUserSort={setSelectedUserSortSearch}
            onChangeFliter={onChangeFliterSearch}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={12}>
          <MultiLineChartCard
            legendData={[{ status: 'all', title: '전체 콘텐츠 이용 개수' }]}
            Data={contentsUsageAnalysisData?.result || null}
            selectedTimeSort={selectedTimeSortContents}
            selectedUserSort={selectedUserSortContents}
            isLoading={isLoadingContentsUsageAnalysis}
            setSelectedTimeSort={setSelectedTimeSortContents}
            setSelectedUserSort={setSelectedUserSortContents}
            onChangeFliter={onChangeFliterContents}
            lineLabel="전체 콘텐츠 이용 개수"
            headerTitle="콘텐츠 이용 개수"
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default SearchUsageAnalysis;
